
export interface IHiTestMessage {
    readonly additionalMessage: string;
    readonly identifier: string;
}

export interface IHiTestGuidToRestartMessage extends IHiTestMessage {
    readonly testGuidToRestart: string;
}

export interface IHiTestProgressMessage extends IHiTestMessage {
    testIsRunning: boolean;
    readonly currentActiveRowId: number;
    readonly testStatus: string;
    readonly rowStatuses: HiTestRowStatus[];
    readonly performedTestModules: HiTestModuleStatus[];
    readonly robotMode: boolean;
}

export interface HiTestModuleStatus {
    moduleId: number;
    status: string;
}

export interface IHiTestAskClientForDataUpdateMessage extends IHiTestMessage {
    data: object;
    dataType: string;
}

export interface IHiTestAskGuiToClearRequestMessage extends IHiTestMessage {
    requestToClear: string;
}

export interface IReceiveDiagnosticValueMessage extends IHiTestMessage {
    diagnosticInputDTOs: DiagInputDTO[];
}

export interface IHiTestRowUpdateMessage extends IHiTestMessage {
    rowId: number;
    parsedInstruction: string;
}

export interface IHiTestTimerStatusMessage extends IHiTestMessage {
    timerValues: HiTestTimerValue[];
}

export interface IHiTestShowSpinnerMessage extends IHiTestMessage {
    spinnerVisible: boolean;
}

export interface IHiTestShowProgressBarMessage extends IHiTestMessage {
    progressBarVisible: boolean;
    determinate: boolean;
    progressPercent: number;
}

export interface IHiTestUpdateToleranceMetersMessage extends IHiTestMessage {
    toleranceMeters: ToleranceMeterDto[];
}

export interface IHiTestShowToleranceMetersMessage extends IHiTestMessage {
    toleranceMeters: ToleranceMeterDto[];
}

export interface ToleranceMeterDto {
    id: number;
    criteria: string;
    inputName: string;
    unit: string;
    value: number;
    valueTarget: number;
    valueMinOk: number;
    valueMaxOk: number;
}

export interface ToleranceMeterDisplayModel extends ToleranceMeterDto {
    valueNice: string;
    valueTargetNice: string;
    valueMinOkNice: string;
    valueMaxOkNice: string;
}

export interface DiagInputDTO {
    inputName: string;
    inputUnit: string;
    latestValue: string;
    justUpdated: boolean;
}

export interface HiTestTimerValue {
    readonly name: string;
    readonly value: number;
}

export interface HiTestRowStatus {
    readonly rowNo: number;
    readonly status: string;
}

export interface IHiTestRowsToSkipMessage extends IHiTestMessage {
    rowIdsToSkip: number[];
}

export enum operatorResponseDataType {
    none,
    numeric,
    text,
    boolean
}

export class IHiTestAskOperatorMessage implements IHiTestMessage {
    public identifier: string;
    public additionalMessage: string;
    public askConfirmation: boolean;
    public askRetry: boolean;
    public askOverride: boolean;
    public askFail: boolean;
    public askStop: boolean;
    public askValue: any;
    public askPriority: boolean;
    public askReleasePriority: boolean;
    public expectedResponseType: operatorResponseDataType;
    public promptOperator: string;
    public description: string;
    public additionalInfo: string;
    public defaultValue: string;

    // Extra properties used only in front-end
    public stepId: number;
    public responseValue: string | number | boolean;
    public showAdditionalInfo: boolean;
}


export interface IHiTestInputsStatusMessage extends IHiTestMessage {
    inputValues: InputValue[];
}

export interface InputValue {
    name: string;
    value: number;
    unit: string;
}
