<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="hic-available-rows">
    <ng-container *ngFor="let row of testRows">
        <!-- Module info -->
        <div *ngIf="row.moduleName" class="module-info"
            [ngStyle]="{'background': getModuleColor(row.ModuleId)}" (contextmenu)="onRightClick($event, row)">
            <div class="module-id">M{{ row.ModuleId }}</div>
            <div class="module-name"><span style="--row-width: {{getOverflowOffset(row.moduleName)}}px">{{row.moduleName}}</span></div>
        </div>

        <!-- Test row -->
        <div *ngIf="!row.placeholderForWholeModule" class="hic-test-row" (click)="toggleShownDescription(row.Id)" (contextmenu)="onRightClick($event, row)"
            [class.opened]="isInShownDescriptions(row.Id)">

            <!-- The following anchor defines where to auto scroll the active test row -->
            <div #testrow [id]="testRowIdPrefix + row.Id" class="test-row-anchor"></div>

            <div class="hic-row-index" [ngStyle]="{'background': getModuleColor(row.ModuleId)}"
                [class.opened]="isInShownDescriptions(row.Id)">
                <div>{{row.InternalStepNr}}/{{row.numberOfRowsInModule}}</div>
            </div>
            <div class="hic-indented">{{getTranslatedTestRowName(row)}}</div>

            <div class="row-spinner" *ngIf="showRowSpinner && row.Id === getCurrentStepId()">
                <mat-spinner diameter="30"></mat-spinner>
            </div>
            <div class="row-status" *ngIf="!(showRowSpinner && row.Id === getCurrentStepId())"
                [ngClass]="getIconStyleClass(row)">
            </div>
        </div>

        <!-- Addition test row info and actions -->
        <div class="hic-test-row-dropdown" *ngIf="isInShownDescriptions(row.Id)">
            <div class="row-progress-information" *ngIf="row.Id === getCurrentStepId()">
                <mat-progress-bar mode="determinate" *ngIf="showRowProgress" [value]="progressValue"></mat-progress-bar>
                <mat-progress-bar mode="indeterminate" *ngIf="showRowProgressIndeterminate"></mat-progress-bar>
            </div>
            <div class="hic-test-row-upper">
                <div class="hic-test-row-description">
                    <markdown [data]="getRowInstruction(row.Id)" ngPreserveWhitespaces></markdown>
                </div>
            </div>
            <!--Keep the two ngIf statements below cascaded and as slim as possible for performance reasons-->
            <ng-container *ngIf="currentTestStatus?.testIsRunning">
                <ng-container *ngIf="row.Id === currentTestStatus?.currentActiveRowId">
                    <div *ngFor="let request of currentRequests">

                        <div *ngIf="request.promptOperator || request.description" class="operator-request-info">
                            <button type="button" *ngIf="request.additionalInfo" class="additionalInfoIcon" (click)="request.showAdditionalInfo = !request.showAdditionalInfo">
                                <mat-icon class="info-icon">info</mat-icon>
                            </button>
                            <div *ngIf="request.promptOperator" class="prompt">
                                {{request.promptOperator}}</div>
                            <div *ngIf="request.description" class="description">{{request.description}}</div>
                        </div>
                        <!-- Additional info section -->
                        <div *ngIf="shouldAdditionalInfoBeVisible(request)" class="additionalInfo"
                            [innerText]="request.additionalInfo">
                        </div>

                        <!-- MANUAL VALUE ENTRY section -->
                        <div *ngIf="request.askValue" class="hic-test-row-manual-input">
                            <div>Value</div>
                            <hic-compact-input2 class="manual-input"
                                *ngIf="request.expectedResponseType === operatorResponseDataType.numeric" type="number"
                                [allowEmpty]="false" [(ngModel)]="request.responseValue"
                                [enterFunction]="getEnterCallback(request)">
                            </hic-compact-input2>
                            <hic-compact-input2 class="manual-input"
                                *ngIf="request.expectedResponseType === operatorResponseDataType.text" type="text"
                                [allowEmpty]="false" [(ngModel)]="request.responseValue"
                                [enterFunction]="getEnterCallback(request)">
                            </hic-compact-input2>
                            <hic-toggle2 class="manual-input"
                                *ngIf="request.expectedResponseType === operatorResponseDataType.boolean"
                                [(ngModel)]="request.responseValue"></hic-toggle2>
                            <button type="button" class="hic-btn-primary" [disabled]="!request.responseValue && request.responseValue !== 0"
                                (click)="sendValueResponse(request)">Send</button>
                            </div>

                        <!-- RETRY button section -->
                        <button *ngIf="request.askRetry" type="button" class="hic-test-row-lower retry-button hic-test-row-button" (click)="retryStep(request.identifier)"
                        i18n="@@hitest.test.retry"> RETRY
                            <mat-icon>chevron_right</mat-icon>
                        </button>

                        <!-- SEND button for parameter preview -->
                        <button type="button" *ngIf="parameterInitViewModel && !request.askRetry" class="hic-test-row-lower hic-test-row-button retry-button" (click)="sendUpdatedParameterData(request.identifier)"
                        i18n="@@hitest.test.send"> SEND
                            <mat-icon>chevron_right</mat-icon>
                        </button>

                        <!-- OVERRIDE/FAIL STEP section -->
                        <div *ngIf="request.askOverride || request.askStop" class="hic-test-row-lower">
                            <div class="hic-test-row-button override-section">
                                <button type="button" *ngIf="request.askOverride" [disabled]="!operatorComment" (click)="overrideStep(request.identifier)"
                                    class="override-button">
                                    <span i18n="@@hitest.test.override">OVERRIDE</span>
                                    <mat-icon>chevron_right</mat-icon>
                                </button>
                                <button type="button" *ngIf="askFail" [disabled]="!operatorComment" (click)="failStep(request.identifier)"
                                    class="override-button">
                                    <span i18n="@@hitest.test.fail">FAIL</span>
                                    <mat-icon>chevron_right</mat-icon>
                                </button>
                                <button type="button" *ngIf="askStop" (click)="stopStep(request.identifier)"
                                    class="override-button">
                                    <span i18n="@@hitest.test.stop">STOP</span>
                                    <mat-icon>chevron_right</mat-icon>
                                </button>
                                <div class="override-input">
                                    <hic-compact-input2 *ngIf="request.askOverride" type="text" [allowEmpty]="false"
                                        [(ngModel)]="operatorComment"
                                        [placeholder]="enterOverrideMessagePlaceholder">
                                    </hic-compact-input2>
                                </div>
                            </div>
                        </div>

                        <!-- DONE button section -->
                        <button type="button" id="done-button" *ngIf="request.askConfirmation"
                            class="hic-test-row-lower unstyled-button done-button" (click)="confirmStep(request.identifier)">
                            <div class="empty"></div>
                            <div class="hic-test-row-button">
                                <h4 i18n="@@hitest.test.done">DONE</h4>
                            </div>
                            <mat-icon>chevron_right</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>
