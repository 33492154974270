import { Component, OnInit, Input } from "@angular/core";
import { HiTestMockingCondition as HitestDisplayCondition, IHiTestModuleRowModel } from "src/app/hitest/models/hitest.model";
import { HiTestService } from "src/app/hitest/services/hitest.service";
import { HiTestTimerValue, InputValue, ToleranceMeterDto, } from "../../models/hitest-status-message.interface";

@Component({
    selector: "hic-hitest-criterias",
    templateUrl: "./hitest-criterias.component.html",
    styleUrls: ["./hitest-criterias.component.scss"]
})
export class HitestCriteriasComponent implements OnInit {

    @Input() public displayAsMiniDisplay: boolean;
    public testRows: IHiTestModuleRowModel[] = [];
    public currentTestRow: IHiTestModuleRowModel;
    public testCondition: HitestDisplayCondition;
    public testConditions: HitestDisplayCondition[] = [];

    @Input() public min: number = undefined;
    @Input() public max: number = undefined;
    @Input() public current: number = undefined;
    @Input() public activeTimers: HiTestTimerValue[] = [];
    @Input() public inputValues: InputValue[] = [];
    @Input() public toleranceMeters: ToleranceMeterDto[] = [];

    public constructor(public hitestService: HiTestService) { }

    public ngOnInit(): void { }

    public conditionTrack(index: number, condition: HitestDisplayCondition): string {
        return condition ? condition.name : undefined;
    }

    public inputTrack(index: number, inputValue: InputValue): string {
        return inputValue ? inputValue.name : undefined;
    }

    public timerTrack(index: number, timerValue: HiTestTimerValue): string {
        return timerValue ? timerValue.name : undefined;
    }

}
