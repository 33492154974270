import { HiTestConstants, IHiTestModuleModel } from "../../models/hitest.model";

export class Restrictions {
    public module: IHiTestModuleModel;
    public notBefore: number[] = [];
    public notAfter: number[] = [];
    public minSkill: string = undefined;


    /**
     * Creates a set of restrictions affecting the provided module. The restrictions are built up
     * by the modules own restrictions and any external restrictions from other modules that affect the provided module.
     *
     * @param module The module to set up the Restrictions object for.
     * @param otherModules Any other modules that could affect restrictions of module. The array may include the module itself,
     * in which case it will simply be filtered away.
     */
    public static create(module: IHiTestModuleModel, otherModules: IHiTestModuleModel[]): Restrictions {
        const restrictions = new Restrictions();
        restrictions.module = module;
        // Check the module for restrictions to other modules.
        for (const innerRestriction of module.HiTestModuleRestrictionModules) {
            if (innerRestriction.Restriction === HiTestConstants.moduleRestrictions.notBefore) {
                restrictions.notBefore.push(innerRestriction.ReferencingModuleId);
            }
            if (innerRestriction.Restriction === HiTestConstants.moduleRestrictions.notAfter) {
                restrictions.notAfter.push(innerRestriction.ReferencingModuleId);
            }
            if (innerRestriction.Restriction === HiTestConstants.moduleRestrictions.minSkill) {
                restrictions.minSkill = innerRestriction.Data;
            }
        }
        Restrictions.appendModuleRestrictions(restrictions, otherModules);
        return restrictions;
    }

    private static appendModuleRestrictions(restrictions: Restrictions, modules: IHiTestModuleModel[]): void {
        // Check all other modules for restrictions that affect the module
        modules = modules.filter(mod => mod.Id !== restrictions.module.Id);
        for (const otherModule of modules) {
            for (const outerRestriction of otherModule.HiTestModuleRestrictionModules) {
                if (outerRestriction.Restriction === HiTestConstants.moduleRestrictions.notBefore &&
                    outerRestriction.ReferencingModuleId === restrictions.module.Id
                ) {
                    restrictions.notAfter.push(outerRestriction.ModuleId);
                }
                if (outerRestriction.Restriction === HiTestConstants.moduleRestrictions.notAfter &&
                    outerRestriction.ReferencingModuleId === restrictions.module.Id
                ) {
                    restrictions.notBefore.push(outerRestriction.ModuleId);
                }
            }
        }
    }

    public allowBefore(comparer: IHiTestModuleModel | number): boolean {
        return !this.notBefore.includes(typeof comparer === "number" ? comparer : comparer.Id);
    }

    public allowAfter(comparer: IHiTestModuleModel | number): boolean {
        return !this.notAfter.includes(typeof comparer === "number" ? comparer : comparer.Id);
    }
}
