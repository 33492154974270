import { HiTestModuleModel } from "../../models/hitest-newmodule.model";
import { HiTestConstants, IHiTestModuleRestriction } from "../../models/hitest.model";
import { HiTestModuleValidity } from "./module-validaty.model";

export class HiTestModuleValidationHelper {
    public validateModule(module: HiTestModuleModel): HiTestModuleValidity {
        return module.HiTestModuleRestrictionModules
            .map(x => this.validateRestriction(x))
            .find(x => x !== HiTestModuleValidity.valid)
            ?? HiTestModuleValidity.valid;
    }

    public validateRestriction(restrictor: IHiTestModuleRestriction): HiTestModuleValidity {
        if (!restrictor?.Restriction) {
            return HiTestModuleValidity.restrictionWithoutType;
        }
        if (restrictor?.Restriction === HiTestConstants.moduleRestrictions.minSkill) {
            return !!restrictor.Data ? HiTestModuleValidity.valid : HiTestModuleValidity.restrictionSkillWithoutValue;
        }

        if (!restrictor.ReferencingModuleId) {
            return HiTestModuleValidity.restrictionWithoutTarget;
        }

        if (!restrictor?.ModuleId) {
            return HiTestModuleValidity.restrictionWithoutSource;
        }
        return HiTestModuleValidity.valid;
    }
}
