import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ButtonType } from "src/app/core/dialogs/models/button-type.enum";
import { IDialogOptions } from "src/app/core/dialogs/models/dialog-options.interface";
import { DialogResult } from "src/app/core/dialogs/models/dialog-result.enum";
import { DialogService } from "src/app/core/dialogs/services/dialog.service";
import { HiTestMessageService } from "src/app/hitest/services/message/hitest-message.service";
import { Semaphore } from "src/app/core/tools/semaphore";
import { ConnectionStatus, HiTestConstants, IHiTestModuleRowModel, IPreviousCompletedTest } from "src/app/hitest/models/hitest.model";
import { HiTestLocationsService } from "src/app/hitest/services/hitest-locations.service";
import { HiTestModuleService } from "src/app/hitest/services/hitest-modules.service";
import { HiTestService } from "src/app/hitest/services/hitest.service";


@Component({
    selector: "hic-equipment-selection",
    templateUrl: "./equipment-selection.component.html",
    styleUrls: ["./../../../admin/hitest-admin.scss", "./equipment-selection.component.scss"]
})

export class HiTestEquipmentSelectionComponent implements OnInit, OnDestroy {
    public craneEquipmentLoaded = false;
    public orderToRun: string;
    public testRows: IHiTestModuleRowModel[] = [];
    public startStepNo = 0;
    public showLoadingBox = false;
    public loadingBoxText = "";
    public showPrevTestBox = false;
    public pausedTestExists = false;
    public showPrevTestAsPaused: boolean;
    public dataPopulated = false;
    public completedTests: IPreviousCompletedTest[] = undefined;

    public readonly cannotSelectTestTooltip = $localize `You need to start over or resume the paused test first.`;

    private loadOrderSemaphore = new Semaphore(1);
    public previousTestsVisible = false;
    public plcConnectionStatus: ConnectionStatus;
    public canConnectionStatus: ConnectionStatus;
    private componentDestroyed$ = new Subject<boolean>();

    constructor(
        public hiTestService: HiTestService,
        public router: Router,
        private messageService: HiTestMessageService,
        public moduleService: HiTestModuleService,
        private locationsService: HiTestLocationsService,
        public dialogService: DialogService,
    ) { }

    ngOnInit(): void {
        this.hiTestService.robotMode = false;
        this.hiTestService.robotModeButtonEnabled = true;
        this.hiTestService.robotModeButtonVisible = false;
        this.moduleService.modulesUpdated$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            try {
                if (response) {
                    const allLocations = await this.locationsService.getLocationsAsync();
                    const activatedLocations = this.moduleService.getIdOfLocationsWithAssignedModules();

                    if (activatedLocations.length !== 1) {
                        const message = activatedLocations.length === 0
                            ? "Sort order did not contain any rows for your location!"
                            : "Sort order contained more than location!";
                        this.messageService.sendAlert(message);
                        throw new Error(message);
                    }

                    const benchLocationId = activatedLocations[0];
                    const benchLocation = allLocations.find(x => x.id === benchLocationId);
                    this.testRows = this.moduleService.getSortedExpandedRowsByLocation(benchLocation);

                    this.startStepNo = 0;
                    const currentTestGuid =
                        await this.hiTestService.getCurrentTestGuid();
                    if (currentTestGuid !== null) {
                        console.log(
                            `Test with GUID ${currentTestGuid} is currently running. Redirecting to operator page.`
                        );
                        this.router.navigateByUrl("hitest/operator");
                    } else {
                        console.log("No currently running test found.");
                    }
                }
            } finally {
                this.showLoadingBox = false;
            }
        });

        this.hiTestService.canStatus$.pipe(takeUntil(this.componentDestroyed$)).subscribe((response: ConnectionStatus) => {
            if (response) {
                this.canConnectionStatus = response;
            } else {
                console.log("Failed to get CAN status");
            }
        });

        this.hiTestService.plcStatus$.pipe(takeUntil(this.componentDestroyed$)).subscribe((response: ConnectionStatus) => {
            if (response) {
                this.plcConnectionStatus = response;
            } else {
                console.log("Failed to get PLC status");
            }
        });

        this.hiTestService.craneEquipmentReady$.pipe(takeUntil(this.componentDestroyed$)).subscribe((response: boolean) => {
            this.craneEquipmentLoaded = response;
            if (response) {
                this.showLoadingBox = false;
            }
        });

        this.showLoadingBox = true;
        this.loadingBoxText = "Loading";

        this.moduleService.dataPopulated$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            if (response) {
                console.log("Data populated, continue with loading of modules.");
                this.dataPopulated = true;
                this.locationsService.clearCache();
                this.moduleService.updateModuleDataAsync();
            }
        });
        this.moduleService.populateDataAsync();

        console.log(this.loadingBoxText);
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    public async onLoadOrderAsync(orderToLoad: string): Promise<void> {
        await this.loadOrderSemaphore.waitAsync();
        try {
            this.craneEquipmentLoaded = false;
            if (!orderToLoad) {
                this.messageService.sendAlert("Order Number may not be empty");
            } else {
                this.orderToRun = orderToLoad;
                this.showPrevTestBox = false;
                this.pausedTestExists = false;
                this.hiTestService.emptyCraneEquipment();

                this.showLoadingBox = true;
                this.loadingBoxText = "Looking for previous test";

                const previousTest = await this.hiTestService.getPreviousTestFromOrderNumberAsync(this.orderToRun);
                this.completedTests = previousTest.completedTests;
                this.completedTests.forEach(x => {
                    if (x.status == HiTestConstants.performedTest.paused) {
                        this.pausedTestExists = true;
                    }
                });
                if (previousTest?.runningTestGuid) {
                    // Ask operator if we should continue on the old test, or start a new one
                    this.showPrevTestAsPaused = true;
                    this.showPrevTestBox = true;
                    this.showLoadingBox = false;
                    this.craneEquipmentLoaded = false;
                } else {
                    this.loadingBoxText = "Loading order";
                    this.hiTestService.getEquipmentFromOrderNumber(this.orderToRun);
                }
            }
        } finally {
            this.loadOrderSemaphore.release();
        }
    }

    public setPreviousTestsVisible(visible: boolean): void {
        this.previousTestsVisible = visible;
    }

    public async selectPreviousTest(test: IPreviousCompletedTest): Promise<void> {
        const dialogOptions: IDialogOptions = {
            title: "Confirm load test again.",
            // eslint-disable-next-line max-len
            content: "Confirm that You intend to load (and run)\nthe " + test.status + " test from " + this.formatDate(test.started) + " again.",
            confirmativeButton: ButtonType.yes,
            dismissiveButton: ButtonType.no
        };

        const dialogResult = await this.dialogService.showModalDialogAsync(dialogOptions);
        if (dialogResult === DialogResult.confirm) {
            this.previousTestsVisible = false;
            this.showPrevTestBox = false;
            this.craneEquipmentLoaded = false;
            const previousTest = await this.hiTestService.selectPreviousTestFromGuidAsync(test.guid);
            this.hiTestService.previousTest = previousTest;
            this.showPrevTestAsPaused = false;
            this.hiTestService.robotModeButtonEnabled = true;
            this.hiTestService.robotMode = false;
            this.showPrevTestBox = true;
        }
    }

    public resumePrev(): void {
        this.hiTestService.getCraneEquipmentFromPrev();
        this.hiTestService.robotModeButtonEnabled = false;
    }

    public async invalidatePrev(): Promise<boolean> {
        const wasInvalidated = await this.hiTestService.invalidatePreviousTest(this.hiTestService.previousTest.runningTestGuid);
        if (wasInvalidated) {
            await this.onLoadOrderAsync(this.hiTestService.previousTest.orderNo);
            this.hiTestService.robotMode = false;
            this.hiTestService.robotModeButtonEnabled = true;
        }
        return wasInvalidated;
    }

    public toggleTestRun(): void {
        if (this.orderToRun) {
            this.hiTestService.toggleTestRunning(this.startStepNo ?? 0);
            this.router.navigateByUrl("hitest/operator");
        } else {
            this.messageService.sendAlert("Load order to run before pressing Start");
        }
    }

    public activateCan(): void {
        this.hiTestService.activateCan();
    }

    public deactivateCan(): void {
        this.hiTestService.deactivateCan();
    }

    public statusCan(): string {
        if (this.canConnectionStatus === undefined) {
            return "";
        }

        if (!this.canConnectionStatus.connected) {
            return "Not connected";
        }

        const items = this.canConnectionStatus.receivedData.length;
        return "Connected (" + items + ")";
    }

    public activatePlc(): void {
        this.hiTestService.activatePlc();
    }

    public deactivatePlc(): void {
        this.hiTestService.deactivatePlc();
    }

    public statusPlc(): string {
        if (this.plcConnectionStatus === undefined) {
            return "";
        }

        if (!this.plcConnectionStatus.connected) {
            return "Not connected";
        }

        const items = this.plcConnectionStatus.receivedData.length;
        return "Connected (" + items + ")";
    }

    public checkStatus(): void {
        this.askForStatus();
    }

    public forceReload(): void {
        this.showLoadingBoxSpinner();
        this.hiTestService.forceReload();
    }

    public dumpHiSet(): void {
        if (this.orderToRun) {
            this.hiTestService.dumpHiSet();
        } else {
            this.hiTestService.dumpHiSetResponse = "Load a order first!";
        }
    }

    private formatDate(date: Date): string {
        const dateObject = new Date(date);
        const year = dateObject.getFullYear().toString().padStart(4, "0");
        const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        const day = dateObject.getDate().toString().padStart(2, "0");
        const hours = dateObject.getHours().toString().padStart(2, "0");
        const minutes = dateObject.getMinutes().toString().padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    private showLoadingBoxSpinner(): void {
        this.loadingBoxText = $localize `:@@hitest.loading.test.sequence:Loading Test Sequence`;
        this.showLoadingBox = true;
    }

    private askForStatus(): void {
        this.hiTestService.statusCan();
        this.hiTestService.statusPlc();
    }
}
