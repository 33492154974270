<ng-container *ngIf="showAlert">
    <hic-response-alert [visible]="showAlert" [message]="message" (closed)="closeAlert()"
        (answer)="removeAnswer($event)">
    </hic-response-alert>
</ng-container>

<div class="background-panel">
    <div class="panel">
        <div i18n>Test Bench</div>
        <div class="testbench-name-group">
            <div class="input-group">
                <hic-compact-input2 type="text" name="name" [allowEmpty]="false"
                    [(ngModel)]="testBenchInEdit.name">
                </hic-compact-input2>
            </div>
            <div *ngIf="selectedTestBench" i18n class="testbench-id">
                Id: {{ selectedTestBench.id }}
            </div>
        </div>
    </div>

    <div class="panel">
        <div class="testbench-config-rows">
            <hic-testbench-interface *ngFor="let testBenchInterface of testBenchInEdit.interfaces"
                [testBenchInterface]="testBenchInterface"
                (interfaceRemovalRequested)="removeBenchInterface($event)">
            </hic-testbench-interface>
        </div>
        <div class="indented">
            <button hicAddBtn (click)="addNew()"></button>
        </div>
    </div>

    <div class="footer">
        <button class="left" (click)="onUndoClicked()" [disabled]="!isDirty">Undo</button>
        <button class="right" (click)="onSaveClicked()" [disabled]="!isDirty || validationStatus !== statuses.valid">Save</button>
    </div>
</div>
