import { Component, Input, OnInit } from "@angular/core";
import { IHiTestModuleModel } from "../../models/hitest.model";
import { ModuleColoringService } from "../../services/module-coloring.service";

@Component({
    selector: "hic-module-item",
    templateUrl: "./module-item.component.html",
    styleUrls: ["./module-item.component.scss"]
})

export class ModuleItemComponent implements OnInit {
    public backgroundColor = "";
    @Input() public disabled = false;
    @Input() public disabledTitle = "";
    @Input() public navigatable = false;
    @Input() public module: IHiTestModuleModel;
    @Input() public assignable = false;
    @Input() public isAssigned = false;
    @Input() public isSelected = false;
    @Input() public sortorderItem = "";
    constructor(private moduleColoringService: ModuleColoringService) { }

    ngOnInit(): void {
        this.backgroundColor = this.moduleColoringService.getModuleColor(this.module.Id);
    }
}
