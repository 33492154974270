<div class="hic-test-sequence-container">
    <div class="hic-assigned-modules-list-container">
        <div>
            Select location
            <select
                class="hic-select-small"
                [(ngModel)]="selectedLocation"
                [disabled]="hasChanges$ | async"
                [title]="!(hasChanges$ | async) ? 'Select location to edit test sequence.' : 'Cannot change location with pending changes. Please, save or undo.'"
                (ngModelChange)="changeLocation()">
                <option *ngFor="let location of locations; trackBy:locationById" [ngValue]="location">{{location?.locationName}}</option>
            </select>
        </div>
        <hic-assigned-modules-list></hic-assigned-modules-list>
    </div>
    <div class="hic-test-sequence-list-container">
        <hic-hitest-row-order-editor [location]="selectedLocation"></hic-hitest-row-order-editor>
    </div>
    <div class="hic-test-sequence-list-zoomed-out">
        <hic-row-order-overview></hic-row-order-overview>
    </div>

    <!-- *** Keep this part commentented until we have support for viewing instructions and input values here *** -->
    <!-- <div class="hic-details-container">
         <hic-collapsable-panel #collapsable [allowOpen]="true" [collapsed]="false" style="cursor: pointer;" header i18n="@@hitest.hitest-operator-live-values">Instructions</hic-collapsable-panel>
        <div class="testing-values-container">
            <hic-hitest-criterias></hic-hitest-criterias>
        </div>
    </div> -->

</div>

<hic-fullscreen-spinner [visible]="showDarkbox" [text]="darkBoxMessage">
</hic-fullscreen-spinner>

