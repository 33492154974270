import {
    AfterContentInit,
    ContentChildren,
    Directive,
    EventEmitter,
    Output,
    QueryList,
    Input,
    OnDestroy
} from "@angular/core";
import { SortableDirective } from "./sortable.directive";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export interface SortEvent {
    currentIndex: number;
    newIndex: number;
}

const distance = (rectA: ClientRect, rectB: ClientRect): number => Math.sqrt(
        Math.pow(rectB.top - rectA.top, 2) +
        Math.pow(rectB.left - rectA.left, 2)
    );

const hCenter = (rect: ClientRect): number => rect.left + rect.width / 2;

const vCenter = (rect: ClientRect): number => rect.top + rect.height / 2;

@Directive({ selector: "[appSortableList]" })
export class SortableListDirective implements AfterContentInit, OnDestroy {
    @ContentChildren(SortableDirective) public sortables: QueryList<SortableDirective>;

    @Output() public sort = new EventEmitter<SortEvent>();
    @Output() public dragStart = new EventEmitter<number>();
    @Output() public dragEnd = new EventEmitter<number>();

    @Input() public sourceUpdated$: Observable<boolean>;
    private directiveDestroyed$: Subject<boolean> = new Subject<boolean>();

    private clientRects: ClientRect[];
    private lastSortEvent: SortEvent;

    public ngAfterContentInit(): void {
        this.initiateSortableList();
        this.sourceUpdated$.pipe(takeUntil(this.directiveDestroyed$)).subscribe(() => {
            this.initiateSortableList();
        });
    }

    public ngOnDestroy(): void {
        this.directiveDestroyed$.next(true);
    }

    public initiateSortableList(): void {
        this.sortables.forEach((sortable, index) => {
            sortable.dragMove
            .pipe(takeUntil(this.directiveDestroyed$), takeUntil(this.sourceUpdated$))
            .subscribe(event => {
                // In original code (https://www.youtube.com/watch?v=dDfN9w76G1c&list=PLJpL6ImpHi2i1ALq7-x7zRADoole7eq8U&index=6)
                // measureClientRects() is done on sortable.dragStart, but that gives problem when
                // list is auto-scrolled during dragging. Therefore now run on every dragMove.
                this.measureClientRects();

                this.detectSorting(sortable, event);
            });
            sortable.dragStart
            .pipe(takeUntil(this.directiveDestroyed$), takeUntil(this.sourceUpdated$))
            .subscribe(event => {
                this.dragStart.emit(index);
            });
            sortable.dragEnd
            .pipe(takeUntil(this.directiveDestroyed$), takeUntil(this.sourceUpdated$))
            .subscribe(event => {
                this.dragEnd.emit(this.lastSortEvent?.newIndex);
            });
        });
    }

    private measureClientRects(): void {
        this.clientRects = this.sortables.map(sortable =>
            sortable.element.nativeElement.getBoundingClientRect()
        );
    }

    private detectSorting(sortable: SortableDirective, event: PointerEvent): void {
        const currentIndex = this.sortables.toArray().indexOf(sortable);
        const currentRect = this.clientRects[currentIndex];

        this.clientRects
            .slice()
            .sort(
                (rectA, rectB) =>
                    distance(rectA, currentRect) - distance(rectB, currentRect)
            )
            .filter(rect => rect !== currentRect)
            .some(rect => {
                const isHorizontal = rect.top === currentRect.top;
                const isBefore = isHorizontal
                    ? rect.left < currentRect.left
                    : rect.top < currentRect.top;

                const moveBack =
                    isBefore &&
                    (isHorizontal
                        ? event.clientX < hCenter(rect)
                        : event.clientY < vCenter(rect));

                const moveForward =
                    !isBefore &&
                    (isHorizontal
                        ? event.clientX > hCenter(rect)
                        : event.clientY > vCenter(rect));

                if (moveBack || moveForward) {
                    this.lastSortEvent = {
                        currentIndex,
                        newIndex: this.clientRects.indexOf(rect)
                    };
                    this.sort.emit(this.lastSortEvent);
                    return true;
                }

                return false;
            });
    }
}
