import { Injectable, OnInit } from "@angular/core";
import { EmulatedInput } from "../models/hitest.model";
import { HiTestHttpService } from "./hitest.http.service";

@Injectable({
    providedIn: "root",
})
export class EmulatorService {
    public emulatedInputs: EmulatedInput[] = [];

    constructor(private hitestHttpService: HiTestHttpService) {
        this.hitestHttpService.getEmulatedInputs().subscribe((response) => {
            this.emulatedInputs = response;
        });
    }

    /**
     * TODO: Convert to await-async pattern https://jira.shared.tds.cargotec.com/browse/HIAA-2183
     */
    public ChangeEmulatedValue(changedInput: EmulatedInput): void {
        this.hitestHttpService.updateEmulatedInput(changedInput).subscribe();
    }

}
