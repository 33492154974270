import { firstValueFrom, Subject, takeUntil, timer } from "rxjs";
import { Injectable, Inject, OnDestroy } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { SIGNALR_TOKEN } from "./signal-r-provider";
import {
    IHiTestMessage, IHiTestAskClientForDataUpdateMessage,
    IHiTestGuidToRestartMessage, IHiTestTimerStatusMessage,
    IHiTestProgressMessage, IHiTestRowsToSkipMessage,
    IHiTestInputsStatusMessage, IHiTestRowUpdateMessage,
    IHiTestAskOperatorMessage, IHiTestAskGuiToClearRequestMessage,
    IReceiveDiagnosticValueMessage, operatorResponseDataType, IHiTestShowSpinnerMessage,
    IHiTestShowProgressBarMessage, IHiTestShowToleranceMetersMessage, IHiTestUpdateToleranceMetersMessage
} from "../operator/models/hitest-status-message.interface";
import { HiTestHttpService } from "./hitest.http.service";
import { HubUrls } from "src/app/globals/urls";

@Injectable({
    providedIn: "root"
})
export class HiTestStatusHubService implements OnDestroy {
    public static readonly messageNames = {
        ReceiveGenericMessage: "ReceiveGenericMessage",
        ReceiveAskClientForDataUpdate: "ReceiveAskClientForDataUpdate",
        ReceiveShutdownMessage: "ReceiveShutdownMessage",
        ReceiveGuidToRestart: "ReceiveGuidToRestart",
        ReceiveAskGuiForReady: "ReceiveAskGuiForReady",
        ReceiveTimerStatus: "ReceiveTimerStatus",
        ReceiveTestProgress: "ReceiveTestProgress",
        ReceiveRowsToSkip: "ReceiveRowsToSkip",
        ReceiveInputsStatus: "ReceiveInputsStatus",
        ReceiveRowUpdate: "ReceiveRowUpdate",
        ReceiveAskOperator: "ReceiveAskOperator",
        ReceiveRequestPriority: "ReceiveRequestPriority",
        ReceiveReleasePriority: "ReceiveReleasePriority",
        ReceiveAskGuiToClearRequest: "ReceiveAskGuiToClearRequest",
        ReceiveDiagnosticValue: "ReceiveDiagnosticValue",
        ShowSpinner: "ShowSpinner",
        ShowProgressBar: "ShowProgressBar",
        ReceiveShowToleranceMeters: "ReceiveShowToleranceMeters",
        ReceiveUpdateToleranceMeters: "ReceiveUpdateToleranceMeters",
    };

    private statusHub: HubConnection;
    private genericMessageSubject = new Subject<IHiTestMessage>();
    private askClientForDataUpdateSubject = new Subject<IHiTestAskClientForDataUpdateMessage>();
    private shutdownMessageSubject = new Subject<IHiTestMessage>();
    private guidToRestartMessageSubject = new Subject<IHiTestGuidToRestartMessage>();
    private askGuiForReadyMessageSubject = new Subject<IHiTestMessage>();
    private timerStatusMessageSubject = new Subject<IHiTestTimerStatusMessage>();
    private testProgressMessageSubject = new Subject<IHiTestProgressMessage>();
    private rowsToSkipMessageSubject = new Subject<IHiTestRowsToSkipMessage>();
    private inputsStatusMessageSubject = new Subject<IHiTestInputsStatusMessage>();
    private showToleranceMetersMessageSubject = new Subject<IHiTestShowToleranceMetersMessage>();
    private updateToleranceMetersMessageSubject = new Subject<IHiTestUpdateToleranceMetersMessage>();
    private rowUpdateMessageSubject = new Subject<IHiTestRowUpdateMessage>();
    private askOperatorMessageSubject = new Subject<IHiTestAskOperatorMessage>();
    private requestPriorityMessageSubject = new Subject<IHiTestMessage>();
    private releasePriorityMessageSubject = new Subject<IHiTestMessage>();
    private askGuiToClearRequestMessageSubject = new Subject<IHiTestAskGuiToClearRequestMessage>();
    private receiveDiagnosticValueMessageSubject = new Subject<IReceiveDiagnosticValueMessage>();
    private showSpinnerMessageSubject = new Subject<IHiTestShowSpinnerMessage>();
    private showProgressBarMessageSubject = new Subject<IHiTestShowProgressBarMessage>();

    private readonly cancelReconnect$ = new Subject<void>();
    private allowReconnect = true;
    private reconnectInProgress = false;

    public genericMessage$ = this.genericMessageSubject.asObservable();
    public askClientForDataUpdateMessage$ = this.askClientForDataUpdateSubject.asObservable();
    public shutdownMessage$ = this.shutdownMessageSubject.asObservable();
    public guidToRestartMessage$ = this.guidToRestartMessageSubject.asObservable();
    public askGuiForReadyMessage$ = this.askGuiForReadyMessageSubject.asObservable();
    public timerStatusMessage$ = this.timerStatusMessageSubject.asObservable();
    public testProgressMessage$ = this.testProgressMessageSubject.asObservable();
    public rowsToSkipMessage$ = this.rowsToSkipMessageSubject.asObservable();
    public inputsStatusMessage$ = this.inputsStatusMessageSubject.asObservable();
    public showToleranceMetersMessage$ = this.showToleranceMetersMessageSubject.asObservable();
    public updateToleranceMetersMessage$ = this.updateToleranceMetersMessageSubject.asObservable();
    public rowUpdateMessage$ = this.rowUpdateMessageSubject.asObservable();
    public askOperatorMessage$ = this.askOperatorMessageSubject.asObservable();
    public requestPriorityMessage$ = this.requestPriorityMessageSubject.asObservable();
    public releasePriorityMessage$ = this.releasePriorityMessageSubject.asObservable();
    public askGuiToClearRequestMessage$ = this.askGuiToClearRequestMessageSubject.asObservable();
    public diagnosticValueMessage$ = this.receiveDiagnosticValueMessageSubject.asObservable();
    public showSpinnerMessage$ = this.showSpinnerMessageSubject.asObservable();
    public showProgressBarMessage$ = this.showProgressBarMessageSubject.asObservable();

    public constructor(@Inject(SIGNALR_TOKEN) private signalR: any, private hitestHttpService: HiTestHttpService) { }

    public async init(): Promise<void> {
        if (this.statusHub !== undefined && this.reconnectInProgress === false) {
            console.log("HiTestStatusHub init Early exit");
            // Early exit if already created
            return;
        }

        this.allowReconnect = true;
        this.reconnectInProgress = false;

        try {

            this.statusHub = this.createHub();


            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveGenericMessage, (receivedMessage: IHiTestMessage) => {
                this.genericMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveAskClientForDataUpdate,
                (receivedMessage: IHiTestAskClientForDataUpdateMessage) => {
                    this.askClientForDataUpdateSubject.next(receivedMessage);
                });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveShutdownMessage, (receivedMessage: IHiTestMessage) => {
                this.shutdownMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveGuidToRestart, (receivedMessage: IHiTestGuidToRestartMessage) => {
                this.guidToRestartMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveAskGuiForReady, (receivedMessage: IHiTestMessage) => {
                this.askGuiForReadyMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveTimerStatus, (receivedMessage: IHiTestTimerStatusMessage) => {
                this.timerStatusMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveTestProgress, (receivedMessage: IHiTestProgressMessage) => {
                this.testProgressMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveRowsToSkip, (receivedMessage: IHiTestRowsToSkipMessage) => {
                this.rowsToSkipMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveInputsStatus, (receivedMessage: IHiTestInputsStatusMessage) => {
                this.inputsStatusMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveRowUpdate, (receivedMessage: IHiTestRowUpdateMessage) => {
                this.rowUpdateMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveAskOperator, (receivedMessage: IHiTestAskOperatorMessage) => {
                const message = this.massageIncomingMessage(receivedMessage);
                this.askOperatorMessageSubject.next(message);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveRequestPriority, (receivedMessage: IHiTestMessage) => {
                this.requestPriorityMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveReleasePriority, (receivedMessage: IHiTestMessage) => {
                this.releasePriorityMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveAskGuiToClearRequest,
                (receivedMessage: IHiTestAskGuiToClearRequestMessage) => {
                    this.askGuiToClearRequestMessageSubject.next(receivedMessage);
                });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveDiagnosticValue,
                (receivedMessage: IReceiveDiagnosticValueMessage) => {
                    this.receiveDiagnosticValueMessageSubject.next(receivedMessage);
                });

            this.statusHub.on(HiTestStatusHubService.messageNames.ShowSpinner, (receivedMessage: IHiTestShowSpinnerMessage) => {
                this.showSpinnerMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ShowProgressBar, (receivedMessage: IHiTestShowProgressBarMessage) => {
                this.showProgressBarMessageSubject.next(receivedMessage);
            });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveShowToleranceMeters,
                (receivedMessage: IHiTestShowToleranceMetersMessage) => {
                    this.showToleranceMetersMessageSubject.next(receivedMessage);
                });

            this.statusHub.on(HiTestStatusHubService.messageNames.ReceiveUpdateToleranceMeters,
                (receivedMessage: IHiTestUpdateToleranceMetersMessage) => {
                    this.updateToleranceMetersMessageSubject.next(receivedMessage);
                });

            await this.statusHub.start();
            console.log("HiTestStatusHub Initiated");

            this.statusHub.onclose(this.onClose);
        } catch (error) {
            console.log("HiTestStatusHub error", error);
            this.scheduleReconnect();
        }
    }

    private scheduleReconnect(): void {
        if (!this.allowReconnect) {
            return;
        }

        this.reconnectInProgress = true;

        console.log("Reconnecting HiTestStatusHub in 2 seconds...");
        // Schedule the reconnect attempt
        timer(2000).pipe(takeUntil(this.cancelReconnect$)).subscribe(() => {
            this.init();
        });
    }

    private onClose = (): void => {
        this.scheduleReconnect();
    }

    public ngOnDestroy(): void {
        for (const key in HiTestStatusHubService.messageNames) {
            if (key) {
                this.statusHub?.off(HiTestStatusHubService.messageNames[key]);
            }
        }
        this.cancelReconnect$.next();
        this.allowReconnect = false;
        this.statusHub?.stop();
    }

    public async requestDiagValuesUpdate(): Promise<void> {
        await firstValueFrom(this.hitestHttpService.requestDiagValuesUpdate());
    }

    private createHub(): HubConnection {
        const builder = this.signalR.HubConnectionBuilder as HubConnectionBuilder;
        return builder
            .withUrl(`${HubUrls.hitest}?connectionId=${sessionStorage.getItem("connectionId")}`)
            .build();
    }

    private massageIncomingMessage(message: IHiTestAskOperatorMessage): IHiTestAskOperatorMessage {
        // expectedResponseType will be received as a string, convert it to matching enum.
        const expectedTypeString = message.expectedResponseType?.toString().toLowerCase();
        message.expectedResponseType = operatorResponseDataType[expectedTypeString];
        return message;
    }
}
