/* eslint-disable no-useless-catch */
import { Injectable, OnDestroy } from "@angular/core";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { HiTestMessageService } from "src/app/hitest/services/message/hitest-message.service";
import { IHiTestModuleModel, IHiTestModuleResponse, IHiTestPreviousTestDTO, IHiTestSortorderResponse, InitParametersResponseDto, ISaveHiTestDataResponse, modulesSelectionResponseDto } from "../models/hitest.model";
import { HiTestModuleModel } from "./../models/hitest-newmodule.model";
import { CanWorkerConfiguration, ConnectionStatus, ICraneEquipmentDTO, PlcWorkerConfiguration } from "./../models/hitest.model";
import { HiTestModuleEditingService } from "./hitest-modules-editing.service";
import { HiTestHttpService } from "./hitest.http.service";
import { SortingService } from "./sorting.service";
import { LocaleService } from "src/app/core/localisation/services/locale/locale.service";

@Injectable({
    providedIn: "any"
})
export class HiTestService implements OnDestroy {
    public isSavingSubject = new Subject<boolean>();
    public isSaving$ = this.isSavingSubject.asObservable();
    private saveReadySubject = new Subject<boolean>();
    public saveReady$ = this.saveReadySubject.asObservable();

    private craneEquipmentReadySubject = new Subject<boolean>();
    public craneEquipmentReady$ = this.craneEquipmentReadySubject.asObservable();
    private tempValueSlotsReadySubject = new Subject<boolean>();
    public tempValueSlotsReady$ = this.tempValueSlotsReadySubject.asObservable();

    private setNextRowPerformedSubject = new Subject<boolean>();
    public setNextRowPerformed$ = this.setNextRowPerformedSubject.asObservable();

    public tempValueSlots: string[] = [];
    public timerSlots: string[];
    public lastAddedNewId = 0;

    public canConfiguration: CanWorkerConfiguration;
    private canStatusSubject = new Subject<ConnectionStatus>();
    public canStatus$ = this.canStatusSubject.asObservable();
    public plcConfiguration: PlcWorkerConfiguration;
    public dumpHiSetResponse: string;
    private plcStatusSubject = new Subject<ConnectionStatus>();
    public plcStatus$ = this.plcStatusSubject.asObservable();
    public craneEquipment = this.newCraneEquipmentEntity();
    public previousTest = this.newPerformedTestEntity();
    public robotMode = false;
    public robotModeButtonEnabled = true;
    public robotModeButtonVisible = false;

    private serviceDestroyedSubject: Subject<boolean> = new Subject<boolean>();
    public serviceDestroyed$: Observable<boolean> = this.serviceDestroyedSubject.asObservable();

    public modules: IHiTestModuleModel[] = [];

    constructor(
        private hitestHttpService: HiTestHttpService,
        private moduleEditingService: HiTestModuleEditingService,
        private sortingService: SortingService,
        private messageService: HiTestMessageService,
        private localeService: LocaleService
    ) {
        this.setupModulesSubscription();
    }

    ngOnDestroy(): void {
        this.serviceDestroyedSubject.next(true);
        this.serviceDestroyedSubject.complete();
    }

    private setupModulesSubscription(): void {
        this.moduleEditingService.modulesUpdated$.pipe(takeUntil(this.serviceDestroyed$)).subscribe(response => {
            if (response) {
                this.modules = this.moduleEditingService.allModules;
            }
        });
    }

    public async localeChanged(locale: string): Promise<void> {
        await firstValueFrom(this.hitestHttpService.localeChanged(locale));
    }

    public async getCurrentTestGuid(): Promise<string> {
        const response = await firstValueFrom(this.hitestHttpService.getCurrentTestGuid());
        return response;
    }

    public async resendMessages(): Promise<void> {
        await firstValueFrom(this.hitestHttpService.resendMessages());
    }

    public async getTempValueSlots(): Promise<string[]> {
        try {
            const response = await firstValueFrom(this.hitestHttpService.getTempValueSlots());
            return response.map(x => x.inputName);
        } catch (error) {
            throw error;
        }
    }

    public async getTimerSlots(): Promise<string[]> {
        try {
            const response = await firstValueFrom(this.hitestHttpService.getTimerSlots());
            return response.map(x => x.inputName);
        } catch (error) {
            throw error;
        }
    }

    public async saveAndReloadSortorder(locationId: number): Promise<ISaveHiTestDataResponse> {
        try {
            this.isSavingSubject.next(true);

            const lastModifiedInLocation = this.moduleEditingService.getLastModifiedSortorderInLocation(locationId);

            const sortorderToSave = this.sortingService.generateRoworderlist().filter(x => x.LocationId === locationId);
            const toSave: IHiTestSortorderResponse = {
                HiTestModuleRowOverallSortorderEntities: sortorderToSave,
                LocationId: locationId,
                LastModifiedInLocation: lastModifiedInLocation
            };

            const response = await firstValueFrom(this.hitestHttpService.saveSortorder(toSave).pipe(first()));
            this.saveReadySubject.next(response.success);
            if (response.success) {
                await this.moduleEditingService.updateModuleDataAsync();
                this.sortingService.detectChanges();
            }
            return response;
        } catch (err) {
            this.messageService.sendAlert("Error occurred when saving the module changes.\n" + err.message);
            this.saveReadySubject.next(false);
            return null;
        } finally {
            this.isSavingSubject.next(false);
        }
    }

    // eslint-disable-next-line max-len
    public async saveModule(module: IHiTestModuleModel, allowSortorderRemove: boolean): Promise<ISaveHiTestDataResponse> {
        if (!module) {
            const errorMessage = "Cannot save because no module was selected";
            this.messageService.sendAlert(errorMessage);
            throw new Error(errorMessage);
        }

        try {
            this.isSavingSubject.next(true);
            await this.sortingService.prepareForSaveAsync(module);
            const toSave: IHiTestModuleResponse = {
                HiTestModuleEntity: module,
                AllowSortorderRemove: allowSortorderRemove,
            };

            const response = await firstValueFrom(this.hitestHttpService.saveModule(toSave).pipe(first()));
            this.saveReadySubject.next(response.success);
            if (response.success) {
                await this.moduleEditingService.updateModuleDataAsync(module);
                this.sortingService.detectChanges();
                // Also update orgPs2000Groups here
            }
            return response;
        } catch (err) {
            this.messageService.sendAlert("Error occurred when saving the module changes.\n" + err.message);
            this.saveReadySubject.next(false);
            return null;
        } finally {
            this.isSavingSubject.next(false);
        }
    }

    public async deleteModule(module: HiTestModuleModel): Promise<void> {
        if (!module) {
            const errorMessage = "Cannot delete because no module was selected";
            this.messageService.sendAlert(errorMessage);
            throw new Error(errorMessage);
        }
        try {
            this.isSavingSubject.next(true);
            const toDelete: IHiTestModuleResponse = {
                HiTestModuleEntity: module,
                AllowSortorderRemove: false,
            };
            const response = await firstValueFrom(this.hitestHttpService.deleteModule(toDelete).pipe(first()));
            this.saveReadySubject.next(response.success);
            if (response.success) {
                await this.moduleEditingService.updateModuleDataAsync();
                this.sortingService.detectChanges();
            } else {
                this.messageService.sendAlert(response.extraInformation);
            }
        } catch (error) {
            this.messageService.sendAlert(`Could not delete module M${module.Id} ${module.TestModuleName}\nMessage: ${error.message}`);
            this.saveReadySubject.next(false);
        } finally {
            this.isSavingSubject.next(false);
        }
    }

    private newHiTestModuleEntity(): IHiTestModuleModel {
        const hiTestModuleEntity: IHiTestModuleModel = new HiTestModuleModel();
        return hiTestModuleEntity;
    }

    public async newModule(): Promise<HiTestModuleModel> {
        try {
            this.isSavingSubject.next(true);
            const toAdd: IHiTestModuleResponse = {
                HiTestModuleEntity: this.newHiTestModuleEntity(),
                AllowSortorderRemove: false,
            };
            await firstValueFrom(this.hitestHttpService.saveModule(toAdd).pipe(first()));
            this.saveReadySubject.next(true);
            await this.moduleEditingService.updateModuleDataAsync();
            this.sortingService.detectChanges();

            // TODO: Backend does not provide any information about the module that have been saved.
            // This is a hackish workaround: we just assume that the module with highest ID is our new module.
            // https://jira.shared.tds.cargotec.com/browse/HIAA-4284
            const newModules = [...this.moduleEditingService.allModules];
            newModules.sort((a, b) => a.Id - b.Id).reverse();
            const module = newModules.find(x => !!x); // Takes the first value if any
            return module;
        } catch (err) {
            this.saveReadySubject.next(false);
            const error = err as Error;
            const message = "Failed to create new module.";
            if (error?.message) {
                err.message = `${message} ${error.message}`;
            }
            this.saveReadySubject.next(false);
            throw err;
        } finally {
            this.isSavingSubject.next(false);
        }
    }

    /**
     * TODO: Convert to await-async pattern https://jira.shared.tds.cargotec.com/browse/HIAA-2183
     */
    public getEquipmentFromOrderNumber(orderNumber: string): ICraneEquipmentDTO | void {
        this.hitestHttpService.getCraneEquipment(orderNumber).pipe(takeUntil(this.serviceDestroyed$)).subscribe(
            response => {
                this.craneEquipment = response;
                this.craneEquipmentReadySubject.next(true);
                console.log("Successfully fetched equipment");
            },
            err => {
                console.log("Something went wrong when fetching equipment");
                console.log(err);
                this.craneEquipmentReadySubject.next(true);
            }
        );
    }

    public async getPreviousTestFromOrderNumberAsync(orderNumber: string): Promise<IHiTestPreviousTestDTO> {
        try {
            const response = await firstValueFrom(this.hitestHttpService.getPreviousTest(orderNumber)
                .pipe(takeUntil(this.serviceDestroyed$), first()));
            this.previousTest = response;
            console.log("Successfully fetched previous test");
            return response;
        } catch (err) {
            console.log("Something went wrong when fetching previous test", err);
            return undefined;
        }
    }

    public async selectPreviousTestFromGuidAsync(guid: string): Promise<IHiTestPreviousTestDTO> {
        try {
            const response = await firstValueFrom(this.hitestHttpService.selectPreviousTest(guid)
                .pipe(takeUntil(this.serviceDestroyed$), first()));
            this.previousTest = response;
            console.log("Successfully selected previous test");
            return response;
        } catch (err) {
            console.log("Something went wrong when selecting previous test", err);
            return undefined;
        }
    }

    public async invalidatePreviousTest(guid: string): Promise<boolean> {
        try {
            await firstValueFrom(this.hitestHttpService.invalidatePreviousTest(guid).pipe(takeUntil(this.serviceDestroyed$), first()));
            console.log("Successfully invalidated previous test");
            return true;
        } catch (err) {
            console.log("Something went wrong when invalidating previous test", err);
            return false;
        }
    }

    /**
     * TODO: Convert to await-async pattern https://jira.shared.tds.cargotec.com/browse/HIAA-2183
     */

    public async stopTest(reason: string): Promise<boolean> {
        try {
            const response = await firstValueFrom(this.hitestHttpService.stopTest(reason));
            console.log(response ? "Stopped test" : "Failed to stop test");
            return response;
        } catch (error) {
            console.log("Error while trying to stop test", error);
        }
        return false;
    }

    public async restartTest(testGuidToRestart: string): Promise<boolean> {
        try {
            const response = await firstValueFrom(this.hitestHttpService.restartTest(testGuidToRestart));
            console.log(response ? "Restarted test" : "Failed to restart test");
            return response;
        } catch (error) {
            console.log("Error while trying to restart test", error);
        }
        return false;
    }

    public async confirmStep(identifier: string): Promise<boolean> {
        try {
            const response = await firstValueFrom(this.hitestHttpService.confirmStep(identifier));
            console.log(response ? "Confirmed step" : "Failed to confirm step");
            return response;
        } catch (error) {
            console.log("Error while trying to confirm step: " + error);
        }
        return false;
    }

    public async sendInitParametersResponse(dto: InitParametersResponseDto): Promise<boolean> {
        try {
            const response = await firstValueFrom(this.hitestHttpService.initParametersResponse(dto));
            console.log(response ? "Successfully init parameter response" : "Failed to send init parameter response");
            return response;
        } catch (error) {
            console.log("Error while trying to send operator response", error);
        }
        return false;
    }

    public async sendModulesSelectionResponse(dto: modulesSelectionResponseDto): Promise<boolean> {
        try {
            const response = await firstValueFrom(this.hitestHttpService.modulesSelectionResponse(dto));
            console.log(response ? "Successfully sent modules selection response" : "Failed to send modules selection response");
            return response;
        } catch (error) {
            console.log("Error while trying to send modules selection response", error);
        }
        return false;
    }

    public async sendOperatorResponse(identifier: string, action: string, additionalData = ""): Promise<boolean> {
        try {
            const response = await firstValueFrom(this.hitestHttpService.operatorResponse(identifier, action, additionalData));
            console.log(response ? "Successfully sent operator response" : "Failed to send operator response");
            return response;
        } catch (error) {
            console.log("Error while trying to send operator response", error);
        }
        return false;
    }

    public async cancelOperatorRequests(identifiers: string[]): Promise<boolean> {
        const result = await firstValueFrom(this.hitestHttpService.cancelOperatorRequests(identifiers));
        return result;
    }

    public activateSkipLegs(): void {
        this.hitestHttpService.activateSkipLegs().subscribe(
            (response: boolean) => {
                if (response) {
                    this.messageService.sendMessage("'Skip Legs' activated");
                } else {
                    this.messageService.sendAlert("Failed to activate 'Skip Legs'");
                }
            }
        );
    }

    public forceReload(): void {
        this.hitestHttpService.forceReload().subscribe(
            (response: boolean) => {
                if (response) {
                    location.reload();
                } else {
                    this.messageService.sendAlert("Failed to force reload");
                }
            }
        );
    }

    public reinitCanSensors(): void {
        this.hitestHttpService.reinitCanSensors().subscribe(
            (response: boolean) => {
                if (response) {
                    this.messageService.sendMessage("Can sensors are reinitiated");
                } else {
                    this.messageService.sendAlert("Failed to reinit can sensors");
                }
            }
        );
    }

    /**
     * TODO: Convert to await-async pattern https://jira.shared.tds.cargotec.com/browse/HIAA-2183
     */
    public toggleTestRunning(startFromStep: number): void {
        const startTestDto: ICraneEquipmentDTO = this.createEquipmentDto(startFromStep);
        this.hitestHttpService.runTest(startTestDto).pipe(takeUntil(this.serviceDestroyed$)).subscribe(
            response => {
                console.log("Start test response:");
                console.log(response);
            },
            err => {
                console.log("Start test error:");
                console.log(err);
            }
        );
    }

    public async setNextRow(Id: number): Promise<boolean> {
        const response = await firstValueFrom(this.hitestHttpService.setNextRow(Id).pipe(takeUntil(this.serviceDestroyedSubject)));
        this.setNextRowPerformedSubject.next(response);
        return response;
    }

    private createEquipmentDto(startStepNo: number): ICraneEquipmentDTO {
        return {
            orderNumber: this.craneEquipment.orderNumber,
            startFromRow: startStepNo,
            pS2000Codes: this.craneEquipment.pS2000Codes,
            omspS2000Codes: this.craneEquipment.omspS2000Codes,
            omspS2000ToHiSet: this.craneEquipment.omspS2000ToHiSet,
            equipments: null,
            craneModel: this.craneEquipment.craneModel,
            craneType: this.craneEquipment.craneType,
            orderType: this.craneEquipment.orderType,
            craneSerialNumber: this.craneEquipment.craneSerialNumber,
            itemId: this.craneEquipment.itemId,
            controlSystem: this.craneEquipment.controlSystem,
            robotMode: this.robotMode,
            restartTestGuid: this.craneEquipment.restartTestGuid,
            operatorLocale: this.localeService.selectedLocale
        };
    }

    public newCraneEquipmentEntity(): ICraneEquipmentDTO {
        const craneEquipmentEntity: ICraneEquipmentDTO = {
            craneModel: "",
            craneType: "",
            orderNumber: "",
            equipments: [
                {
                    name: "",
                    selected: false,
                    hidden: false,
                    notCombineWith: [],
                }
            ],
            pS2000Codes: [],
            omspS2000Codes: [],
            omspS2000ToHiSet: false,
            orderType: "",
            startFromRow: 0,
            craneSerialNumber: "",
            itemId: "",
            controlSystem: "",
            robotMode: false,
            restartTestGuid: undefined,
            operatorLocale: ""
        };

        return craneEquipmentEntity;
    }

    public newPerformedTestEntity(): IHiTestPreviousTestDTO {
        const performedTestEntity: IHiTestPreviousTestDTO = {
            comment: "",
            performedModules: 0,
            totalModules: 0,
            craneEquipment: this.newCraneEquipmentEntity(),
            factory: "",
            orderNo: "",
            paused: "",
            runningTestGuid: "",
            started: "",
            testBench: "",
            orderType: "",
            robotMode: false,
            completedTests: null
        };
        return performedTestEntity;
    }

    public activateCan(): void {
        this.hitestHttpService.activateCan(true).subscribe(
            response => {
                console.log("Testbench CAN started on channel " + response.channelId);
                this.canConfiguration = response;
            },
            err => {
                console.log("Failed to start testbench CAN: " + err);
            }
        );
    }

    public deactivateCan(): void {
        this.hitestHttpService.activateCan(false).subscribe(
            response => {
                console.log("Testbench CAN stopped on channel " + response.channelId);
                this.canConfiguration = response;
            },
            err => {
                console.log("Failed to stop testbench CAN: " + err);
            }
        );
    }

    public statusCan(): void {
        this.hitestHttpService.statusCan().subscribe(
            response => {
                console.log(response);
                this.canStatusSubject.next(response);
            },
            err => {
                console.log("statusCan error " + err);
            }
        );
    }

    public activatePlc(): void {
        this.hitestHttpService.activatePlc(true).subscribe(
            response => {
                console.log("Testbench PLC started " + response.ip + ":" + response.port);
                this.plcConfiguration = response;
            },
            err => {
                console.log("Failed to connect to PLC server: " + err);
            }
        );
    }

    public deactivatePlc(): void {
        this.hitestHttpService.activatePlc(false).subscribe(
            response => {
                console.log("Testbench PLC stopped");
                this.plcConfiguration = response;
            },
            err => {
                console.log("Failed to stop testbench PLC: " + err);
            }
        );
    }

    public statusPlc(): void {
        this.hitestHttpService.statusPlc().subscribe(
            response => {
                console.log(response);
                this.plcStatusSubject.next(response);
            },
            err => {
                console.log("statusPlc error " + err);
            }
        );
    }

    public dumpHiSet(): void {
        const craneEquipment: ICraneEquipmentDTO =
            this.createEquipmentDto(0);

        this.dumpHiSetResponse = "Dumping...";
        this.hitestHttpService.dumpHiSet(craneEquipment).subscribe(
            response => {
                this.dumpHiSetResponse = response.result;
            }
        );
    }

    /**
     *   Lets us use a unique Id to keep track of added records
     *   It will always be a negative value (to avoid risk of conflict with previous Id:s).
     *   When saving, the backend will not find a current record, and therefore create a new record.
     *   If we save again, the backend will treat the recently saved records as "deleted",
     *   remove them from the db, and then add the new records again.
     */
    public getNewUniqueId(): number {
        return --this.lastAddedNewId;
    }

    public emptyCraneEquipment(): void {
        this.craneEquipment = this.newCraneEquipmentEntity();
        this.craneEquipmentReadySubject.next(true);
    }

    public getCraneEquipmentFromPrev(): void {
        this.craneEquipment = this.previousTest.craneEquipment;
        this.craneEquipment.restartTestGuid = this.previousTest.runningTestGuid;
        this.robotMode = this.previousTest.robotMode;
        this.craneEquipmentReadySubject.next(true);
    }

    public formatDateTime(dateTimeValueString: string): string {
        // ToDo - HIAA-5861 Investigate use of "ECMAscripts Internationalization API" instead
        if (dateTimeValueString === "0001-01-01T00:00:00") {
            return "";
        }

        if (!dateTimeValueString.endsWith("Z")) {
            dateTimeValueString = dateTimeValueString + "Z";
        }

        const dateTime = new Date(dateTimeValueString);
        return this.formatDate(dateTime) + " " + this.formatTime(dateTime);
    }

    private formatDate(dateTime): string {
        let month = "" + (dateTime.getMonth() + 1);
        let day = "" + dateTime.getDate();
        const year = "" + dateTime.getFullYear();

        if (month.length < 2) {
            month = "0" + month;
        }
        if (day.length < 2) {
            day = "0" + day;
        }

        return year + "-" + month + "-" + day;
    }

    private formatTime(dateTime): string {
        let hours = "" + dateTime.getHours();
        let minutes = "" + dateTime.getMinutes();
        let seconds = "" + dateTime.getSeconds();

        if (hours.length < 2) {
            hours = "0" + hours;
        }
        if (minutes.length < 2) {
            minutes = "0" + minutes;
        }
        if (seconds.length < 2) {
            seconds = "0" + seconds;
        }

        return hours + ":" + minutes + ":" + seconds;
    }
}
