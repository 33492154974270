<div class="message-container">
    <div [id]="'message_' + message.id" class="message-box" [class.warning-box]="message.messageType === messageType.warning" *ngFor="let message of (messages$ | async)"
        [class.show-message]="message">
        <div class="message-text">
            {{ message.message }}
        </div>
        <div class="message-footer">
            {{ message.timestamp | date:"HH:mm:ss" }}
        </div>
    </div>

    <div [id]="'alert_' + alert.id" class="alert-box" *ngFor="let alert of (alerts$ | async)"
        [class.show-and-stay]="alert">
        <div class="message-header">
            <h3 i18n="@@messages.alert-message.header">Alert</h3>
            <div>
                <button (click)="alert.confirm()">&#x2573; <!-- Unicode big cross character --></button>
            </div>
        </div>
        <div class="message-text">
            {{ alert.message }}
        </div>
        <div class="message-footer">
            {{ alert.timestamp | date:"HH:mm:ss" }}
        </div>
    </div>
</div>
