import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MarkdownModule } from "ngx-markdown";
import { ToolboxModule } from "src/app/shared/toolbox/toolbox.module";
import { GraphicsModule } from "../shared/graphics/graphics.module";
import { SharedModule } from "../shared/shared.module";
import { DraggableModule } from "./admin/components/draggable/draggable.module";
import { AssignedModulesListComponent } from "./admin/components/testSequence/assigned-modules-list/assignedModulesList.component";
import { HiTestRowOrderEditorComponent } from "./admin/components/testSequence/row-order-editor/hitest-row-order-editor.component";
import { HitestRowOrderOverviewComponent } from "./admin/components/testSequence/row-order-overview/hitest-row-order-overview.component";
import { HiTestTestSequenceComponent } from "./admin/components/testSequence/test-sequence.component";
import { ModuleItemComponent } from "./admin/module-item/module-item.component";
import { HiTestRoutingModule } from "./hitest-routing.module";
import { SharedInputModule } from "./inputs/shared/shared-input.module";
import { TestBenchConfigurationModule } from "./inputs/test-bench-configuration/test-bench-configuration.module";
import { HiTestASCTableComponent } from "./operator/components/asc-table/asc-table.component";
import { HitestCriteriasComponent } from "./operator/components/conditions/hitest-criterias.component";
import { ContextMenuComponent } from "./operator/components/context-menu/context-menu.component";
import { DiagnosticValuesComponent } from "./operator/components/diagnostic-values/diagnostic-values.component";
import { EmulatorComponent } from "./operator/components/emulator/emulator.component";
import { HitestEquipmentDndComponent } from "./operator/components/equipment-selection/equipment-dnd/equipment-dnd.component";
import { HiTestEquipmentSelectionComponent } from "./operator/components/equipment-selection/equipment-selection.component";
import { HitestLoadOrderComponent } from "./operator/components/equipment-selection/load-order/load-order.component";
import { PrevOrderComponent } from "./operator/components/equipment-selection/prev-order/prev-order.component";
import { OperatorstepsComponent } from "./operator/components/operatorsteps/operatorsteps.component";
import { HitestToleranceMeterComponent } from "./operator/components/tolerance-meter/hitest-tolerance-meter.component";
import { HitestOperatorComponent } from "./operator/hitestoperator.component";
import { MessageViewerComponent } from "./services/message/message-viewer.component";

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        HiTestRoutingModule,
        ToolboxModule,
        DraggableModule,
        GraphicsModule,
        FormsModule,
        MarkdownModule.forChild(),
        SharedInputModule,
        TestBenchConfigurationModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatIconModule,
        MatTooltipModule,
    ],
    declarations: [
        // Core
        HiTestTestSequenceComponent,
        AssignedModulesListComponent,
        HiTestRowOrderEditorComponent,
        HitestRowOrderOverviewComponent,
        ModuleItemComponent,


        // Operator specific
        HitestOperatorComponent,
        HiTestASCTableComponent,
        HitestCriteriasComponent,
        EmulatorComponent,
        HiTestEquipmentSelectionComponent,
        HitestEquipmentDndComponent,
        HitestLoadOrderComponent,
        PrevOrderComponent,
        DiagnosticValuesComponent,
        OperatorstepsComponent,
        HitestToleranceMeterComponent,
        ContextMenuComponent,
        MessageViewerComponent
    ],
    providers: [],
    exports: [
        HitestCriteriasComponent,
        ModuleItemComponent,
        DiagnosticValuesComponent,
        HitestToleranceMeterComponent,
        ContextMenuComponent,
        MessageViewerComponent
    ],
})
export class HiTestModule { }
