import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Urls } from "src/app/globals/urls";
import { IHiTestModuleResponse, IHiTestModuleRequest, EmulatedInput, InputDTO, ICraneEquipmentDTO, CanWorkerConfiguration, ConnectionStatus, PlcWorkerConfiguration, IHiTestPreviousTestDTO, InitParametersResponseDto, ISaveHiTestDataResponse, IHiTestSortorderResponse, modulesSelectionResponseDto, localeDto } from "../models/hitest.model";
import { HiTestReportSpecificationDetailed, HiTestReportSpecificationFailures, HiTestReportSpecificationSummary } from "./reports.service";


@Injectable({
    providedIn: "root"
})
export class HiTestHttpService {
    constructor(private http: HttpClient) {
    }

    public getCurrentTestGuid(): Observable<string> {
        const result = this.http.get<string>(Urls.HITEST_TEST_GUID);
        return result;
    }

    public resendMessages(): Observable<Response> {
        return this.http.put<Response>(Urls.HITEST_RESEND_MESSAGES, "");
    }

    public localeChanged(locale: string): Observable<Response> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body: localeDto = { locale };
        return this.http.put<Response>(Urls.HITEST_LOCALE_CHANGED, body, { headers: requestHeaders });
    }

    public requestDiagValuesUpdate(): Observable<Response> {
        return this.http.put<Response>(Urls.HITEST_REQUEST_DIAG_VALUES_UPDATE, "");
    }

    public getTempValueSlots(): Observable<InputDTO[]> {
        const params = new HttpParams().set("inputType", "TemporaryValue");
        const result = this.http.get<InputDTO[]>(Urls.HITEST_GET_INPUTS_LIST, { params });
        return result;
    }

    public getTimerSlots(): Observable<InputDTO[]> {
        const params = new HttpParams().set("inputType", "Timer");
        const result = this.http.get<InputDTO[]>(Urls.HITEST_GET_INPUTS_LIST, { params });
        return result;
    }

    public populateData(): Observable<boolean> {
        const result = this.http.get<boolean>(Urls.HITEST_POPULATE_DATA);
        return result;
    }

    public getModules(): Observable<IHiTestModuleRequest> {
        const result = this.http.get<IHiTestModuleRequest>(Urls.HITEST_MODULE);
        return result;
    }

    public saveSortorder(hitestSortorder: IHiTestSortorderResponse): Observable<ISaveHiTestDataResponse> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = hitestSortorder;
        return this.http.put<ISaveHiTestDataResponse>(Urls.HITEST_SAVE_SORTORDER, body, { headers: requestHeaders });
    }

    public saveModule(hitestModule: IHiTestModuleResponse): Observable<ISaveHiTestDataResponse> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = hitestModule;
        return this.http.put<ISaveHiTestDataResponse>(Urls.HITEST_MODULE, body, { headers: requestHeaders });
    }

    public deleteModule(hitestModule: IHiTestModuleResponse): Observable<ISaveHiTestDataResponse> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = hitestModule;
        return this.http.put<ISaveHiTestDataResponse>(Urls.HITEST_DELETE_MODULE, body, { headers: requestHeaders });
    }

    public exportModule(exportModuleId: number): Observable<string> {
        const params = new HttpParams().set("exportModuleId", exportModuleId);
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        return this.http.get<string>(Urls.HITEST_EXPORT_MODULE, { headers: requestHeaders, params });
    }

    public importModule(hitestModule: string): Observable<Response> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = hitestModule;
        return this.http.put<Response>(Urls.HITEST_IMPORT_MODULE, body, { headers: requestHeaders });
    }

    public getEmulatedInputs(): Observable<EmulatedInput[]> {
        return this.http.get<EmulatedInput[]>(Urls.HITEST_GET_EMULATED_INPUTS);
    }

    public updateEmulatedInput(changedInput: EmulatedInput): Observable<Response> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = changedInput;
        return this.http.put<Response>(Urls.HITEST_UPDATE_EMULATED_INPUT, body, { headers: requestHeaders });
    }

    public getCraneEquipment(orderNumber: string): Observable<ICraneEquipmentDTO> {
        const params = new HttpParams().append("orderNumber", orderNumber);
        const result = this.http.get<ICraneEquipmentDTO>(Urls.HITEST_CRANE_EQUIPMENT, { params });
        return result;
    }

    public getPreviousTest(orderNumber: string): Observable<IHiTestPreviousTestDTO> {
        const params = new HttpParams().append("orderNumber", orderNumber);
        const result = this.http.get<IHiTestPreviousTestDTO>(Urls.HITEST_GET_PREV_TEST, { params });
        return result;
    }

    public selectPreviousTest(guid: string): Observable<IHiTestPreviousTestDTO> {
        const params = new HttpParams().append("guid", guid);
        const result = this.http.get<IHiTestPreviousTestDTO>(Urls.HITEST_SELECT_PREV_TEST, { params });
        return result;
    }

    public invalidatePreviousTest(guid: string): Observable<void> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = JSON.stringify(guid);
        const result = this.http.post<void>(Urls.HITEST_INVALIDATE_PREV_TEST, body, {headers: requestHeaders});
        return result;
    }

    public runTest(craneEquipment: ICraneEquipmentDTO): Observable<string> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = craneEquipment;
        return this.http.post<string>(Urls.HITEST_RUN_TEST, body, { headers: requestHeaders });
    }

    public stopTest(reason: string): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = JSON.stringify(reason);
        return this.http.post<boolean>(Urls.HITEST_STOP_TEST, body, { headers: requestHeaders });
    }

    public restartTest(testGuidToRestart: string): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = JSON.stringify(testGuidToRestart);
        return this.http.post<boolean>(Urls.HITEST_RESTART_TEST, body, { headers: requestHeaders });
    }

    public setNextRow(id: number): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = id;
        return this.http.post<boolean>(Urls.SET_NEXT_ROW, body, { headers: requestHeaders });
    }

    public confirmStep(identifier: string): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("identifier", identifier);
        return this.http.post<boolean>(Urls.HITEST_CONFIRM_ROW, "", { headers: requestHeaders });
    }

    public operatorResponse(identifier: string, action: string, additionalData: string): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const operatorResponseDTO = {
            identifier,
            action,
            additionalData
        };
        const body = JSON.stringify(operatorResponseDTO);
        return this.http.post<boolean>(Urls.HITEST_OPERATOR_RESPONSE, body, { headers: requestHeaders });
    }

    public initParametersResponse(dto: InitParametersResponseDto): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        return this.http.post<boolean>(Urls.HITEST_INITPARAMETERS_RESPONSE, dto, { headers: requestHeaders });
    }

    public modulesSelectionResponse(dto: modulesSelectionResponseDto): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        return this.http.post<boolean>(Urls.HITEST_MODULES_SELECTION_RESPONSE, dto, { headers: requestHeaders });
    }

    public cancelOperatorRequests(identifiers: string[]): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = JSON.stringify(identifiers);
        return this.http.post<boolean>(Urls.HITEST_CANCEL_OPERATOR_REQUESTS, body, { headers: requestHeaders });
    }

    public activateSkipLegs(): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        return this.http.put<boolean>(Urls.HITEST_ACTIVATE_SKIP_LEGS, "", { headers: requestHeaders});
    }

    public forceReload(): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        return this.http.put<boolean>(Urls.HITEST_FORCE_RELOAD, "", { headers: requestHeaders});
    }

    public reinitCanSensors(): Observable<boolean> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        return this.http.put<boolean>(Urls.HITEST_REINIT_CAN_SENSORS, "", { headers: requestHeaders});
    }

    public activateCan(activate: boolean): Observable<CanWorkerConfiguration> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = JSON.stringify({ value: activate ? "auto" : "deactivate" });
        return this.http.post<CanWorkerConfiguration>(Urls.HITEST_ACTIVATE_CAN, body, { headers: requestHeaders });
    }

    public statusCan(): Observable<ConnectionStatus> {
        return this.http.get<ConnectionStatus>(Urls.HITEST_STATUS_CAN);
    }

    public activatePlc(activate: boolean): Observable<PlcWorkerConfiguration> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = JSON.stringify({ value: activate ? "auto" : "deactivate" });
        return this.http.post<PlcWorkerConfiguration>(Urls.HITEST_ACTIVATE_PLC, body, { headers: requestHeaders });
    }

    public statusPlc(): Observable<ConnectionStatus> {
        return this.http.get<ConnectionStatus>(Urls.HITEST_STATUS_PLC);
    }

    public dumpHiSet(craneEquipment: ICraneEquipmentDTO): Observable<any> {
        const requestHeaders = new HttpHeaders().set("Content-Type", "application/json");
        const body = craneEquipment;
        return this.http.post<string>(Urls.HITEST_DUMP_HISET, body, { headers: requestHeaders });
    }

    public getHiTestReportSummary(reportSpecification: HiTestReportSpecificationSummary): Observable<HiTestReportSpecificationSummary> {
        let headers = new HttpHeaders();

        for (const [key, value] of Object.entries(reportSpecification)) {
            if (value) {
                headers = headers.append(key, value.toString());
            }
        }

        const result = this.http.get<HiTestReportSpecificationFailures>(Urls.HITEST_REPORT_BASIC, { headers });
        return result;
    }

    public getHiTestReportFailures(reportSpecification: HiTestReportSpecificationFailures): Observable<HiTestReportSpecificationFailures> {
        let headers = new HttpHeaders();

        for (const [key, value] of Object.entries(reportSpecification)) {
            if (value) {
                headers = headers.append(key, value.toString());
            }
        }

        const result = this.http.get<HiTestReportSpecificationFailures>(Urls.HITEST_REPORT_FAILURES, { headers });
        return result;
    }

    public getHiTestReportDetailed(reportSpecification: HiTestReportSpecificationDetailed): Observable<HiTestReportSpecificationDetailed> {
        let headers = new HttpHeaders();

        for (const [key, value] of Object.entries(reportSpecification)) {
            if (value) {
                headers = headers.append(key, value.toString());
            }
        }

        const result = this.http.get<HiTestReportSpecificationDetailed>(Urls.HITEST_REPORT_DETAILED, { headers });
        return result;
    }
}
