<ng-container *ngIf="showAlert===true">
    <hic-response-alert [title]="title" [visible]="showAlert" [message]="message" (closed)="closeAlert()"
        (answer)="onRemoveAlertResponse($event)">
    </hic-response-alert>
</ng-container>

<div class="source-config-row">
    <div class="config-slot">
        <div i18n class="smaller-text">Source</div>
        <select class="hic-select-small" [(ngModel)]="testBenchInterface.source" name="source">
            <option *ngFor="let source of benchInterfaceTypes" [ngValue]="source">
                {{ source }}
            </option>
        </select>
    </div>
    <div class="config-slot">
        <div i18n class="smaller-text">Name</div>
        <hic-compact-input2 type="text" name="name" [allowEmpty]="false" [(ngModel)]="testBenchInterface.name">
        </hic-compact-input2>
    </div>

    <ng-container *ngIf="testBenchInterface.source !== sources.can">
        <div class="config-slot">
            <div i18n class="smaller-text">IPV4 Number</div>
            <hic-compact-input2 type="text" name="name" [allowEmpty]="false" hicAllowedCharacters
                allowedRegExp="^[0-9\.]+$" [(ngModel)]="testBenchInterface.address" [validationFunction]="validateIp"></hic-compact-input2>
        </div>
        <div class="config-slot">
            <div i18n class="smaller-text">Port</div>
            <hic-compact-input2 type="number" name="name" [min]="1" [allowEmpty]="false"
                [(ngModel)]="testBenchInterface.port"></hic-compact-input2>
        </div>
    </ng-container>

    <ng-container *ngIf="testBenchInterface.source === sources.can">
        <div class="config-slot">
            <div i18n class="smaller-text">Serial Number</div>
            <hic-compact-input2 type="text" name="name" [allowEmpty]="false"
                [(ngModel)]="testBenchInterface.address"></hic-compact-input2>
        </div>
        <div class="config-slot">
            <div i18n class="smaller-text">Bit Rate (kbit/s)</div>
            <select class="hic-select-small" name="speed" [(ngModel)]="testBenchInterface.speed">
                <option *ngFor="let speed of bitRates" [ngValue]="speed">
                    {{ speed }}
                </option>
            </select>
        </div>
    </ng-container>
    <div class="hitest-remove-row">
        <button hicDeleteBtn (click)="showRemovalAlert(testBenchInterface)">X</button>
    </div>
</div>
