import { Injectable } from "@angular/core";
import { ITestRowViewModel } from "../../models/hitest.model";

@Injectable({
    providedIn: "root"
})

export class ContextMenuService {
    public x = 0;
    public y = 0;
    public row: ITestRowViewModel;
    public showContextMenuRow = false;
    public showContextMenuModule = false;
}
