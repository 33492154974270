import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { HiTestConstants } from "src/app/hitest/models/hitest.model";
import { HiTestBenchDTO, TestBenchInterfaceDTO } from "../../../models/hitestinputs.model";

enum ValidationStatus {
    valid,
    missingSource,
    missingName,
    missingAddress,
    missingCanSpeed,
    missingPort,
    invalidPortNumber
}
@Component({
    selector: "hic-testbench-editor",
    templateUrl: "./testbench-editor.component.html",
    styleUrls: ["../../../admin/hitest-admin.scss", "./testbench-editor.component.scss"]
})
export class TestbenchEditorComponent implements OnInit, AfterViewChecked {
    @Input() public selectedTestBench: HiTestBenchDTO;
    @Output() public changedConfigRow = new EventEmitter<HiTestBenchDTO>();
    @Output() public closed = new EventEmitter();
    @Output() public deleteTestBenchConfig = new EventEmitter<HiTestBenchDTO>();

    private newTestBenchInterface: TestBenchInterfaceDTO
        = { id: "", source: "", name: "", speed: "", address: "", port: 0 };

    public message = "";
    public showAlert = false;
    public testBenchInterfaceItem: TestBenchInterfaceDTO;
    public testBenchInEdit: HiTestBenchDTO;
    public sources = HiTestConstants.sensorInterfaces;
    public selectedName = "";
    private selectedTestBenchJson = "";
    public statuses = ValidationStatus; // Exposes the ValidationStatus enum to the template.
    public validationStatus = ValidationStatus.valid;
    public isDirty = false;

    ngOnInit(): void {
        this.setInitData();
    }

    public ngAfterViewChecked(): void {
        this.updateDirtyState();
    }

    public addNew(): void {
        const unchangedTestBenchInterface: TestBenchInterfaceDTO
            = { id: "", source: "", name: "", speed: "", address: "", port: 0 };
        this.testBenchInEdit.interfaces.push(unchangedTestBenchInterface);
    }

    public removeBenchInterface(benchInterface: TestBenchInterfaceDTO): void {
        const index = this.testBenchInEdit.interfaces.indexOf(benchInterface);
        this.testBenchInEdit.interfaces.splice(index, 1);
    }

    public onSaveClicked(): void {
        if (this.validationStatus === ValidationStatus.valid) {
            this.removeEmptyInterfaces();
            this.changedConfigRow.emit(this.testBenchInEdit);
            this.closed.emit();
        }
    }

    public onUndoClicked(): void {
        this.setInitData();
    }

    public removeAnswer(event): void {
        if (event === true) {
            this.deleteTestBenchConfig.emit(this.testBenchInEdit);
        }
    }

    public closeAlert(): void {
        this.showAlert = false;
    }

    private setInitData(): void {
        this.selectedTestBenchJson = JSON.stringify(this.selectedTestBench);
        this.testBenchInEdit = JSON.parse(JSON.stringify(this.selectedTestBench));
    }

    private updateDirtyState(): void {
        const isDirty = JSON.stringify(this.testBenchInEdit) !== this.selectedTestBenchJson;
        const errorStatus = this.validateChangeData();
        if (isDirty !== this.isDirty || this.validationStatus !== errorStatus) {
            setTimeout(() => {
                this.isDirty = isDirty;
                this.validationStatus = errorStatus;
            }, 0);
        }
    }

    private removeEmptyInterfaces(): void {
        this.testBenchInEdit.interfaces.forEach(item => {
            if (!this.isInterfaceChanged(item)) {
                const index = this.testBenchInEdit.interfaces.findIndex(n => n === item);
                this.testBenchInEdit.interfaces.splice(index);
            }
        });
    }

    private validateInterface(item: TestBenchInterfaceDTO): ValidationStatus{
        const validationStatus =
            !item.source ? ValidationStatus.missingSource
            : !item.name ? ValidationStatus.missingName
            : !item.address ? ValidationStatus.missingAddress
            : !item.speed && item.source === this.sources.can ? ValidationStatus.missingCanSpeed
            : item.port <= 0 && item.source === this.sources.plc ? ValidationStatus.invalidPortNumber
            : !item.port && item.source === this.sources.plc ? ValidationStatus.missingPort
            : ValidationStatus.valid;
        return validationStatus;
    }

    private isInterfaceChanged(interfaceItem: TestBenchInterfaceDTO): boolean {
        if (JSON.stringify(interfaceItem) === JSON.stringify(this.newTestBenchInterface)) {
            return false;
        }
        return true;
    }

    private validateChangeData(): ValidationStatus {
        for (const interfaceItem of this.testBenchInEdit.interfaces) {
            const status = this.validateInterface(interfaceItem);
            if (status !== ValidationStatus.valid) {
                return status;
            }
        }
        return ValidationStatus.valid;
    }
}
