import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { HiTestConstants } from "src/app/hitest/models/hitest.model";
import { TestBenchConnections, HiTestBenchDTO } from "src/app/hitest/models/hitestinputs.model";

@Component({
    selector: "hic-test-bench-row",
    templateUrl: "./test-bench-row.component.html",
    styleUrls: ["./../../../admin/hitest-admin.scss", "./test-bench-row.component.scss"]
})
export class TestBenchRowComponent implements OnInit {

    @Input() public columns: TestBenchConnections;
    @Input() public testBench: HiTestBenchDTO;
    @Output() public delete = new EventEmitter<HiTestBenchDTO>();
    @Output() public opens = new EventEmitter();

    public readonly idOfDefaultBench = HiTestConstants.idOfDefaultValue.UnknownTestbench;
    public canNames: string[] = [];
    public plcNames: string[] = [];

    public sources = HiTestConstants.sensorInterfaces;

    ngOnInit(): void {
        this.displayCanNames();
        this.displayPlcNames();
    }

    public onDelete(): void {
        this.delete.emit(this.testBench);
    }

    public onOpen(): void {
        this.opens.emit();

    }

    private displayCanNames(): void {
        const canNames = this.createDisplayNames(this.sources.can, this.columns.can.length);
        this.canNames = canNames;
    }

    private displayPlcNames(): void {
        const plcNames = this.createDisplayNames(this.sources.plc, this.columns.plc.length);
        this.plcNames = plcNames;
    }

    private createDisplayNames(source: string, columnCount: number): string[] {
        const sourceNames = this.testBench.interfaces.filter(x => x.source === source).map(x => x.name);
        const existingLength = sourceNames.length;
        sourceNames.length = columnCount;
        sourceNames.fill(" - ", existingLength, columnCount);
        return sourceNames;
    }
}
