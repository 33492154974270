import { DynamicInputValidationStatus } from "./dynamic-input-validation-status.enum";
import { DynamicExpressionModifier } from "./dynamic-expression-modifier.model";
import { IAdvancedExpression } from "src/app/hitest/models/advanced-expression.model";
import { HiTestInputDTO } from "src/app/hitest/models/hitestinputs.model";

export class DynamicExpression extends IAdvancedExpression {
    public sourceInput: HiTestInputDTO;
    public modifiers: DynamicExpressionModifier[] = [];

    public static fromAdvancedExpression(expression: IAdvancedExpression, availableInputs: HiTestInputDTO[]): DynamicExpression {
        const dynExpression = Object.assign(new DynamicExpression(), expression);
        dynExpression.modifiers = DynamicExpressionModifier.fromExpression(dynExpression.expression, availableInputs);
        const sourceInputName = DynamicExpression.findSourceInputName(expression.parameters);
        dynExpression.sourceInput = availableInputs.find(x => x.inputName === sourceInputName);
        return dynExpression;
    }

    private static findSourceInputName(parameters: string[]): string {
        for (const parameter of parameters) {
            const regex = /\{.*\}/;
            const result = parameter.match(regex);
            if (result?.length ?? 0 > 0) {
                return result[0].replace(/\{|\}/g, "");
            }
        }
    }

    public toAdvancedExpression(): IAdvancedExpression {
        const expression = new IAdvancedExpression();
        expression.criteria = this.criteria;
        expression.expression = this.expression;
        expression.parameters = this.parameters;
        return expression;
    }

    public buildParameters(): string[] {
        return this.sourceInput ? [`ToNumber(\{${this.sourceInput.inputName}\})`] : [];
    }

    public validate(): DynamicInputValidationStatus {
        if (this.sourceInput === undefined) {
            return DynamicInputValidationStatus.expressionSourceInputMissing;
        }

        if (!this.validateCriteria(this.criteria)) {
            return DynamicInputValidationStatus.expressionInvalidCriteria;
        }

        for (const modifier of this.modifiers) {
            const modifierStatus: DynamicInputValidationStatus = modifier.validate();
            if (modifierStatus !== DynamicInputValidationStatus.valid) {
                return modifierStatus;
            }
        }
        return DynamicInputValidationStatus.valid;
    }

    private validateCriteria(criteria: string): boolean {
        return criteria !== undefined;
    }
}
