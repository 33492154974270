import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RowCluster } from "src/app/hitest/services/helpers/row-cluster.model";
import { ModuleColoringService } from "src/app/hitest/services/module-coloring.service";
import { SortingService } from "src/app/hitest/services/sorting.service";

class RowClusterViewModel extends RowCluster {
    public moduleColor: string;
}

@Component({
    selector: "hic-row-order-overview",
    templateUrl: "./hitest-row-order-overview.component.html",
    styleUrls: ["./hitest-row-order-overview.component.scss"]
})
export class HitestRowOrderOverviewComponent implements OnInit, OnDestroy {
    private componentDestroyed$ = new Subject<boolean>();

    public clustersOfUnbreakableRows: RowCluster[] = [];
    public clustersPresentation: RowClusterViewModel[] = [];
    public totalNumberOfRows = 1;

    constructor(
        private sortingService: SortingService,
        private moduleColoringService: ModuleColoringService
    ) { }

    ngOnInit(): void {
        this.setupClustersOfUnbreakableRowsSubscription();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    private setupClustersOfUnbreakableRowsSubscription(): void {
        this.sortingService.clustersOfUnbreakableRows$.pipe(takeUntil(this.componentDestroyed$)).subscribe((response) => {
            this.clustersOfUnbreakableRows = response ?? [];
            this.clustersPresentation = this.createPresentationObjects(this.clustersOfUnbreakableRows);
            this.totalNumberOfRows = response?.reduce<number>((prev, curr) => ++prev, 0) ?? 0;
        });
    }

    private createPresentationObjects(clustersOfUnbreakableRows: RowCluster[]): RowClusterViewModel[] {
        const clustersPresentationList = clustersOfUnbreakableRows
            .map(cluster => {
                const viewModel: RowClusterViewModel = Object.assign(new RowClusterViewModel(), cluster);
                viewModel.moduleColor = this.moduleColoringService.getModuleColor(cluster.moduleId);
                return viewModel;
            }
        );
        return clustersPresentationList;
    }
}
