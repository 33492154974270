import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HiTestModuleModel } from "../models/hitest-newmodule.model";
import { IHiTestAllowedPluginEntity, IHiTestModuleModel, IHiTestModuleRequest, IHiTestModuleRowModel, IHiTestModuleRowOverallSortorderModel, IHiTestRowtypeEntity } from "../models/hitest.model";
import { IHiTestLocation } from "../models/hitestinputs.model";
import { HiTestModuleValidationHelper } from "./helpers/module-validation-helper.model";
import { HiTestModuleValidity } from "./helpers/module-validaty.model";
import { HiTestModuleService } from "./hitest-modules.service";
import { IHiTestModuleService } from "./hitest-modules.service.interface";

@Injectable({
    providedIn: "any"

})
export class HiTestModuleEditingService implements IHiTestModuleService {
    private workingCopy: IHiTestModuleModel = undefined;
    private workingCopySubject = new BehaviorSubject<IHiTestModuleModel>(this.workingCopy);
    private hasPendingChangesSubject = new BehaviorSubject<boolean>(false);
    public workingCopy$ = this.workingCopySubject.asObservable();
    public hasPendingChanges$ = this.hasPendingChangesSubject.asObservable();

    public originalModuleJson: string;
    private validator: HiTestModuleValidationHelper;

    public modulesUpdated$: Observable<boolean> = this.moduleService.modulesUpdated$;

    public get showRobotModeSettings(): boolean {
        return this.moduleService.showRobotModeOnline;
    }
    public get isInitialized(): any {
        return this.moduleService.isInitialized;
    }
    public get sortorderData(): IHiTestModuleRowOverallSortorderModel[] {
        return this.moduleService.sortorderData;
    }
    public get rowTypes(): IHiTestRowtypeEntity[] {
        return this.moduleService.rowTypes;
    }
    public get allowedPluginTypesSubject(): IHiTestAllowedPluginEntity[] {
        return this.moduleService.allowedPluginTypes;
    }
    public get allModules(): IHiTestModuleModel[] {
        return this.moduleService.allModules;
    }
    public get enabledModules(): IHiTestModuleModel[] {
        return this.moduleService.enabledModules;
    }
    public get disabledModules(): IHiTestModuleModel[] {
        return this.moduleService.disabledModules;
    }
    public get enabledTestRows(): IHiTestModuleRowModel[] {
        return this.moduleService.enabledTestRows;
    }

    constructor(private moduleService: HiTestModuleService) {
        this.validator = new HiTestModuleValidationHelper();
    }

    public get getOriginalDataHash(): number {
        return this.moduleService.getOriginalDataHash;
    }

    public getLastModifiedSortorderInLocation(locationId: number): string {
        return this.moduleService.getLastModifiedSortorderInLocation(locationId);
    }

    public async updateModuleDataAsync(module?: IHiTestModuleModel): Promise<IHiTestModuleRequest> {
        const response = await this.moduleService.updateModuleDataAsync();
        if (module) {
            const ourModule = response.HiTestModuleEntitys.find(x => x.Id === module.Id);
            this.setActiveModule(ourModule);
        }
        return response;
    }

    public getSortedRowsByLocation(location: IHiTestLocation): IHiTestModuleRowModel[] {
        return this.moduleService.getSortedRowsByLocation(location);
    }
    public updateSortOrderForLocation(location: IHiTestLocation, module: IHiTestModuleModel, sortedRows: IHiTestModuleRowModel[]): void {
        this.moduleService.updateSortOrderForLocation(location, module, sortedRows);
    }
    public getIdOfLocationsWithAssignedModules(): number[] {
        return this.moduleService.getIdOfLocationsWithAssignedModules();
    }

    public setActiveModule(module: IHiTestModuleModel): void {
        this.originalModuleJson = JSON.stringify(module);
        this.workingCopy = this.getModuleCopy(module);
        this.workingCopySubject.next(this.workingCopy);
    }
    public getModuleCopy(module: IHiTestModuleModel): IHiTestModuleModel {
        const stringified = JSON.stringify(module);
        const clone: IHiTestModuleModel = JSON.parse(stringified);
        return clone;
    }

    public updateUndoData(): void {
        this.originalModuleJson = JSON.stringify(this.workingCopy);
    }

    public undo(): void {
        if (this.workingCopy.Id > 0) {
            const originalModule = JSON.parse(this.originalModuleJson);
            this.workingCopy = originalModule;
            this.workingCopySubject.next(this.workingCopy);
        }
    }


    public hasPendingChanges(): boolean {
        const result = this.checkPendingChanges();
        this.hasPendingChangesSubject.next(result);
        return result;
    }

    private checkPendingChanges(): boolean {
        if (!this.workingCopy) {
            return false;
        }
        const modifiedJson = JSON.stringify(this.workingCopy);
        return this.originalModuleJson !== modifiedJson;
    }

    public validateModule(module: HiTestModuleModel): HiTestModuleValidity {
        return this.validator.validateModule(module);
    }
}
