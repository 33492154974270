import { Component, ChangeDetectorRef, Input } from "@angular/core";
import { BaseComponent } from "src/app/base-component";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { CraneBoomData } from "src/app/shared/graphics/side-view/components/crane-side-view.additionals.model";

@Component({
    selector: "hic-hitest-asc-table",
    templateUrl: "./asc-table.component.html",
    styleUrls: ["./asc-table.scss"]
})
export class HiTestASCTableComponent extends BaseComponent {
    @Input() boomData: CraneBoomData;

    constructor(conditionsService: ConditionsService,
                changeDetector: ChangeDetectorRef) {

        super(conditionsService, changeDetector);
    }
}
