import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { DraggableDirective } from "./draggable.directive";
import { GlobalPositionStrategy, Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
    selector: "[appDraggableHelper]",
    exportAs: "appDraggableHelper"
})
export class DraggableHelperDirective implements OnInit, OnDestroy {

    private directiveDestroyed$ = new Subject<boolean>();
    private overlayRef: OverlayRef;
    private positionStrategy = new GlobalPositionStrategy();
    private startPosition?: { x: number; y: number };

    public constructor(private draggable: DraggableDirective,
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private overlay: Overlay) { }

    public ngOnInit(): void {
        this.draggable.dragStart.pipe(takeUntil(this.directiveDestroyed$)).subscribe(event => this.onDragStart(event));
        this.draggable.dragMove.pipe(takeUntil(this.directiveDestroyed$)).subscribe(event => this.onDragMove(event));
        this.draggable.dragEnd.pipe(takeUntil(this.directiveDestroyed$)).subscribe(() => this.onDragEnd());

        // create an overlay...
        this.overlayRef = this.overlay.create({
            positionStrategy: this.positionStrategy
        });
    }

    public ngOnDestroy(): void {
        // remove the overlay...
        this.overlayRef.dispose();
        this.directiveDestroyed$.next(true);
        this.directiveDestroyed$.complete();
    }

    private onDragStart(event: PointerEvent): void {
        // determine relative start position
        const clientRect = this.draggable.element.nativeElement.getBoundingClientRect();

        this.startPosition = {
            x: event.clientX - clientRect.left,
            y: event.clientY - clientRect.top
        };

        // added after YouTube video: width
        this.overlayRef.overlayElement.style.width = `${clientRect.width}px`;
        setTimeout(() => this.handleOverlay(event), 0);
    }

    private onDragMove(event: PointerEvent): void {
        this.handleOverlay(event);
    }

    private handleOverlay(event: PointerEvent): void  {
        if (!this.overlayRef.hasAttached()) {
            // render the helper in the overlay
            this.overlayRef.attach(new TemplatePortal(this.templateRef, this.viewContainerRef));
            // added after YouTube video: width
            const rootElement = this.overlayRef.overlayElement.firstChild as HTMLElement;
            rootElement.style.width = "100%";
            rootElement.style.boxSizing = "border-box";
        }
        // position the helper...
        this.positionStrategy.left(`${event.clientX - this.startPosition.x}px`);
        this.positionStrategy.top(`${event.clientY - this.startPosition.y}px`);
        this.positionStrategy.apply();
    }

    private onDragEnd(): void {
        // remove the helper from the overlay
        this.overlayRef.detach();
    }
}
