import { HiTestInputDTO } from "../../../models/hitestinputs.model";
import { DynamicInputValueData } from "./dynamic-input-value-data.model";

export class DynamicInput extends HiTestInputDTO {
    public valueData = new DynamicInputValueData();
    public static fromInputDTO(source: HiTestInputDTO, availableInputs: HiTestInputDTO[]): DynamicInput {
        const dynamic: DynamicInput = Object.assign(new DynamicInput(), source);
        dynamic.valueData = DynamicInputValueData.fromJson(dynamic.value, availableInputs);
        return dynamic;
    }

    public generateValue(): void {
        this.value = this.valueData.toJson();
    }

    public toInputDto(): HiTestInputDTO {
        this.generateValue();
        const inputDto = new HiTestInputDTO();
        HiTestInputDTO.copyProperties(inputDto, this);
        return inputDto;
    }
}
