<div *ngIf="emulatorService.emulatedInputs?.length > 0" class="hic-emulator-container">
    <div class="emulator-header">
        <p *ngIf="!collapsed">
            Input-emulator
        </p>
        <button type="button" (click)="toggleCollapsed()" *ngIf="!collapsed" class="hic-collapsebutton">
            <mat-icon>expand_less</mat-icon>
        </button>
        <button type="button" (click)="toggleCollapsed()" *ngIf="collapsed" class="hic-collapsebutton">
            <mat-icon>expand_more</mat-icon>
        </button>
    </div>
    <div *ngIf="!collapsed">
        
        <div *ngFor="let emulatedInput of GetOrderedListOfEmulated()">
            {{emulatedInput.name}}<br />
            <hic-compact-input2 (ngModelChange)="emulatedValueChanged(emulatedInput, $event)" [min]="0" [max]="1000" [(ngModel)]="emulatedInput.value"></hic-compact-input2><br />
            <input (ngModelChange)="emulatedValueChanged(emulatedInput, $event)" type="range" [min]="0" [max]="1000" class="slider" [(ngModel)]="emulatedInput.value">
        </div>
    </div>
</div>
