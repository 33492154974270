<div class="tolerance-meter-wrapper">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="600" height="55"
        fill="none">

        <rect width="100%" height="100%" fill="#636363" rx="3" />

        <text text-anchor="start" font-size="24" y="40" x="5" class="hic-text">{{displayModel.inputName}}</text>
        <text text-anchor="end" font-size="24" y="40" [attr.x]="ledLeftMargin - ledSpacing * 2"
            class="hic-text">{{displayModel.valueNice}}</text>

        <text text-anchor="start" font-size="24" y="40" [attr.x]="ledLeftMargin + ledSpacing * (numberOfLeds + 2)"
            class="hic-text">{{displayModel.unit}}</text>

        <text text-anchor="end" font-size="24" y="17" [attr.x]="targetPosition - 40"
            class="hic-text mini">{{displayModel.valueMinOkNice}}</text>

        <text text-anchor="middle" font-size="24" y="17" [attr.x]="targetPosition"
            class="hic-text">{{displayModel.valueTargetNice}}</text>

        <text text-anchor="start" font-size="24" y="17" [attr.x]="targetPosition + 40"
            class="hic-text mini">{{displayModel.valueMaxOkNice}}</text>

        <ellipse *ngIf="displayModel.value < minValue" ry="5" rx="5" id="underflow" [attr.cy]="ledTopMargin + 10" [attr.cx]="ledLeftMargin - ledSpacing" fill="#ffd000"/>

        <ellipse *ngIf="displayModel.value > minValue + scaleSpan" ry="5" rx="5" id="owerflow" [attr.cy]="ledTopMargin + 10" [attr.cx]="ledLeftMargin + ledSpacing * (numberOfLeds + 1) - 3" fill="#ff0000"/>

        <rect *ngFor="let led of leds" [attr.x]="getLedX(led)" [attr.y]="getLedY(led)" rx="2"
            [ngClass]="getLedClasses(led)" />

    </svg>
</div>
