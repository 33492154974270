import { Injectable } from "@angular/core";
import { BehaviorSubject, firstValueFrom } from "rxjs";
import { HiTestMessageService } from "src/app/hitest/services/message/hitest-message.service";
import { HiTestConstants, IHiTestAllowedPluginEntity, IHiTestModuleModel, IHiTestModuleRequest, IHiTestModuleRowModel, IHiTestModuleRowOverallSortorderModel, IHiTestRowtypeEntity, ILastModifiedTimestampInLocation } from "../models/hitest.model";
import { IHiTestLocation } from "../models/hitestinputs.model";
import { IHiTestModuleService } from "./hitest-modules.service.interface";
import { HiTestHttpService } from "./hitest.http.service";

@Injectable({
    providedIn: "root"
})
export class HiTestModuleService implements IHiTestModuleService {
    private readonly notInitializedMessage = "The collection has not been initialized, run updateModuleDataAsync() first";
    private _isInitialized = false;
    private modulesUpdatedSubject = new BehaviorSubject<boolean>(false);
    public modulesUpdated$ = this.modulesUpdatedSubject.asObservable();
    private dataPopulatedSubject = new BehaviorSubject<boolean>(false);
    public dataPopulated$ = this.dataPopulatedSubject.asObservable();
    public showRobotModeOnline = false;
    public showRobotModeOffline = false;
    public interactiveAllowed = false;
    public guiRowLimit: number;
    public visibleRowsRadius: number;

    /**
     * Gets a boolean indicating if the module collection has been initialized.
     * If false, consider calling updateModuleDataAsync() before using any other method or properties
     * in this class.
     */
    public get isInitialized(): boolean {
        return this._isInitialized;
    }

    private _sortorderExpandedData: IHiTestModuleRowOverallSortorderModel[] = undefined;
    private _lastModifiedTimestampInSortorderLocations:  ILastModifiedTimestampInLocation[] = undefined;

    private _sortorderData: IHiTestModuleRowOverallSortorderModel[] = undefined; // Here is correct data to use
    public get sortorderData(): IHiTestModuleRowOverallSortorderModel[] {
        return [...this._sortorderData];
    }

    private _allowedPluginTypes: IHiTestAllowedPluginEntity[] = [];
    /**
     * Gets a list of all allowed plugin types
     */
     public get allowedPluginTypes(): IHiTestAllowedPluginEntity[] {
        if (!this._isInitialized) {
            throw new Error(this.notInitializedMessage);
        }

        return [...this._allowedPluginTypes];
    }

    private _originalDataHash: number;

    public isReferredGroup(name: string): boolean {
        const searchFor = "[" + name + "]";

        const result = this._allModules.some(m => {
            if (m.Ps2000Rules?.includes(searchFor)) {
                return true;
            }

            const foundInRows = m.HiTestModuleRows?.some(r => {
                if (r.Ps2000Rules?.includes(searchFor)) {
                    return true;
                }
            });

            return foundInRows;
        });

        return result;
    }

    public get getOriginalDataHash(): number {
        return this._originalDataHash;
    }

    private _rowTypes: IHiTestRowtypeEntity[] = [];
    /**
     * Gets a list of all existing row types
     */
    public get rowTypes(): IHiTestRowtypeEntity[] {
        if (!this._isInitialized) {
            throw new Error(this.notInitializedMessage);
        }

        return [...this._rowTypes];
    }

    private _allModules: IHiTestModuleModel[] = [];
    /**
     * Gets a list of all existing modules.
     */
    public get allModules(): IHiTestModuleModel[] {
        if (!this._isInitialized) {
            throw new Error(this.notInitializedMessage);
        }

        return [...this._allModules];
    }

    /**
     * Gets a list of all enabled modules.
     */
     public get enabledModules(): IHiTestModuleModel[] {
        if (!this._isInitialized) {
            throw new Error(this.notInitializedMessage);
        }

        const enabledModules = this._allModules.filter(x => x.Enabled === HiTestConstants.db.true);
        return enabledModules;
    }

    /**
     * Gets a list of all disabled modules.
     */
     public get disabledModules(): IHiTestModuleModel[] {
        if (!this._isInitialized) {
            throw new Error(this.notInitializedMessage);
        }

        const disabledModules = this._allModules.filter(x => x.Enabled !== HiTestConstants.db.true);
        return disabledModules;
    }

    /**
     * Gets a flattened list of all testrows in all enabled modules.
     */
    public get enabledTestRows(): IHiTestModuleRowModel[] {
        if (!this._isInitialized) {
            throw new Error(this.notInitializedMessage);
        }

        const enabledTestRows = this.enabledModules.flatMap(x => x.HiTestModuleRows);
        return enabledTestRows;
    }

    constructor(protected hitestHttpService: HiTestHttpService, protected messageService: HiTestMessageService) {}

    public async populateDataAsync(): Promise<void> {
        const populated = await firstValueFrom(this.hitestHttpService.populateData());
        await this.updateModuleDataAsync();
        this.dataPopulatedSubject.next(populated);
    }

    /**
     * Asynchronously updates modules, row types and sort order data.
     * Call this method before trying to get any other data from this class.
     */
    public async updateModuleDataAsync(_?: IHiTestModuleModel): Promise<IHiTestModuleRequest> {
        const response: IHiTestModuleRequest = await firstValueFrom(this.hitestHttpService.getModules());
        const expandedModules = this.expandUndefinedInModules(response.HiTestModuleEntitys);
        this._allModules = this.sortAllModuleRows(expandedModules);
        this._rowTypes = response.HiTestRowtypeEntities;
        this._allowedPluginTypes = response.HiTestAllowedPluginEntities;
        this._sortorderData = response.HiTestModuleRowOverallSortorderEntity;
        this._sortorderExpandedData = response.HiTestModuleRowOverallSortorderExpandedEntity;
        this._lastModifiedTimestampInSortorderLocations = response.LastModifiedTimestampInSortorderLocations;
        this._originalDataHash = response.OriginalDataHash;
        this.showRobotModeOnline = response.ShowRobotModeOnline;
        this.showRobotModeOffline = response.ShowRobotModeOffline;
        this.interactiveAllowed = response.InteractiveAllowed;
        this.guiRowLimit = response.GuiRowLimit;
        this.visibleRowsRadius = response.VisibleRowsRadius;
        this._isInitialized = true;
        this.modulesUpdatedSubject.next(true);
        return response;
    }

    // Expands empty array properties known to be removed from DTO by backend in
    // Hiab.HiCommand.HiTest.HiTestCore.Controllers.HiTestCommonController.RemoveIrrelevantData()
    // (and therefore will be represented as unknown in frontend) to empty arrays which is a better representation
    public expandUndefinedInModules(modules: IHiTestModuleModel[]): IHiTestModuleModel[] {
        const expanded = [...modules];
        for (const m of expanded) {
            if (!m.HiTestModuleRestrictionModules) {
                m.HiTestModuleRestrictionModules = [];
            }
            if (!m.HiTestModuleRestrictionReferencingModules) {
                m.HiTestModuleRestrictionReferencingModules = [];
            }
            if (!m.HiTestConditionModuleCondition) {
                m.HiTestConditionModuleCondition = [];
            }
            if (!m.HiTestConditionModuleApproval) {
                m.HiTestConditionModuleApproval = [];
            }
            for (const r of m.HiTestModuleRows) {
                if (!r.HiTestConditionRowApproval) {
                    r.HiTestConditionRowApproval = [];
                }
                if (!r.HiTestConditionRowCondition) {
                    r.HiTestConditionRowCondition = [];
                }
                if (!r.HiTestConditionRowIdAdditionalMeasurements) {
                    r.HiTestConditionRowIdAdditionalMeasurements = [];
                }
                if (!r.HiTestConditionRowIdCommands) {
                    r.HiTestConditionRowIdCommands = [];
                }
            }
        }
        return expanded;
    }

    /**
     * Get a list of custom sorted test rows for the provided location.
     *
     * @param location The location for which to get the sorted test rows.
     * @returns An array of IHiTestModuleRowModel.
     */
    public getSortedRowsByLocation(location: IHiTestLocation): IHiTestModuleRowModel[] {
        return this.getSortedRowsByLocationFromSource(this._sortorderData, location);
    }

    public getLastModifiedSortorderInLocation(locationId: number): string {
        const timestampEntry = this._lastModifiedTimestampInSortorderLocations.find(x => x.LocationId === locationId);
        return timestampEntry?.LastModifiedTimestamp ?? "";
    }


    public getSortedExpandedRowsByLocation(location: IHiTestLocation): IHiTestModuleRowModel[] {
        return this.getSortedRowsByLocationFromSource(this._sortorderExpandedData, location);
    }

    private getSortedRowsByLocationFromSource(
        sourceData: IHiTestModuleRowOverallSortorderModel[],
        location: IHiTestLocation
    ): IHiTestModuleRowModel[] {
        if (!this._isInitialized) {
            this.messageService.sendAlert("There was an attempt to get sorted rows by location when service was not initialized");
            throw new Error(this.notInitializedMessage);
        }

        const sortorderAtLocation = sourceData.filter(x => x.LocationId === location.id);
        sortorderAtLocation.sort((a, b) => a.SortorderInOverall - b.SortorderInOverall);

        const enabledTestRowsMap = new Map(this.enabledTestRows.map(row => [row.Id, row]));
        let testRowsAtLocation = sortorderAtLocation.map(x => enabledTestRowsMap.get(x.TestModuleRowId));

        if (testRowsAtLocation.some(row => !row)) {
            // eslint-disable-next-line max-len
            this.messageService.sendAlert(`Original sort order for ${location.locationName} contains rows that could not be found in any module. These rows will be cleared from the sorting list`);
            testRowsAtLocation = testRowsAtLocation.filter(row => !!row);
        }
        return testRowsAtLocation;
    }

    public updateSortOrderForLocation(location: IHiTestLocation, module: IHiTestModuleModel, sortedRows: IHiTestModuleRowModel[]): void {
        if (!sortedRows) {
            const message = `sortedRows argument was null when trying to update sort order for location ${location.locationName}.`;
            this.messageService.sendAlert(message);
            throw new Error(message);
        }
        // Update or add all modules array with the provided module.
        const originalModuleIndex = this._allModules.findIndex(x => x.Id === module.Id);
        if (originalModuleIndex >= 0) {
            if (this._allModules[originalModuleIndex] !== module) {
                this._allModules[originalModuleIndex] = module;
            }

            // Remove any items in sortOrderData that points to non existing rows
            const allAvailableRowIds = this.enabledTestRows.map(x => x.Id);
            this._sortorderData = this._sortorderData.filter(x => allAvailableRowIds.includes(x.TestModuleRowId));
        } else {
            this._allModules.push(module);
        }

        this._sortorderData = this._sortorderData.filter(x => x.LocationId !== location.id);
        let counter = 0;
        const updatedSortOrder = sortedRows.map<IHiTestModuleRowOverallSortorderModel>(row => ({
            Id: 0,
            TestModuleRowId: row.Id,
            LocationId: location.id,
            SortorderInOverall: counter++,
            LastUpdatedTimestamp: "",
            LastUpdatedUserName: "unknown.updated"
        }));
        this._sortorderData.push(...updatedSortOrder);
    }

    public getIdOfLocationsWithAssignedModules(): number[] {
        const identifiers = [...new Set(this._sortorderData.map(x => x.LocationId))];
        return identifiers;
    }

    /**
     * Goes through all modules and sorts their rows in ascending order according to InternalStepNr.
     *
     * @param modules The modules to be processed.
     */
     private sortAllModuleRows(modules: IHiTestModuleModel[]): IHiTestModuleModel[] {
        for (const module of modules) {
            module.HiTestModuleRows.sort((a, b) => (a.InternalStepNr < b.InternalStepNr ? -1 : 0));
        }
        return modules;
    }

}
