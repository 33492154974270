import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { first, timeout } from "rxjs/operators";
import { HiTestMessageService } from "src/app/hitest/services/message/hitest-message.service";
import { IHiTestModuleRowModel } from "src/app/hitest/models/hitest.model";
import { IHiTestLocation } from "src/app/hitest/models/hitestinputs.model";
import { HiTestLocationsService } from "src/app/hitest/services/hitest-locations.service";
import { HiTestService } from "src/app/hitest/services/hitest.service";
import { SortingService } from "src/app/hitest/services/sorting.service";

@Component({
    selector: "hic-test-sequence",
    templateUrl: "./test-sequence.component.html",
    styleUrls: ["../../hitest-admin.scss", "./test-sequence.component.scss"]
})

export class HiTestTestSequenceComponent implements OnInit, OnDestroy {


    public menuItems: string[] = [
        "MODULE CONFIG.",
        "TEST SEQUENCE",
        "INPUTS"
    ];

    public menuTargets: string[] = [
        "hitest/module-config",
        "hitest/test-sequence",
        "hitest/inputs"];

    public itemSelected = 1;
    public testRows: IHiTestModuleRowModel[];
    private componentDestroyed$ = new Subject<boolean>();
    public darkBoxMessage: string;
    public showDarkbox: boolean;
    public currentUserLocation: IHiTestLocation;
    public locations: IHiTestLocation[] = [];
    public selectedLocation: IHiTestLocation = undefined; // {id: HiTestConstants.location.unknownLocation, locationName: "Unknown" };
    public hasChanges$: Observable<boolean>;

    constructor(
        public hitestService: HiTestService,
        public sortingService: SortingService,
        private hitestLocationService: HiTestLocationsService,
        private messageService: HiTestMessageService,
    ) {
        this.hasChanges$ = this.sortingService.hasChanges$;
    }

    async ngOnInit(): Promise<void> {
        try {
            const loadingTimeout = 10000; // milliseconds
            this.showDarkbox = true;
            this.darkBoxMessage = "Loading...";

            this.locations = await this.hitestLocationService.getLocationsAsync();
            this.currentUserLocation = await this.hitestLocationService.getUserLocation();

            const userLocation = this.locations.find(x => x.id === this.currentUserLocation.id);
            await this.sortingService.changeLocationAsync(userLocation);
            this.selectedLocation = userLocation;

            await this.sortingService.modulesReady$.pipe(first(ready => !!ready), timeout(loadingTimeout)).toPromise();
        } catch (err) {
            this.messageService.sendAlert("Module loading timed out, try refreshing the page or contact support");
        } finally {
            this.showDarkbox = false;
        }
    }

    async ngOnDestroy(): Promise<void> {
        const hasChanges = await this.hasChanges$.pipe(first()).toPromise();
        if (hasChanges) {
            this.sortingService.onUndo();
            this.messageService.sendAlert(
                `Your unsaved changes to test sequence for ${this.sortingService.currentLocation.locationName} has been lost.`
            );
        }
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    public locationById(index: number, item: IHiTestLocation): number {
        return item.id;
    }

    public async changeLocation(): Promise<void> {
        this.showDarkbox = true;
        setTimeout(async () => {
            await this.sortingService.changeLocationAsync(this.selectedLocation);
            this.showDarkbox = false;
        }, 0);
    }
}
