import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { filter, first } from "rxjs/operators";
import { SortEvent } from "../admin/components/draggable/sortable-list.directive";
import { HiTestConstants, IHiTestAllowedPluginEntity, IHiTestModuleModel, IHiTestModuleRowModel, IHiTestModuleRowOverallSortorderModel, IHiTestRowtypeEntity } from "../models/hitest.model";
import { IHiTestLocation } from "../models/hitestinputs.model";
import { Restrictions } from "./helpers/restrictions.model";
import { RowCluster } from "./helpers/row-cluster.model";
import { SortingTool } from "./helpers/sorting-tool.model";
import { HiTestLocationsService } from "./hitest-locations.service";
import { HiTestModuleEditingService } from "./hitest-modules-editing.service";

@Injectable({
    providedIn: "root"
})

export class SortingService {
    private assignedModulesSubject = new BehaviorSubject<IHiTestModuleModel[]>([]);
    private unassignedModulesSubject = new BehaviorSubject<IHiTestModuleModel[]>([]);
    public assignedModules$ = this.assignedModulesSubject.asObservable();
    public unassignedModules$ = this.unassignedModulesSubject.asObservable();

    private moduleInEdit: IHiTestModuleModel = undefined;

    private modulesSubject: BehaviorSubject<IHiTestModuleModel[]> = new BehaviorSubject<IHiTestModuleModel[]>([]);
    public modules$ = this.modulesSubject.asObservable();

    private testRowsSubject: BehaviorSubject<IHiTestModuleRowModel[]> = new BehaviorSubject<IHiTestModuleRowModel[]>([]);
    public testRows$ = this.testRowsSubject.asObservable();

    private rowTypesSubject = new BehaviorSubject<IHiTestRowtypeEntity[]>([]);
    public rowTypes$ = this.rowTypesSubject.asObservable();

    private allowedPluginTypesSubject = new BehaviorSubject<IHiTestAllowedPluginEntity[]>([]);
    public allowedPluginTypes$ = this.allowedPluginTypesSubject.asObservable();

    private modulesReadySubject = new BehaviorSubject<boolean>(false);
    public modulesReady$ = this.modulesReadySubject.asObservable();

    private clustersOfUnbreakableRowsSubject = new BehaviorSubject<RowCluster[]>(null);
    public clustersOfUnbreakableRows$ = this.clustersOfUnbreakableRowsSubject.asObservable();

    private hasChangesSubject = new BehaviorSubject<boolean>(false);
    public hasChanges$ = this.hasChangesSubject.asObservable();

    private originalTestRows = "";
    private originalModules = "";
    private orginalSortedRows: string;

    public allModules: IHiTestModuleModel[] = [];
    public enabledModules: IHiTestModuleModel[] = [];
    public disabledModules: IHiTestModuleModel[] = [];

    private allEnabledTestRows: IHiTestModuleRowModel[] = [];
    public testRowsAtLocation: IHiTestModuleRowModel[] = [];
    public sortingsAtAllLocations: IHiTestModuleRowOverallSortorderModel[] = [];

    public rowClusters: RowCluster[] = [];

    public currentModule: IHiTestModuleModel;

    private locations: IHiTestLocation[] = [];
    private isLocationsLoaded: Promise<boolean>;
    public currentLocation: IHiTestLocation = {
        id: HiTestConstants.location.unknownLocation,
        locationName: "Unknown location",
    };

    constructor(
        private moduleEditingService: HiTestModuleEditingService,
        private locationsService: HiTestLocationsService
    ) {
        // eslint-disable-next-line no-async-promise-executor
        this.isLocationsLoaded = new Promise(async (resolve, _reject) => {
            this.locations = await this.locationsService.getLocationsAsync();
            resolve(true);
        });

        // Subscribe to module and sort order changes
        this.moduleEditingService.modulesUpdated$.pipe(filter(x => !!x)).subscribe(() => {
            this.updateModuleAndSortingData();
        });
    }

    /**
     * TODO: Unit test getModuleAndSortingData() https://jira.shared.tds.cargotec.com/browse/HIAA-2307
     */
    private async updateModuleAndSortingData(): Promise<void> {
        this.allModules = this.moduleEditingService.allModules;
        this.enabledModules = this.moduleEditingService.enabledModules;
        this.disabledModules = this.moduleEditingService.disabledModules;

        // TODO: Implement getting bench data, HIAA-4044 https://jira.shared.tds.cargotec.com/browse/HIAA-4044
        // Hackish solution find out if we're running as operator or admin.
        // If only one location has assigned modules we assume that we are at operator page and activate that single location.
        const locationIdsWithSortings = this.moduleEditingService.getIdOfLocationsWithAssignedModules();
        if (locationIdsWithSortings.length === 1) {
            const activeLocation = this.locations.find(x => x.id === locationIdsWithSortings[0]);
            if (activeLocation) {
                this.testRowsAtLocation = this.moduleEditingService.getSortedRowsByLocation(activeLocation);
            }
        }

        this.allEnabledTestRows = this.moduleEditingService.enabledTestRows;

        this.sortingsAtAllLocations = this.moduleEditingService.sortorderData;
        this.updateAssignedModulesLists();
        this.updateRowClusters();
        this.storeLastKnownValues();

        this.allowedPluginTypesSubject.next(this.moduleEditingService.allowedPluginTypesSubject);
        this.rowTypesSubject.next(this.moduleEditingService.rowTypes);
        this.triggerSubjects();
        this.modulesReadySubject.next(true);
    }

    public async changeLocationAsync(location: IHiTestLocation, saveUndo = true): Promise<void> {
        await this.isLocationsLoaded;
        await this.modulesReady$.pipe(filter(ready => ready), first()).toPromise();
        this.testRowsAtLocation = this.moduleEditingService.getSortedRowsByLocation(location);
        this.sortingsAtAllLocations = this.moduleEditingService.sortorderData;
        this.updateAssignedModulesLists();
        this.updateRowClusters();
        if (saveUndo) {
            this.storeLastKnownValues();
        }
        this.currentLocation = location;
    }

    public getRowNumber(row: IHiTestModuleRowModel): number {
        const rowNumber = this.testRowsAtLocation.indexOf(row) + 1;
        return rowNumber;
    }

    private updateRowClusters(): void {
        this.rowClusters = RowCluster.extractClustersFromRows(this.testRowsAtLocation);
        this.clustersOfUnbreakableRowsSubject.next(this.rowClusters);
    }

    private storeLastKnownValues(): void {
        // Store module and rows backup data
        this.originalTestRows = JSON.stringify(this.allEnabledTestRows); // Used for undo functionality
        this.originalModules = JSON.stringify(this.allModules); // Used for undo functionality *** POSSIBLY NOT NEEDED ***

        // Store sorting data
        this.orginalSortedRows = JSON.stringify(this.testRowsAtLocation);
    }

    public selectCurrentModule(currentModule: IHiTestModuleModel): void {
        const moduleIndex = this.allModules.findIndex(module => module.Id === currentModule.Id);
        if (moduleIndex < 0 || moduleIndex > this.allModules.length) {
            throw new Error("getCurrentModule() cannot find a testModule on moduleIndex: " + moduleIndex);
        } else {
            this.currentModule = this.allModules[moduleIndex];
        }
    }

    public getModuleAssignmentStatus(module: IHiTestModuleModel): number {
        let counter = 0;
        for (const location of this.locations) {
            const localSortings = this.sortingsAtAllLocations.filter(x => x.LocationId === location.id);
            const hasSortingAtLocation = localSortings.some(x => module.HiTestModuleRows.some(y => y.Id === x.TestModuleRowId));
            counter = hasSortingAtLocation ? counter + 1 : counter;
        }
        const result = this.locations.length > 0 ? counter / this.locations.length : 0;
        return result;
    }

    public hasSortingAnywhere(module: IHiTestModuleModel): boolean {
        const hasSortingAnywhere = this.sortingsAtAllLocations
            .some(x => module.HiTestModuleRows.some(y => y.Id === x.TestModuleRowId));
        return hasSortingAnywhere;
    }


    public async prepareForSaveAsync(module: IHiTestModuleModel): Promise<void> {
        if (!module) {
            throw new Error("A module must be provided");
        }

        const originalLocation = this.currentLocation;
        const originalModule = this.allModules.find(oldMod => oldMod.Id === module.Id);
        const originalModuleIndex = this.allModules.indexOf(originalModule);

        if (JSON.stringify(originalModule) === JSON.stringify(module)) {
            return;
        }

        for (const location of this.locations) {
            // Change location (without saving undo information)
            await this.changeLocationAsync(location, false);

            const sortingTool = new SortingTool();

            // Special case when edited module is disabled or has changed enabled state.
            if (module.Enabled === HiTestConstants.db.false || module.Enabled !== originalModule.Enabled) {
                this.testRowsAtLocation = this.testRowsAtLocation.filter(row => row.ModuleId !== module.Id);
                if (module.Enabled === HiTestConstants.db.true) {
                    this.testRowsAtLocation = sortingTool.sortModuleRowsBasedOnRestrictions(
                        module, this.allModules, this.testRowsAtLocation);
                }
                this.allModules[originalModuleIndex] = module;
                this.moduleEditingService.updateSortOrderForLocation(location, module, this.testRowsAtLocation);
                continue;
            }

            // Sort rows in the module on internal step number, ascending.
            module.HiTestModuleRows.sort((row, nextRow) => row.InternalStepNr - nextRow.InternalStepNr);

            // Handle case when module's restrictions has been altered
            if (sortingTool.detectRestrictionChanges(module, originalModule)) {
                this.testRowsAtLocation = sortingTool.sortModuleRowsBasedOnRestrictions(
                    module, this.allModules, this.testRowsAtLocation);
                this.allModules[originalModuleIndex] = module;
                this.moduleEditingService.updateSortOrderForLocation(location, module, this.testRowsAtLocation);
                continue;
            }

            if (sortingTool.detectSortingChanges(module, originalModule)) {
                this.testRowsAtLocation = sortingTool.substituteWithSortingChanges(module, originalModule, this.testRowsAtLocation);
                this.allModules[originalModuleIndex] = module;
                this.moduleEditingService.updateSortOrderForLocation(location, module, this.testRowsAtLocation);
                continue;
            }

            // No order changes have been done. Just update module and rows.
            this.allModules[originalModuleIndex] = module;
            this.testRowsAtLocation = sortingTool.substituteDirectMatch(module, this.testRowsAtLocation);
            this.moduleEditingService.updateSortOrderForLocation(location, module, this.testRowsAtLocation);
        }
        // Change back to original location (with saving undo information)
        await this.changeLocationAsync(originalLocation, true);
        this.triggerSubjects();
    }

    private triggerSubjects(): void {
        this.modulesSubject.next(this.allModules);
        this.testRowsSubject.next(this.testRowsAtLocation);
        this.clustersOfUnbreakableRowsSubject.next(this.rowClusters);
    }

    /**
     * Adds a module's test rows to the global test row-list.
     *
     * @param module which test rows are to be included in the testrow.
     */
    public assignModule(module: IHiTestModuleModel): void {
        const sortingTool = new SortingTool();
        this.testRowsAtLocation = sortingTool.addModule(module, this.allModules, this.testRowsAtLocation);
        this.sortingsAtAllLocations = this.moduleEditingService.sortorderData;
        this.updateAssignedModulesLists();
        this.updateRowClusters();
        this.triggerSubjects();
        this.detectChanges();
    }

    public unassignModule(module: IHiTestModuleModel): void {
        this.testRowsAtLocation = this.testRowsAtLocation.filter(row => row.ModuleId !== module.Id);
        this.sortingsAtAllLocations = this.moduleEditingService.sortorderData;
        this.updateAssignedModulesLists();
        this.updateRowClusters();
        this.triggerSubjects();
        this.detectChanges();
    }

    private updateAssignedModulesLists(): void {
        const assigned = this.enabledModules.filter(module => this.testRowsAtLocation.some(row => row.ModuleId === module.Id));
        const unsassigned = this.enabledModules.filter(module => !this.testRowsAtLocation.some(row => row.ModuleId === module.Id));
        this.assignedModulesSubject.next(assigned);
        this.unassignedModulesSubject.next(unsassigned);
    }

    /**
     * Takes the testrows property and assigns a sortorder based on the testrows placements in the list.
     * Modules that either has been disabled or unassigned won't be included.
     */
    public generateRoworderlist(): IHiTestModuleRowOverallSortorderModel[] {
        const allSortOrderModels: IHiTestModuleRowOverallSortorderModel[] = [];

        for (const location of this.locations) {
            const localSortOrderModels: IHiTestModuleRowOverallSortorderModel[] = [];

            let i = 0;
            const localRows = location.id === this.currentLocation.id
                ? this.testRowsAtLocation
                : this.moduleEditingService.getSortedRowsByLocation(location);

            for (const row of localRows) {
                const sortorderModel: IHiTestModuleRowOverallSortorderModel = {
                    Id: 0,
                    TestModuleRowId: row.Id,
                    LocationId: location.id,
                    SortorderInOverall: i++,
                    LastUpdatedUserName: "unknown.generated",
                    LastUpdatedTimestamp: ""
                };
                localSortOrderModels.push(sortorderModel);
            }
            allSortOrderModels.push(...localSortOrderModels);
        }
        return allSortOrderModels;
    }

    public getOriginalDataHash(): number {
        return this.moduleEditingService.getOriginalDataHash;
    }

    /**
     * Checks if a row cluster may swap array location with another row cluster.
     *
     * @param current The row cluster to be checked.
     * @param swapWith The row cluster to check against
     * @param event A SortEvent describing the index change of the clusters to be swapped.
     * @returns A boolean indicating if the swap is allowed.
     */
    public isSortAllowed(current: RowCluster, swapWith: RowCluster, event: SortEvent): boolean {
        if (current.moduleId === swapWith.moduleId) {
            return false;
        }
        if (event.newIndex === event.currentIndex) {
            return true;
        }

        const currentModule = this.allModules.find(x => x.Id === current.moduleId);
        const swapWithModule = this.allModules.find(x => x.Id === swapWith.moduleId);

        const restrictions = Restrictions.create(currentModule, [swapWithModule]);
        if (event.newIndex < event.currentIndex) {
            return restrictions.allowBefore(swapWithModule);
        }
        if (event.newIndex > event.currentIndex) {
            return restrictions.allowAfter(swapWithModule);
        }
    }

    public updateTestRowsOrder(clusters: RowCluster[]): void {
        this.testRowsAtLocation = clusters.flatMap(cluster => cluster.rows);
        this.sortingsAtAllLocations = this.moduleEditingService.sortorderData;
        this.triggerSubjects();
        this.updateRowClusters();
        this.updateAssignedModulesLists();
        this.detectChanges();
    }

    public onUndo(): void {
        this.allEnabledTestRows = JSON.parse(this.originalTestRows);
        this.allModules = JSON.parse(this.originalModules);
        this.testRowsAtLocation = JSON.parse(this.orginalSortedRows);
        this.sortingsAtAllLocations = this.moduleEditingService.sortorderData;
        this.updateRowClusters();
        this.updateAssignedModulesLists();
        this.triggerSubjects();
        this.detectChanges();
    }


    public detectChanges(): void {
        const currentTestRows = JSON.stringify(this.allEnabledTestRows);
        const currentModules = JSON.stringify(this.allModules);
        const currentSortedRows = JSON.stringify(this.testRowsAtLocation);

        const testRowsChanged = currentTestRows !== this.originalTestRows;
        const modulesChanged = currentModules !== this.originalModules;
        const sortingChanged = currentSortedRows !== this.orginalSortedRows;
        this.hasChangesSubject.next(testRowsChanged || modulesChanged || sortingChanged);
    }
}
