<div class="hic-modules-list-container panel scrolling no-fill">

    <!-- Not yet assigned modules -->
    <div class="hitestmodule-list">
        <div class="hic-module-title-bar">
            <div class="hic-header" i18n="@@hitest.admin.assigned-modules-list.not-yet-assigned">Not Yet Assigned</div>
        </div>
        <div *ngFor="let module of unassignedModules$ | async">
            <hic-module-item [module]="module" [assignable]="true" (click)="selectModule(module)">
                <div class="hic-module-expanded" *ngIf="module === selectedModule" (click)="assignModule()">
                    <button *ngIf="hasSteps()" class="hic-assign-module">
                         <h5 header i18n="@@hitest.admin.assigned-modules-list.add-to-sequence">Add to sequence</h5>
                    </button>
                     <h5 *ngIf="!hasSteps()" class="not-assignable" header i18n="@@hitest.admin.assigned-modules-list.no-steps">Empty module</h5>
                </div>
            </hic-module-item>
        </div>
    </div>

    <!-- Assigned modules -->
    <div class="hitestmodule-list">
        <div class="hic-module-title-bar">
            <div class="hic-header" i18n="@@hitest.admin.assigned-modules-list.assigned">Assigned</div>
        </div>
        <div *ngFor="let module of assignedModules$ | async">
            <hic-module-item [module]="module" [assignable]="true" (click)="selectModule(module)">
                <div class="hic-module-expanded" *ngIf="module === selectedModule" (click)="unassignModule()">
                    <button class="hic-assign-module">
                         <h5 header i18n="@@hitest.admin.assigned-modules-list.unassign">Unassign</h5>
                    </button>
                </div>
            </hic-module-item>
        </div>
    </div>
</div>
