import { Directive, ElementRef, HostBinding, HostListener, Input, Renderer2 } from "@angular/core";
import { DraggableDirective } from "./draggable.directive";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

interface Position {
  x: number;
  y: number;
}

@Directive({
  selector: "[appMovable]"
})
export class MovableDirective extends DraggableDirective {
  @HostBinding("style.transform") public get transform(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `translateX(${this.position.x}px) translateY(${this.position.y}px)`
    );
  }

  @HostBinding("class.movable") public movable = true;

  public position: Position = {x: 0, y: 0};

  private startPosition: Position;

  @Input("appMovableReset") public reset = false;

  public constructor(private sanitizer: DomSanitizer, public element: ElementRef, renderer: Renderer2) {
    super(element, renderer);
  }

  @HostListener("dragStart", ["$event"])
  public onDragStart(event: PointerEvent): void {
    this.startPosition = {
      x: event.clientX - this.position.x,
      y: event.clientY - this.position.y
    };
  }

  @HostListener("dragMove", ["$event"])
  public onDragMove(event: PointerEvent): void {
    this.position.x = event.clientX - this.startPosition.x;
    this.position.y = event.clientY - this.startPosition.y;
  }

  @HostListener("dragEnd", ["$event"])
  public onDragEnd(event: PointerEvent) {
    if (this.reset) {
      this.position = {x: 0, y: 0};
    }
  }
}
