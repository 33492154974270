<div class="hic-hitestequipment-container">
    <div *ngIf="dataPopulated"><hic-emulator></hic-emulator></div>
    <div class="hic-center">
        <div class="panel">
            <hic-load-order
                (loadButtonPressed)="onLoadOrderAsync($event)"
                [showRobotModeOffline] = "moduleService.showRobotModeOffline"
                [completedTests]="completedTests"
                (previousTestsVisible)="setPreviousTestsVisible($event)">
            </hic-load-order>
        </div>

        <!-- Show previous test controls if any previously paused test was found -->
        <div *ngIf="showPrevTestBox" class="panel">
            <hic-prev-order (startOverPressed)="invalidatePrev()" (resumePressed)="resumePrev()" [showPrevTestAsPaused]="showPrevTestAsPaused"></hic-prev-order>
        </div>

        <!-- Show only when configuration has been loaded-->
        <ng-container *ngIf="craneEquipmentLoaded">
            <div class="panel flexible" style="min-height: 8em;">
                <hic-equipment-dnd></hic-equipment-dnd>
            </div>

            <button type="button" class="full-size-button panel horizontal centered" (click)="toggleTestRun()">
                <div class="play-icon-wrapper">
                    <div class="play-button"></div>
                    <h5 i18n="@@hitest.start.test">START TEST</h5>
                </div>
            </button>

            <div class="panel horizontal centered-cross-axis">
                <div class="item" i18n="@@hitest.start-step">Select start step</div>
                <select class="hic-select-small" [(ngModel)]="startStepNo">
                    <option *ngFor="let startRow of testRows; let counterIndex = index" [ngValue]="counterIndex">M{{ startRow.ModuleId }}/{{startRow.InternalStepNr}} : {{startRow.TestRowName}}</option>
                </select>
            </div>
        </ng-container>

        <div class="panel hic-equipment-bottom">
            <h4>Reload</h4>
            <button type="button" class="hic-hitest-edit-rule-button" (click)="forceReload()" header i18n="@@hitest.hitestoperator.force.reload">Force test reload</button>

            <div class="panel horizontal">
                <div class="panel horizontal halfpanel">
                    <div class="item" i18n="@@hitest.can">CAN</div>
                    <button type="button" class="button-item" (click)="activateCan()">Connect</button>
                    <button type="button" class="button-item" (click)="deactivateCan()">Disconnect</button>
                    <button type="button" class="button-item" (click)="checkStatus()">Check status</button>
                    <div class="item">{{statusCan()}}</div>
                </div>
                <div class="panel horizontal halfpanel">
                    <div class="item" i18n="@@hitest.plc">PLC</div>
                    <button type="button" class="button-item" (click)="activatePlc()">Connect</button>
                    <button type="button" class="button-item" (click)="deactivatePlc()">Disconnect</button>
                    <button type="button" class="button-item" (click)="checkStatus()">Check status</button>
                    <div class="item">{{statusPlc()}}</div>
                </div>
            </div>
            <div class="panel horizontal">
                <div class="panel horizontal halfpanel">
                   <div class="item" i18n="@@hitest.hiset">HiSet</div>
                    <button type="button" class="button-item" (click)="dumpHiSet()">Dump data</button>
                </div>
                <div class="panel horizontal halfpanel">
                    {{hiTestService.dumpHiSetResponse}}
                </div>
            </div>
    </div>
</div>

<hic-fullscreen-spinner [visible]="showLoadingBox" [text]="loadingBoxText">
</hic-fullscreen-spinner>


<div *ngIf="previousTestsVisible" class="hic-darkbox">
    <div class="hic-previous-tests">
        <div class="header">
            <div class="header-part" *ngIf="pausedTestExists">
                <p class="important" [matTooltip]="cannotSelectTestTooltip" i18n>Cannot select previous test, since paused test exists</p>
                <h1 i18n>Previous tests</h1>
            </div>
            <h1 *ngIf="pausedTestExists === false" i18n>Select test to load again</h1>
        </div>
        <div class="content">
            <table>
                <tr>
                    <th>Started</th>
                    <th>Ended</th>
                    <th>Bench</th>
                    <th>Status</th>
                </tr>
                <tr *ngFor="let completedTest of completedTests" [ngClass]="pausedTestExists? 'data-row-disabled' : 'data-row'" (click)="selectPreviousTest(completedTest)">
                    <td>{{completedTest.started | date: 'yyyy-MM-dd HH:mm'}}</td>
                    <td>{{completedTest.ended | date: 'yyyy-MM-dd HH:mm'}}</td>
                    <td>{{completedTest.testBenchName}}</td>
                    <td>{{completedTest.status}}</td>
                </tr>
            </table>
        </div>
        <div class="footer">
            <button type="button" (click)="setPreviousTestsVisible(false)">Close</button>
        </div>
    </div>
</div>
