import { HiTestConstants } from "./hitest.model";

/**
 * Used to populate the inputsModel (and the Criteria Input column) OnInit
 */
export interface IHiTestInputsModels {
    InputsModels: IHiTestInputsModel[];

}

export class HiTestInputDTO {
    public id: number;
    public inputName: string;
    public inputTypeId: number;
    public inputUnitId: number;
    public isHidden: boolean;
    public value: string;
    public referenceCount: number;

    /**
     * Creates a full clone of a HiTestInputDTO instance.
     *
     * @param source The source HiTestInputDTO to be cloned.
     */
    public static clone(source: HiTestInputDTO): HiTestInputDTO {
        const newInstance = HiTestInputDTO.fromJson(JSON.stringify(source));
        return newInstance;
    }

    /**
     * Copies safe properties from source object to target object. Note that
     * this function will **not** copy the _id_ and _referenceCount_ properties.
     *
     * @param target The target HiTestInputDTO that will receive new values
     * @param source The source HiTestInputDTO that contains the values to be copied.
     */
    public static copyProperties(target: HiTestInputDTO, source: HiTestInputDTO): void {
        target.inputName = source.inputName;
        target.inputTypeId = source.inputTypeId;
        target.inputUnitId = source.inputUnitId;
        target.isHidden = source.isHidden;
        target.value = source.value;
    }

    /** Converts a stringified HiTestInputDTO into an instance.
     *
     * @param json The stringified HiTestInputDTO representation to be converted.
     */
    public static fromJson(json: string): HiTestInputDTO {
        const jsonInstance = JSON.parse(json);
        const newInstance = new HiTestInputDTO();
        Object.assign(newInstance, jsonInstance);
        return newInstance;
    }
}

export class HiTestInputTypeDTO {
    public id: number;
    public inputTypeName: string;
    public isHidden: boolean;
    public syncTimeStamp: number;
}

export class HiTestInputUnitDTO {
    public id: number;
    public unit: string;
    public class: string;
}
export class HiTestTableLookUpDTO {
    public table: string;
    public columns: string[] = [];
}

export class HiTestDiagnosticValue {
    public Id: number;
    public hiTestInputDTO: HiTestInputDTO;
    public hitestLocationDTO: IHiTestLocation;
    public logToDb: boolean;
    public showOperator: boolean;
    public interval: number;
}

export class HiTestDiagnosticValuesDTO {
    public locationId: number;
    public hiTestDiagnosticValues: HiTestDiagnosticValue[];
}

export class HiTestLocationEntity {
    public id: number;
    public locationName: string;
}

/**
 * Sent to backend to add or remove an input to a model.
 */
export interface IHiTestInputsModel {
    id: number;
    inputName: string;
    inputTypeId: number;
    inputUnitId: number;
    isHidden: boolean;
    value: string;
    referenceCount: number;
}

export interface IHiTestInputType {
    id: number;
    inputTypeName: string;
    syncTimeStamp: number;
}

export interface IHiTestLocation {
    id: number;
    locationName: string;
}

/**
 * Received from backend to show what the existing inputs are
 * Contains all types of inputs
 */
export class MockInputTypeModel {
    public parameters: InputTypeContainer;
    public variables: InputTypeContainer;
    public table_lookup: InputTypeContainer;
    public dynamic: InputTypeContainer;
    public library: InputTypeContainer;
    public hudiksvall: InputTypeContainer;
    public stargard: InputTypeContainer;
    public zaragosa: InputTypeContainer;
}

/**
 * One input type
 */
export class InputTypeContainer {
    public name: string;
    public id: number;
    public inputValues: MockInputValue[] = [];
}

/**
 * The inputs within the input type
 */
export class MockInputValue {
    public inputType: InputType;
    public inputValueType: InputValueType;
    public friendlyName: string; // Used as key
}

export enum InputType {
    SpaceValue,
    TableData,
    TestBenchValue,
    ManualData
}

export enum InputValueType {
    Parameters = "Parameters",
    Variables = "Variables",
    ErrorCode = "ErrorCode",
    WeightsTable = "WeightsTable",
    PS2000 = "PS2000",
    Equipment = "Equipment",
    Craneconfig = "Craneconfig"
}

export class HiTestBenchDTO {
    /** Unique ID */
    public id?: number;
    /** Name of the testbench */
    public name: string;
    /** The location ID of the testbench */
    public locationId: number;
    /** An array of test bench interfaces on the test bench */
    public interfaces: TestBenchInterfaceDTO[];
}

export class TestBenchInterfaceDTO {
    /** CAN or PLC. Please use constants defined in HiTestConstants when dealing with this field. */
    public source: string;
    /** Unique ID of the interface */
    public id?: string;
    /** Name of the interface */
    public name: string;
    /** PLC Server IP or CAN serial number */
    public address: string;
    /** PLC server port or CAN channel number */
    public port?: number;
    /** CAN bitrate */
    public speed?: string;

    public constructor(address?: string, name?: string, source?: string) {
        this.address = address;
        this.name = name;
        this.source = source;
      }

}

export class SensorInputConfiguration {
    /** Unique ID or Sensor ID */
    public id = 1;
    /** For Can Sensor */
    public byteOrder?: number[] = new Array(8).fill(0);
    /** For PLC Sensor */
    public devicePoints?: number = 1;
    public maxAge?: number = 5000;
    public ascii?: boolean = false;
    public type?: number = HiTestConstants.plcTypes.word.value;
    public instructions?: string = "";
    public numerical?: boolean = false;
    public modifier?: string = HiTestConstants.modifierNames.none;
    public modifierValue?: number = 0;
}

export class HiTestBenchInputDTO extends HiTestInputDTO {
    public benchId: number;
    public sourceInputId: number;
}

export class SensorInput extends HiTestBenchInputDTO {
    /** CAN, PLC or Manual, defined in HiTestConstants */
    public type: string = HiTestConstants.sensorInterfaces.empty;
    public configuration: SensorInputConfiguration = new SensorInputConfiguration();

    public constructor(benchId?: number, sourceId?: number, unitId?: number) {
        super();
        this.benchId = benchId;
        this.inputName = "";
        this.value = "";
        this.id = 0;
        this.inputTypeId = 4;
        this.sourceInputId = sourceId;
        this.inputUnitId = unitId;
        this.isHidden = true;
        this.referenceCount = 0;
      }
}

export class TestBenchConnections {
    public can: string[] = [];
    public plc: string[] = [];
}

export class ImportLookupTableResponse {
    public result: string;
}
