import { IHiTestModuleRowModel, IHiTestModuleModel, HiTestConstants } from "../../models/hitest.model";

class Chain {
    public item: IHiTestModuleRowModel;
    public child: Chain;
    public parent: Chain;

    constructor(item: IHiTestModuleRowModel) {
        this.item = item;
    }

    public getChildById(id: number): Chain {
        if (this.item.Id === id) {
            return this;
        }

        let child = this.child;
        while (child) {
            if (child.item.Id === id) {
                return child;
            }
            child = child.child;
        }
        return undefined;
    }
}

export class RowCluster {
    public rows: IHiTestModuleRowModel[] = [];
    public moduleId: number = undefined;
    public moduleRowCount = 0;

    public static extractClustersFromModule(module: IHiTestModuleModel, considerUnbreakableModule: boolean): RowCluster[] {
        const rows = module.HiTestModuleRows.slice();
        rows.sort((a, b) => a.InternalStepNr - b.InternalStepNr);

        if (considerUnbreakableModule && module.UnbreakableModule === HiTestConstants.db.true && rows.length >= 1) {
            const unbreakableClusters: RowCluster[] = [];

            const cluster = new RowCluster();
            cluster.moduleId = module.Id;
            cluster.rows = rows.slice(0, 1);
            cluster.moduleRowCount = 1;
            unbreakableClusters.push(cluster);

            return unbreakableClusters;
        } else {
            const clusters = RowCluster.extractClusters(rows);
            clusters.forEach(cluster => cluster.moduleRowCount = rows.length);
            return clusters;
        }
    }

    public static extractClustersFromRows(rows: IHiTestModuleRowModel[]): RowCluster[] {
        const clusters = RowCluster.extractClusters(rows);
        for (const cluster of clusters) {
            cluster.moduleRowCount = rows.filter(row => row.ModuleId === cluster.rows[0].ModuleId).length;
        }
        return clusters;
    }

    private static extractClusters(rows: IHiTestModuleRowModel[]): RowCluster[] {
        const clusters: RowCluster[] = [];
        const chains = RowCluster.getChainsOfNonBreakableRows(rows);
        for (const chain of chains) {
            const cluster = new RowCluster();
            cluster.moduleId = chain.item.ModuleId;
            let chainChild = chain;
            while (chainChild) {
                cluster.rows.push(chainChild.item);
                chainChild = chainChild.child;
            }
            clusters.push(cluster);
        }

        return clusters;
    }

    private static getChainsOfNonBreakableRows(rows: IHiTestModuleRowModel[]): Chain[] {
        const chains: Chain[] = [];
        for (const row of rows) {
            if (!row.NotBreakableFromParentId) {
                chains.push(new Chain(row));
            }

            if (row.NotBreakableFromParentId) {
                let childChain: Chain;
                for (const chain of chains) {
                    childChain = chain.getChildById(row.NotBreakableFromParentId);
                    if (childChain) {
                        break;
                    }
                }
                if (childChain) {
                    childChain.child = new Chain(row);
                } else {
                    throw new Error("Something is wrong with the ordering of rows");
                }
            }
        }
        return chains;
    }
}
