import { Injectable } from "@angular/core";
import { first } from "rxjs/operators";
import { HiTestInputsHttpService } from "../inputs/services/hitestinputs.http.service";
import { HiTestConstants } from "../models/hitest.model";
import { IHiTestLocation } from "../models/hitestinputs.model";

@Injectable({
    providedIn: "root"
})
export class HiTestLocationsService {
    private storedLocations: IHiTestLocation[];
    constructor(private hitestInputsHttpService: HiTestInputsHttpService) {}

    public clearCache(): void {
        this.storedLocations = undefined;
    }

    public async getLocationsAsync(): Promise<IHiTestLocation[]> {
        if (this.storedLocations) {
            return this.storedLocations;
        }

        const fetchedLocations = await this.hitestInputsHttpService.getLocations().pipe(first()).toPromise();
        const unknownLocation = [{ id: HiTestConstants.location.unknownLocation, locationName: "Unknown location" }];
        this.storedLocations = fetchedLocations;
        return fetchedLocations ? fetchedLocations : unknownLocation;
    }
    public async getUserLocation(): Promise<IHiTestLocation> {
        const currentUserLocation = await this.hitestInputsHttpService.getUserLocation().pipe(first()).toPromise();
        return currentUserLocation;
    }
}
