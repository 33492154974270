<div class="hitest-load-order-container">
    <div class="search-section">
        <div class="hitest-order-number-container">
            <div class="hitest-order-number">
                <div class="header">
                    <span i18n style="margin-left: 10px; cursor: default;">Order or serial number</span>
                </div>
                <hic-compact-input2 id="input-component" [(ngModel)]="orderToLoad" type="text"
                    [enterFunction]="orderToLoad ? loadOrder.bind(this) : null"></hic-compact-input2>
            </div>
            <button type="button" class="hitest-load-button" (click)="loadOrder()" [disabled]="!orderToLoad">
                <span i18n>LOAD ORDER</span>
            </button>
        </div>
        <div *ngIf="completedTests && completedTests.length !== 0" class="hitest-previous-performed-present-container">
            <button type="button" class="hitest-load-button" (click)="showPreviousTests()" [matTooltip]="previouslyPerformedTestsTooltip">
                <span i18n>Performed tests</span>
            </button>
        </div>
        <div *ngIf="showRobotModeOffline" class="hitest-robot-mode-container">
            <span [ngClass]="hiTestService.robotModeButtonEnabled? 'robot-mode-header-active':''" class=""
            (click)="toggleRobotMode()" i18n>Robot mode</span>
            <div class="robot-mode-toggle">
                <hic-toggle2 [(ngModel)]="hiTestService.robotMode" (toggled)="robotModeChange()"
                    [disabled]="!hiTestService.robotModeButtonEnabled"></hic-toggle2>
            </div>
        </div>
    </div>

    <div *ngIf="isOrderLoaded" class="result-section">
        <div class="result-item">
            <div class="header" i18n="@@hitest.order-number" style="cursor: default;">Order number:</div>
            <div class="result">
                {{orderNumber}}
            </div>
        </div>

        <div class="result-item">
            <div class="header" i18n="@@hitest.order-type" style="cursor: default;">Order type:</div>
            <div class="result">
                {{orderType}}
            </div>
        </div>

        <div class="result-item">
            <div class="header" i18n="@@hitest.crane-name" style="cursor: default;">Crane Model:</div>
            <div class="result">
                {{craneModel}}
            </div>
        </div>

        <div *ngIf="itemId" class="result-item">
            <div class="header" i18n="hitest.probuilder-ProbuilderName" style="cursor: default;">ProBuilder Name:</div>
            <div class="result">
                {{itemId}}
            </div>
        </div>

        <div *ngIf="craneSerial" class="result-item">
            <div class="header" i18n="@@hitest.crane-serial" style="cursor: default;">Serial number:</div>
            <div class="result">
                {{craneSerial}}
            </div>
        </div>
    </div>
</div>
