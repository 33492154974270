import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { IHiTestModuleModel } from "src/app/hitest/models/hitest.model";
import { SortingService } from "src/app/hitest/services/sorting.service";

@Component({
    selector: "hic-assigned-modules-list",
    templateUrl: "./assignedModulesList.component.html",
    styleUrls: ["../../../hitest-admin.scss", "./assignedModulesList.component.scss"]
})

export class AssignedModulesListComponent {
    private _selectedModule: IHiTestModuleModel;

    public get selectedModule(): IHiTestModuleModel {
        return this._selectedModule;
    }
    public assignedModules$: Observable<IHiTestModuleModel[]>;
    public unassignedModules$: Observable<IHiTestModuleModel[]>;

    constructor(private sortingService: SortingService) {
        this.assignedModules$ = sortingService.assignedModules$;
        this.unassignedModules$ = sortingService.unassignedModules$;
    }

    public selectModule(selectedModule: IHiTestModuleModel): void {
        this._selectedModule = selectedModule;
    }

    public hasSteps(): boolean {
        return this.selectedModule.HiTestModuleRows.length > 0;
    }

    public async assignModule(): Promise<void> {
        if (this.hasSteps()) {
            this.sortingService.assignModule(this.selectedModule);
            this._selectedModule = undefined;
        }
    }

    public async unassignModule(): Promise<void> {
        this.sortingService.unassignModule(this.selectedModule);
        this._selectedModule = undefined;
    }
}
