<ng-container *ngIf="showAlert===true">
    <hic-response-alert [visible]="showAlert" [message]="message" (closed)="closeAlert()"
        (answer)="removeAnswer($event)">
    </hic-response-alert>
</ng-container>


<div class="table-background-panel">
    <div class="header-row">
        <div class="bench-name-header">Bench ID</div>
        <div class="source-headers">
            <div class="source-header" *ngFor="let can of canHeaders">
                {{ can }}
            </div>
            <div class="source-header" *ngFor="let plc of plcHeaders">
                {{ plc }}
            </div>
        </div>
        <div class="delete-margin"></div>
    </div>
</div>
<div class="testbench-row-container">
    <hic-test-bench-row *ngFor="let testBench of testBenches" (opens)="openConfig(testBench)" [columns]="columns"
        [testBench]="testBench" (delete)="deleteTestBench($event)"></hic-test-bench-row>
</div>
<div class="add-button-row">
    <button hicAddBtn (click)="addNew()">+</button>
</div>

<div *ngIf="showEditor" class="hic-darkbox">
    <hic-closeable-panel (closing)="loadTable()">
        <hic-testbench-editor [selectedTestBench]="selectedTestBench" (closed)="close()"
            (deleteTestBenchConfig)="deleteTestBenchConfig($event)" (changedConfigRow)="changedConfigRow($event)">
        </hic-testbench-editor>
    </hic-closeable-panel>
</div>
