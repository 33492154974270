import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { AlertMessage, HiTestMessageService, Message, MessageType } from "./hitest-message.service";

@Component({
    selector: "hic-message-viewer",
    templateUrl: "./message-viewer.component.html",
    styleUrls: ["./message-viewer.component.scss"],
})
export class MessageViewerComponent {
    public messages$: Observable<Message[]>;
    public alerts$: Observable<AlertMessage[]>;
    public messageType = MessageType;

    constructor(
        private messageService: HiTestMessageService) {
        this.messages$ = this.messageService.messages$;
        this.alerts$ = this.messageService.alerts$;

    }
}
