import { Component, OnInit } from "@angular/core";
import { EmulatedInput } from "src/app/hitest/models/hitest.model";
import { EmulatorService } from "src/app/hitest/services/emulator.service";

@Component({
    selector: "hic-emulator",
    templateUrl: "./emulator.component.html",
    styleUrls: ["./emulator.component.scss"],
})


export class EmulatorComponent implements OnInit {


    public collapsed = false;

    constructor(public emulatorService: EmulatorService) {}
    ngOnInit(): void {
    }

    public toggleCollapsed(): void {
        this.collapsed = !this.collapsed;
    }

    public emulatedValueChanged(changedInput: EmulatedInput, newValue): void {
        // The CompactInputComponent:s "valueChange" emits right BEFORE the value is changed,
        // that´s why we set the value from the provided value in that case
        if (newValue) {
            changedInput.value = newValue;
        }
        console.log(changedInput);
        this.emulatorService.ChangeEmulatedValue(changedInput);
    }

    public GetOrderedListOfEmulated(): EmulatedInput[] {
        const response =
        this.emulatorService.emulatedInputs.sort((n1, n2) => {
            if (n1.name > n2.name) {
                return 1;
            }

            if (n1.name < n2.name) {
                return -1;
            }

            return 0;
        });
        return response;
    }



}
