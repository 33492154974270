import { HiTestBenchInputDTO, HiTestDiagnosticValuesDTO, IHiTestLocation, ImportLookupTableResponse } from "src/app/hitest/models/hitestinputs.model";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, firstValueFrom, Observable, Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { HiTestBenchDTO, HiTestDiagnosticValue, HiTestInputDTO, HiTestInputTypeDTO, HiTestInputUnitDTO, HiTestTableLookUpDTO } from "./../../models/hitestinputs.model";
import { HiTestInputsHttpService } from "./hitestinputs.http.service";
import { HiTestConstants } from "../../models/hitest.model";
import { DynamicInput } from "../dynamic-inputs/models/dynamic-input.model";

@Injectable({
    providedIn: "root"
})
export class HiTestInputsService implements OnDestroy {
    private serviceDestroyed$ = new Subject<boolean>();
    public inputs: HiTestInputDTO[];
    public inputNames: string[];
    public inputTypes: HiTestInputTypeDTO[];
    public inputUnits: HiTestInputUnitDTO[];
    public lookUpSelection: HiTestTableLookUpDTO[];
    public benchSensorInputs: HiTestBenchInputDTO[];
    public diagnosticValues: HiTestDiagnosticValue[];

    private inputsSubject = new BehaviorSubject<HiTestInputDTO[]>([]);
    public inputs$: Observable<HiTestInputDTO[]> = this.inputsSubject.asObservable();

    private inputTypesSubject = new BehaviorSubject<HiTestInputTypeDTO[]>([]);
    public inputTypes$: Observable<HiTestInputTypeDTO[]> = this.inputTypesSubject.asObservable();

    private updateReadySubject = new Subject<boolean>();
    public updateReady$: Observable<boolean> = this.updateReadySubject.asObservable();

    private createReadySubject = new Subject<boolean>();
    public createReady$: Observable<boolean> = this.createReadySubject.asObservable();

    private testBenchesSubject = new BehaviorSubject<HiTestBenchDTO[]>([]);
    public testBenches$: Observable<HiTestBenchDTO[]> = this.testBenchesSubject.asObservable();

    private inputNamesSubject = new BehaviorSubject<string[]>(null);
    public inputNames$: Observable<string[]> = this.inputNamesSubject.asObservable();

    constructor(private hitestInputsHttpService: HiTestInputsHttpService) { }

    ngOnDestroy(): void {
        this.serviceDestroyed$.next(true);
        this.serviceDestroyed$.complete();
    }

    public getInputs(): void {
        this.hitestInputsHttpService.getInputs().pipe(takeUntil(this.serviceDestroyed$))
            .subscribe(response => {
                this.inputs = response;
                this.inputsSubject.next(response);
            });
    }

    public getInputNames(): void {
        this.hitestInputsHttpService.getInputNames().pipe(takeUntil(this.serviceDestroyed$))
            .subscribe(response => {
                this.inputNames = response;
                this.inputNamesSubject.next(response);
            });
    }

    public async getInputsAsync(): Promise<HiTestInputDTO[]> {
        const inputs = await this.hitestInputsHttpService.getInputs().toPromise();
        this.inputs = inputs;
        return inputs;
    }

    public async getInputUnitsAsync(): Promise<HiTestInputUnitDTO[]> {
        const units = await this.hitestInputsHttpService.getUnits().toPromise();
        this.inputUnits = units;
        return units;
    }

    public async getUnitByIdAsync(id: number): Promise<HiTestInputUnitDTO> {
        const units = this.inputUnits ?? await this.getInputUnitsAsync();
        const unit = units.find(x => x.id === id);
        return unit;
    }

    /**
     * Used to populate the frontends InputTypeContainer
     */
    public getInputTypes(): void {
        this.hitestInputsHttpService.getInputTypes().pipe(takeUntil(this.serviceDestroyed$))
            .subscribe(response => {
                this.inputTypes = response;
                this.inputTypesSubject.next(response);
            });
    }

    public async getInputTypesAsync(): Promise<HiTestInputTypeDTO[]> {
        const inputTypes = await this.hitestInputsHttpService.getInputTypes().toPromise();
        this.inputTypes = inputTypes;
        this.inputTypesSubject.next(inputTypes);
        return inputTypes;
    }

    public async deleteInputAsync(inputToDelete: HiTestInputDTO): Promise<boolean> {
        try {
            const response = await this.hitestInputsHttpService.deleteInput(inputToDelete)
                .pipe(takeUntil(this.serviceDestroyed$), first()).toPromise();
            return response;
        } catch {
            return false;
        } finally {
            this.getInputs();
        }
    }

    public updateInput(inputToUpdate: HiTestInputDTO): void {
        this.hitestInputsHttpService.updateInput(inputToUpdate).pipe(takeUntil(this.serviceDestroyed$))
            .subscribe((response: boolean) => {
                this.updateReadySubject.next(response);

                // Always reload inputs
                this.getInputs();
            },
                err => {
                    this.updateReadySubject.next(false);
                });
    }

    public createInput(inputToCreate: HiTestInputDTO): void {
        this.hitestInputsHttpService.createInput(inputToCreate).pipe(takeUntil(this.serviceDestroyed$))
            .subscribe((response: boolean) => {
                this.createReadySubject.next(response);

                // Always reload inputs
                this.getInputs();
            },
                err => {
                    this.createReadySubject.next(false);
                });
    }

    public async getTableLookUpSelectionAsync(): Promise<void> {
        this.lookUpSelection = await firstValueFrom(this.hitestInputsHttpService.getTableLookUpSelection());
    }

    public async getLookupTableForExportAsync(tableName: string): Promise<string> {
        const response = await firstValueFrom(this.hitestInputsHttpService.getLookUpTableForExport(tableName));
        return response;
    }

    public async importLookupTableAsync(tableName: string, importedLookup: string): Promise<ImportLookupTableResponse> {
        try {
            const response = await this.hitestInputsHttpService.importLookupTableAsync(tableName, importedLookup);
            return response;
        } catch {
            throw new Error("Failed to import Lookup table");
        }
    }

    public async getLookupTableListAsync(): Promise<string[]> {
        const response = await firstValueFrom(this.hitestInputsHttpService.getLookupTableList());
        return response;
    }

    public async getTestBenchesAsync(locationId: number): Promise<HiTestBenchDTO[]> {
        const response = await this.hitestInputsHttpService.getTestBenchInterfaces(locationId).toPromise();
        this.testBenchesSubject.next(response);
        return response;
    }

    public async updateTestBenchAsync(testBechToUpdate: HiTestBenchDTO, locationId: number): Promise<boolean> {
        const response = await this.hitestInputsHttpService.updateTestBench(testBechToUpdate).toPromise();
        await this.getTestBenchesAsync(locationId);
        return response;
    }

    public async deleteTestBenchAsync(testBenchId: number, locationId: number): Promise<boolean> {
        const response = await this.hitestInputsHttpService.deleteTestBench(testBenchId).toPromise();
        await this.getTestBenchesAsync(locationId);
        return response;
    }

    public async getDynamicBenchInputs(): Promise<DynamicInput[]> {

        if (!this.inputTypes) {
            await this.getInputTypesAsync();
        }
        if (!this.inputs) {
            await this.getInputsAsync();
        }

        const dynamicInputId = this.inputTypes.find(x => x.inputTypeName === HiTestConstants.inputTypes.dynamic);
        const dynamicInputs = this.inputs.filter(x => x.inputTypeId === dynamicInputId.id)
            .map(x => DynamicInput.fromInputDTO(x, this.inputs));

        const dynamicBenchInputs = dynamicInputs.filter(x => x.valueData.allowBenchData);

        return dynamicBenchInputs;
    }

    public async getBenchSensorInputs(locationId: number): Promise<HiTestBenchInputDTO[]> {
        const response = await this.hitestInputsHttpService.getBenchSensorInputs(locationId).toPromise();
        this.benchSensorInputs = response;
        return response;
    }

    public async updateBenchSensorInput(benchInput: HiTestBenchInputDTO): Promise<boolean> {
        const response = await this.hitestInputsHttpService.updateBenchSensorInput(benchInput).toPromise();
        return response;
    }

    public async deleteBenchSensorInput(inputId: number): Promise<boolean> {
        const response = await this.hitestInputsHttpService.deleteBenchSensorInput(inputId).toPromise();
        return response;
    }

    public async getDiagnosticValues(locationId?: number): Promise<HiTestDiagnosticValue[]> {
        const idString = locationId ? locationId?.toString() : "";
        this.diagnosticValues = await this.hitestInputsHttpService.getDiagnosticValues(idString).toPromise();
        return this.diagnosticValues;
    }

    public async saveDiagnosticValues(dto: HiTestDiagnosticValuesDTO): Promise<boolean> {
        const result = await this.hitestInputsHttpService.saveDiagnosticValues(dto).toPromise();
        return result;
    }

    public async getLocations(): Promise<IHiTestLocation[]> {
        const locations = await this.hitestInputsHttpService.getLocations().toPromise();
        return locations;
    }

    public async getUserLocation(): Promise<IHiTestLocation> {
        const location = await this.hitestInputsHttpService.getUserLocation().toPromise();
        return location;
    }

}
