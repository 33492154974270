<div class="hic-modules-list">
    <button class="hic-module hic-gradiated-header"
        [class.selected]="isSelected"
        [routerLink]="navigatable ? ('../' + module.Id) : undefined"
        [disabled]="disabled"
        [title]="disabledTitle">
        <div class="hic-module-number" [ngStyle]="{'background-color': backgroundColor}">
            <div>M{{module.Id}}</div>
        </div>
        <div class="hic-indented">
            {{module.TestModuleName}}
        </div>
        <div class="hic-sortorderitem">
            {{sortorderItem}}
        </div>
        <div class="hic-module-assign-status" *ngIf="assignable">
            <div [ngClass]="isAssigned ? 'module-assigned' : 'module-not-assigned' "></div>
        </div>
    </button>
    <div>
        <ng-content></ng-content>
    </div>
</div>
