import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HiTestStatusHubService } from "src/app/hitest/services/hitest-status-hub.service";
import { DiagInputDTO } from "../../models/hitest-status-message.interface";

@Component({
    selector: "hic-diagnostic-values",
    templateUrl: "./diagnostic-values.component.html",
    styleUrls: ["./diagnostic-values.component.scss"],
})

export class DiagnosticValuesComponent implements OnInit, OnDestroy {
    private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

    public newlyUpdated = false;
    public diagnosticValues: DiagInputDTO[] = [];

    constructor(private statusHubService: HiTestStatusHubService) {}

    ngOnInit(): void {
        this.statusHubService.diagnosticValueMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
            if (result) {
                this.diagnosticValues = result.diagnosticInputDTOs;

                // Activate the flag to highlight the new values
                this.newlyUpdated = true;
                setTimeout(() => {
                    // Deactivate the flag to remove highlighing and make the value "Flash"
                    this.newlyUpdated = false;
                }, 100);
            }
        });
        this.statusHubService.requestDiagValuesUpdate();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }
}
