<div class="hic-hitestequipment-dnd-container">
    <div class="item-list-container panel">
        <div class="hic-inline-parts">
            <h4>
                <span header i18n="@@hitest.equipment.browse.dnd">PS2000 Codes</span>
                <button type="button" class="copy-button" (click)="copyOrderInfoToClipboard()">
                    <img class="copy-icon" src="assets/symbols/copy.svg" alt="Copy order data" />
                </button>
            </h4>
            <div>
                <hic-toggle2 [(ngModel)] = "craneEquipment.omspS2000ToHiSet" [label]="generateOMSPS2000ToHiSetLabel" [matTooltip]="generateOMSPS2000ToHiSetTooltip"></hic-toggle2>
            </div>
        </div>
            <div class="item-list">
            <div *ngFor="let p of craneEquipment?.pS2000Codes; let i = index" class="list-item">

                <div *ngIf="i !== editIndex"
                    (dblclick)="openForEdit(i)">{{p}}
                </div>

                <hic-compact-input2 *ngIf="i === editIndex"
                    [(ngModel)]="editText"
                    type="string"
                    (focusout)="closeEdit()"
                    id="input-component">
                </hic-compact-input2>

            </div>
        </div>
        <button type="button" (click)="addPs2000Code()" i18n="@@hitest.equipment.selection.addcode">Add code</button>
    </div>
    <div class="item-list-container panel">
         <h4 header i18n="@@hitest.equipment.selection.dnd">Equipment Selection</h4>
        <div class="item-list">
            <div *ngFor="let e of selectedEquipment" class="list-item"> {{e.name}} </div>
        </div>
    </div>
</div>
