<div class="testbench-row">
    <div class="bench-name" (click)="onOpen()"> {{ testBench.name }}</div>
    <div class="source-names">
        <div *ngFor="let canName of canNames" class="source">
            {{canName}}
        </div>
        <div *ngFor="let plcName of plcNames" class="source">
            {{plcName}}
        </div>
    </div>
    <div class="delete-margin">
        <button *ngIf="testBench.id !== idOfDefaultBench" class="remove-row-button" hicDeleteBtn (click)="onDelete()">X</button>
    </div>
</div>
