import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject, timer } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { HiTestMessageService } from "src/app/hitest/services/message/hitest-message.service";
import { HiTestConstants, IHiTestModuleModel } from "src/app/hitest/models/hitest.model";
import { IHiTestLocation } from "src/app/hitest/models/hitestinputs.model";
import { RowCluster } from "src/app/hitest/services/helpers/row-cluster.model";
import { HiTestModuleService } from "src/app/hitest/services/hitest-modules.service";
import { HiTestService } from "src/app/hitest/services/hitest.service";
import { ModuleColoringService } from "src/app/hitest/services/module-coloring.service";
import { SortingService } from "src/app/hitest/services/sorting.service";
import { SortEvent } from "../../draggable/sortable-list.directive";

class RowClusterViewModel extends RowCluster {
    public moduleColor: string;
    public startNumber = 0;
    public endNumber = 0;
    public representsUnbreakableModule = false;
    public representsModuleName: string;
    public static fromRowCluster(rowCluster: RowCluster, moduleColor?: string, module?: IHiTestModuleModel): RowClusterViewModel {
        const item = Object.assign(new RowClusterViewModel(), rowCluster);
        item.moduleColor = moduleColor;
        item.representsUnbreakableModule = module.UnbreakableModule == HiTestConstants.db.true;
        item.representsModuleName = module.TestModuleName;
        return item;
    }
}

@Component({
    selector: "hic-hitest-row-order-editor",
    templateUrl: "./hitest-row-order-editor.component.html",
    styleUrls: ["./hitest-row-order-editor.component.scss"],
})
export class HiTestRowOrderEditorComponent implements OnInit, OnDestroy {
    @Input() public location: IHiTestLocation;
    private componentDestroyed$ = new Subject<boolean>();
    public debugMessage = "";
    private listUpdatedSubject: Subject<boolean> = new Subject<boolean>();
    public listUpdated$: Observable<boolean> = this.listUpdatedSubject.asObservable();
    public hasChanges$: Observable<boolean>;
    public isMoveAllowed = true;
    public clusters: RowClusterViewModel[] = [];
    public draggingStartIndex: number = undefined;
    public afterSaveInformationVisible = false;
    public afterSaveInformationText = "";

    constructor(
        private sortingService: SortingService,
        private hitestService: HiTestService,
        private moduleService: HiTestModuleService,
        private moduleColoringService: ModuleColoringService,
        private messageService: HiTestMessageService
    ) {
        this.hasChanges$ = this.sortingService.hasChanges$;
    }

    async ngOnInit(): Promise<void> {
        this.setupClustersOfUnbreakableRowsSubscription();
        this.setupSaveReadySubscription();
        this.activateScrollingHandler();
        await this.moduleService.updateModuleDataAsync();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    public onDragStart(index: number): void {
        this.isMoveAllowed = true;
        this.draggingStartIndex = index;
        console.log(`Started dragging item from index ${index}.`);
    }

    public onDragEnd(index: number): void {
        console.log(`Stopped dragging item at index ${index}.`);
        if (index !== this.draggingStartIndex) {
            this.sortingService.updateTestRowsOrder(this.clusters);
        }
        this.draggingStartIndex = undefined;
    }

    private setupClustersOfUnbreakableRowsSubscription(): void {
        this.sortingService.clustersOfUnbreakableRows$.pipe(takeUntil(this.componentDestroyed$), filter(x => !!x)).subscribe((clusters) => {
            this.clusters = clusters.map(cluster => {
                const rcvm =
                    RowClusterViewModel.fromRowCluster(cluster, this.getModuleColor(cluster.moduleId), this.getModule(cluster.moduleId));
                rcvm.startNumber = this.sortingService.getRowNumber(cluster.rows[0]);
                rcvm.endNumber = this.sortingService.getRowNumber(cluster.rows[cluster.rows.length - 1]);
                return rcvm;
            });
            this.reinitSortFunction(0);
        });
    }

    private getModule(moduleId: number): IHiTestModuleModel {
        return this.moduleService.allModules.find(x => x.Id === moduleId);
    }

    private setupSaveReadySubscription(): void {
        this.hitestService.saveReady$.pipe(takeUntil(this.componentDestroyed$)).subscribe((ready) => {
            if (ready) {
                this.messageService.sendMessage(
                    "Successfully saved changes!"
                );
            }
        });
    }

    /**
     *  Handles when a dragged element is about to leave the screen,
     *  and the list should be scrolled to "catch" the element
     */
    public activateScrollingHandler(): void {
        // Distance from edge before activating scroll
        const activateMargin = 80;

        const scrollingTimer = timer(1000, 100);

        scrollingTimer.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
            const elm = document.getElementById("draggable");
            if (elm !== null) {
                // Element is not null -> dragging is performed
                const container = document.getElementById(
                    "hic-rows-container"
                );

                const topBreakpoint = container.offsetTop + activateMargin;

                const bottomBreakpoint =
                    container.clientHeight + container.offsetTop - activateMargin;

                const y = elm.offsetTop;

                if (y < topBreakpoint) {
                    // The dragged element is near the top. Scroll uppward.
                    container.scrollTop =
                        container.scrollTop - (topBreakpoint - y);
                }

                if (y > bottomBreakpoint) {
                    // The dragged elemtn is near the bottom. Scroll downward.
                    container.scrollTop =
                        container.scrollTop + (y - bottomBreakpoint);
                }
            }
        });
    }

    public sortRow(sortEvent: SortEvent): void {
        const current = this.clusters[sortEvent.currentIndex];
        const swapWith = this.clusters[sortEvent.newIndex];
        this.isMoveAllowed = this.sortingService.isSortAllowed(current, swapWith, sortEvent);
        if (this.isMoveAllowed) {
            // Use Destructuring assignment to swap items in the array.
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
            [ this.clusters[sortEvent.currentIndex], this.clusters[sortEvent.newIndex] ] =
                [ this.clusters[sortEvent.newIndex], this.clusters[sortEvent.currentIndex] ];
        }
    }

    private getModuleColor(moduleId: number): string {
        return this.moduleColoringService.getModuleColor(moduleId);
    }

    public async onSave(): Promise<void> {
        const response = await this.hitestService.saveAndReloadSortorder(this.location.id);
        if (response.extraInformation) {
            this.afterSaveInformationText = response.extraInformation;
            this.afterSaveInformationVisible = true;
        }
    }

    public onAfterSaveInformationConfirm(): void {
        this.afterSaveInformationVisible = false;
    }

    public onUndo(): void {
        this.sortingService.onUndo();
    }

    /**
     *  Reinitialize the sort-function (after a short delay to allow DOM to finish)
     */
    private reinitSortFunction(time: number): void {
        const initTimer = timer(time);
        initTimer.pipe(takeUntil(this.listUpdated$))
            .subscribe(() => {
                this.listUpdatedSubject.next(true);
            });
    }
}
