import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ButtonType } from "src/app/core/dialogs/models/button-type.enum";
import { IDialogOptions } from "src/app/core/dialogs/models/dialog-options.interface";
import { DialogResult } from "src/app/core/dialogs/models/dialog-result.enum";
import { DialogService } from "src/app/core/dialogs/services/dialog.service";
import { IHiTestPreviousTestDTO } from "src/app/hitest/models/hitest.model";
import { HiTestService } from "src/app/hitest/services/hitest.service";

@Component({
    selector: "hic-prev-order",
    templateUrl: "./prev-order.component.html",
    styleUrls: ["./prev-order.component.scss"]
})
export class PrevOrderComponent implements OnInit {
    @Output() public startOverPressed = new EventEmitter();
    @Output() public resumePressed = new EventEmitter();
    @Input() public showPrevTestAsPaused = true;
    public previousTest: IHiTestPreviousTestDTO;

    public pausedDate: string;
    public startedDate: string;
    public completed: boolean;
    public readonly startOverTooltipText = $localize `Abort this test to be able to start over testing`;
    public readonly resumeTooltipText = $localize `Resume this test`;

    constructor(public hiTestService: HiTestService, public dialogService: DialogService) { }

    ngOnInit(): void {
        this.previousTest = this.hiTestService.previousTest;
        this.pausedDate = this.formatDates(this.previousTest.paused);
        this.startedDate = this.formatDates(this.previousTest.started);
        this.completed = this.previousTest.performedModules === this.previousTest.totalModules;
    }

    private formatDates(dateTime: string): string {
        const dateTimeObj = new Date(dateTime);
        const time = dateTimeObj.toLocaleTimeString("sv-SE", { hour12: false });
        const date = dateTimeObj.toLocaleDateString("sv-SE");

        return time.slice(0, 5) + " | " + date;
    }

    public async startOver(): Promise<void> {
        const dialogOptions: IDialogOptions = {
            title: $localize `:@@hitest.confirm.start-over.header:Confirm \"Start over\"`,
            content: $localize `:@@hitest.confirm.start-over:Confirm that the test should be reset.`,
            confirmativeButton: ButtonType.yes,
            dismissiveButton: ButtonType.no
        };

        const dialogResult = await this.dialogService.showModalDialogAsync(dialogOptions);
        if (dialogResult === DialogResult.confirm) {
            this.startOverPressed.emit();
        }
    }

    public resume(): void {
        this.resumePressed.emit();
    }
}
