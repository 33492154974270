import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { HiTestConstants } from "src/app/hitest/models/hitest.model";
import { TestBenchInterfaceDTO } from "src/app/hitest/models/hitestinputs.model";

@Component({
    selector: "hic-testbench-interface",
    templateUrl: "./testbench-interface.component.html",
    styleUrls: ["../../../../admin/hitest-admin.scss",
        "./testbench-interface.component.scss"]
})
export class TestbenchInterfaceComponent implements OnInit {
    @Input() public testBenchInterface: TestBenchInterfaceDTO;
    @Output() public interfaceRemovalRequested = new EventEmitter<TestBenchInterfaceDTO>();

    public title = "Delete?";
    public message = "";
    public showAlert = false;

    public sources = HiTestConstants.sensorInterfaces;

    public benchInterfaceTypes = [this.sources.can, this.sources.plc];
    public bitRates = [
        HiTestConstants.canBitRates.k10,
        HiTestConstants.canBitRates.k50,
        HiTestConstants.canBitRates.k62,
        HiTestConstants.canBitRates.k83,
        HiTestConstants.canBitRates.k100,
        HiTestConstants.canBitRates.k125,
        HiTestConstants.canBitRates.k250,
        HiTestConstants.canBitRates.k500
    ];

    ngOnInit(): void { }

    public showRemovalAlert(item: TestBenchInterfaceDTO): void {
        if (item) {
            this.message = `Do you really want to remove '${item.name}'?`;
            this.showAlert = true;
        }
    }

    public closeAlert(): void {
        this.showAlert = false;
    }

    public onRemoveAlertResponse(wasConfirmed: boolean): void {
        if (wasConfirmed) {
            this.interfaceRemovalRequested.emit(this.testBenchInterface);
        }
    }

    public validateIp(value: string, isValid: boolean): boolean {
        if(!isValid) {
            return false;
        }

        const regExpMaskPattern = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

        return regExpMaskPattern.test(value);
    }
}
