import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HiTestMessageService } from "src/app/hitest/services/message/hitest-message.service";
import { Equipment } from "src/app/hitest/models/hitest.model";
import { HiTestService } from "src/app/hitest/services/hitest.service";

@Component({
    selector: "hic-equipment-dnd",
    templateUrl: "./equipment-dnd.component.html",
    styleUrls: ["./../../../../admin/hitest-admin.scss", "./equipment-dnd.component.scss"]
})

export class HitestEquipmentDndComponent implements OnInit, OnDestroy {
    public craneEquipment = this.hiTestService.newCraneEquipmentEntity();
    public selectedEquipment: Array<Equipment> = [];
    public editIndex: number = undefined; // Currently edited PS2000 code index
    public editText: string; // Currently edited PS2000 code test
    private componentDestroyed$ = new Subject<boolean>();
    public generateOMSPS2000ToHiSetLabel = $localize `RAW config to HiSet`;
    public generateOMSPS2000ToHiSetTooltip = $localize `Use pure PS2000 codes from OMS instead of this list to send to HiSet.`;

    constructor(
        private hiTestService: HiTestService,
        private messageService: HiTestMessageService
    ) {
        this.hiTestService.craneEquipmentReady$.pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
            if (result) {
                this.refreshCraneEquipment();
            }
        });
    }

    ngOnInit(): void {
        this.refreshCraneEquipment();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    public filterSelectedEquipment(): void {
        this.selectedEquipment = this.craneEquipment.equipments.filter(x => x.selected === true);
    }


    public addPs2000Code(): void {
        this.hiTestService.craneEquipment.pS2000Codes.push("");
        this.openForEdit(this.hiTestService.craneEquipment.pS2000Codes.length - 1);
    }

    public openForEdit(index: number): void {

        // We don't want to edit the actual element directly since this triggers change detection after every
        //  keystroke, and we looses focus. Create a copy of string to edit instead.
        this.editText = this.hiTestService.craneEquipment.pS2000Codes[index];
        this.editIndex = index;

        // Wait for DOM, and focus the actual <input> element inside <hic-compact-input2>
        setTimeout(() => {
            const element = (document.getElementById("input-component").firstChild.firstChild) as HTMLElement;
            element.focus();
        }, 0
        );
    }

    public closeEdit(): void {
        this.hiTestService.craneEquipment.pS2000Codes[this.editIndex] = this.editText;
        this.editIndex = undefined;

        this.removeEmptyEntries();
    }

    public copyOrderInfoToClipboard(): void {
        const copyText = JSON.stringify({
            orderNumber: this.hiTestService.craneEquipment.orderNumber,
            craneModel: this.hiTestService.craneEquipment.craneModel,
            itemId: this.hiTestService.craneEquipment.itemId,
            pS2000Codes: this.hiTestService.craneEquipment.pS2000Codes,
        });
        navigator.clipboard.writeText(copyText);

        const message = $localize `:@@hitest.hitestoperator.orderinfo.copied:Order information copied to clipboard.`;

        this.messageService.sendMessage(message);
    }

    private removeEmptyEntries(): void {
        this.hiTestService.craneEquipment.pS2000Codes = this.hiTestService.craneEquipment.pS2000Codes.filter(x => x);
    }

    private refreshCraneEquipment(): void {
        this.craneEquipment = this.hiTestService.craneEquipment;
        this.filterSelectedEquipment();
    }
}
