<table class="summary-table">
    <tbody>
        <tr class="numeric-data">
            <td i18n="@@summary.table.titleFirstBoom">1st Boom</td>
            <td>{{ boomData.firstBoom.ascSpeed }}</td>
        </tr>
        <tr class="numeric-data">
            <td i18n="@@summary.table.titleSecondBoom">2nd Boom</td>
            <td>{{ boomData.secondBoom.ascSpeed }}</td>
        </tr>
        <tr *ngIf="boomData.hasThirdBoom" class="numeric-data">
            <td i18n="@@summary.table.titlejib">Jib</td>
            <td>{{ boomData.thirdBoom.ascSpeed }}</td>
        </tr>
    </tbody>
</table>
