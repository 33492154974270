<div class="hic-hitestoperator-container">
    <hic-emulator></hic-emulator>
    <hic-context-menu></hic-context-menu>
    <div class="left">
        <div class="editor">
            <hic-collapsable-panel #collapsable theme="dark" [allowOpen]="true" [collapsed]="false" [id]="'crane'">
                <span header i18n="@@hitest.hitestoperator.header.tools">Tools</span>
                <div class="manual-order">
                    <button type="button" class="hic-hitest-edit-rule-button" (click)="reinitCanSensors()" header
                        i18n="@@hitest.hitestoperator.reinit.can.sensors">Reinit Can Sensors</button>
                    <br />
                    <button type="button" class="hic-hitest-edit-rule-button" (click)="activateSkipLegs()" header
                        i18n="@@hitest.hitestoperator.activate.skiplegs">Activate 'Skip Legs'</button>
                    <br />
                </div>
            </hic-collapsable-panel>

            <!-- Pressures -->
            <hic-collapsable-panel #collapsable theme="dark" [allowOpen]="true" [collapsed]="false" [id]="'pressures'">
                <span header i18n="@@crane-table.header">Pressures</span>
                <hic-summary-table [nrOfColumns]="4" [hitest]="true">
                    <hic-crane-table>
                    </hic-crane-table>
                </hic-summary-table>
            </hic-collapsable-panel>


            <!-- Crane Functions -->
            <hic-collapsable-panel #collapsable theme="dark" [allowOpen]="true" [collapsed]="false"
                [id]="'crane-functions'">
                <span header i18n="@@crane-functions.header">Crane Functions</span>

                <hic-summary-table [nrOfColumns]="6" [hitest]="true">
                    <hic-hand-controller-table>
                    </hic-hand-controller-table>
                </hic-summary-table>

            </hic-collapsable-panel>

            <!-- Diagnostic values -->
            <hic-collapsable-panel #collapsable theme="dark" [allowOpen]="true" [collapsed]="false"
                [id]="'diagnosic-values'">
                <span header i18n="@@hitest.hitestoperator.diagnostic.values">Diagnostic Values</span>
                <hic-diagnostic-values>
                </hic-diagnostic-values>
            </hic-collapsable-panel>
        </div>
    </div>

    <div class="main">
        <div class="top">
            <div class="pause-test" [ngStyle]="{'cursor': isRestartable() || isTestStarted() ? 'pointer' : ''}">
                <button type="button" class="button-resume" *ngIf="isRestartable()" (click)="toggleTestRun()">
                    <div class="play-button"></div>
                    <h5>
                        <span i18n="@@hitest.restart.test">RESUME TEST</span>
                    </h5>
                </button>
                <button type="button" class="button-pause" *ngIf="isTestStarted()" (click)="toggleTestRun()">
                    <div class="pause-button"></div>
                    <h5>
                        <span i18n="@@hitest.pause.test">PAUSE TEST</span>
                    </h5>
                </button>
            </div>

            <div class="progress-bar">
                <hic-progress-bar [completedProgress]="getNumberOfStepsPerformed()"
                    [totalProgress]="totalNumberOfStepsToExecute()">
                </hic-progress-bar>
            </div>
            <button type="button" class="stop-button" (click)="stopTest()" [disabled]="!isTestStarted()"
                [ngStyle]="{'cursor': isTestStarted() ? 'pointer' : ''}">
                <h5 *ngIf="isTestStarted()" i18n="@@hitest.stop">ABORT</h5>
            </button>
            <div class="robot-mode">
                <h5 *ngIf="currentTestStatus?.robotMode" i18n>
                    Robot mode
                </h5>
            </div>
        </div>

        <div class="bottom">
            <div class="middle">
                <ng-container *ngIf="modulesLoaded">
                    <hic-operatorsteps #operatorsteps [testRows]="testRowsToShow"
                        [currentTestStatus]="currentTestStatus" [currentRequests]="currentRequests"
                        [shownDescriptions]="shownDescriptions" [showRowProgress]="showRowProgress"
                        [showRowProgressIndeterminate]="showRowProgressIndeterminate" [showRowSpinner]="showRowSpinner"
                        [progressValue]="progressValue" [askOperatorRequests]="askOperatorRequests"
                        [operatorComment]="operatorComment" [parameterInitViewModel]="parameterInitViewModel"
                        [parameterInitDataRequest]="parameterInitDataRequest" [askFail]="askFail" [askStop]="askStop"
                        [interactiveAllowed]="modulesService.interactiveAllowed"
                        (toggleShownDescriptionEvent)="toggleShownDescription($event)"
                        (updateCurrentRequestsEvent)="updateCurrentRequests($event)"
                        (hideParameterInitModelEvent)="hideParameterInitModel()"></hic-operatorsteps>
                    <button *ngIf="rowsAreHidden" type="button" class="hic-hitest-show-all-steps"
                        (click)="revealHiddenRows()" [matTooltip]="revealHiddenStepsTooltip" i18n>
                        Reveal {{hiddenRows}} hidden steps
                    </button>
                </ng-container>
            </div>

            <div *ngIf="!parameterInitViewModel" class="right">
                <div class="testing-values-container">
                    <hic-hitest-criterias [activeTimers]="activeTimers" [inputValues]="inputValues"
                        [toleranceMeters]="toleranceMeters">
                    </hic-hitest-criterias>
                </div>
                <div class="live-values-container">
                    <hic-collapsable-panel #collapsable [allowOpen]="true" [collapsed]="false">
                        <span style="cursor: pointer;" header i18n="@@hitest.hitest-operator-live-values">Live Values
                        </span>
                        <div class="side-view" *ngIf="showSideView" hic-crane-side-view></div>
                        <div class="top-view" *ngIf="!showSideView">
                            <hic-top-view [truckTopViewSetup]="topViewSetup"></hic-top-view>
                        </div>

                        <div class="button-row">
                            <button type="button" class="btn-toggle-view" (click)="showSideView = !showSideView">
                                {{ showSideView ? "Stabilisers" : "Crane"}}
                            </button>
                        </div>
                    </hic-collapsable-panel>
                </div>
            </div>

            <!-- X4 Init parameters update request -->
            <div *ngIf="!isEvoSystem && parameterInitViewModel" class="parameter-init-container">
                <div class="parameter-init">
                    <h1>Parameters</h1>
                    <div *ngFor="let parameter of parameterInitViewModel.parameters" class="parameter-item"
                        [class.deselected]="!parameter.selected">
                        <div class="selector-button">
                            <hic-toggle2 [(ngModel)]="parameter.selected"></hic-toggle2>
                        </div>
                        <div class="parameter-name">{{parameter.item.name}}</div>
                        <div class="parameter-values">
                            <div class="parameter-value">{{parameter.item.newValue}}</div>
                            <div *ngIf="parameter.item.currentValue !== ''" class="parameter-current-value">
                                {{parameter.item.currentValue}}</div>
                        </div>
                    </div>
                    <h1 *ngIf="parameterInitViewModel.canParameters.length > 0">CAN Parameters</h1>
                    <ng-container *ngFor="let canParameter of parameterInitViewModel.canParameters; let i = index">
                        <div *ngIf="canParameter" class="can-parameter-item"
                            [class.deselected]="!canParameter.selected">
                            <div>
                                <h2>CAN parameter ID: {{i}}</h2>
                                <div>
                                    <div *ngFor="let subParameter of canParameter.items" class="can-parameter-subitem">
                                        <div class="selector-button">
                                            <hic-toggle2 [(ngModel)]="subParameter.selected"></hic-toggle2>
                                        </div>
                                        <div class="parameter-name">{{subParameter.name}}</div>
                                        <div class="parameter-values">
                                            <div class="parameter-value">{{subParameter.newValue}}</div>
                                            <div class="parameter-current-value">{{subParameter.currentValue}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- EVO Init parameters update request -->
            <div *ngIf="isEvoSystem && parameterInitViewModel" class="scrollable">
                <h3>Parameters</h3>
                <div *ngIf="showAliasProBuilderName()" class="probuilder">
                    <h6 i18n>Applied alias</h6>
                    <div i18n>Oms ProBuilder Name: {{parameterInitViewModel.omsProBuilderName}}</div>
                    <div i18n>Alias ProBuilder Name: {{parameterInitViewModel.aliasProBuilderName}}</div>
                </div>
                <div class="parameters-grid">
                    <div *ngFor="let parameter of parameterInitViewModel.parameters let i = index"
                        class="parameters-table"
                        [ngClass]='i !== parameterInitViewModel.parameters.length - 1 ? "show-border-bottom" : ""'>
                        <div *ngIf="i===0">
                            <h6>Id</h6>
                        </div>
                        <div *ngIf="i===0">
                            <h6>Name</h6>
                        </div>
                        <div *ngIf="i===0">
                            <h6>Current Value</h6>
                        </div>
                        <div *ngIf="i===0">
                            <h6>New Value</h6>
                        </div>
                        <div *ngIf="i===0" class="write-header">
                            <h6>Write Parameter</h6>
                            <hic-toggle2 [ngModel]="true"
                                (toggled)="toggleAllWriteParameterToggles($event)"></hic-toggle2>
                        </div>
                        <div [class.deselected]="!parameter.selected">{{parameter.item.id}}</div>
                        <div [class.deselected]="!parameter.selected">{{parameter.item.name}}</div>
                        <div [class.deselected]="!parameter.selected">{{parameter.item.currentValue}}</div>
                        <div [class.deselected]="!parameter.selected">{{parameter.item.newValue}}</div>
                        <hic-toggle2 [(ngModel)]="parameter.selected"></hic-toggle2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="hic-darkbox" *ngIf="showDarkbox" style="display: flex; flex-direction: column; justify-content: center">
        <div class="main">
            <div class="top">
                <div class="title">
                    <h3>{{popupTitle}}</h3>
                </div>
                <div class="info"> {{popupInfo}}</div>
                <textarea type="text" placeholder="Comment..." name="test" #textbox
                    [(ngModel)]="commentValue"></textarea>
                <div class="no-comment" *ngIf=(noComment)>
                    <h4 i18n="@@hitest.submit.comment">Submit a comment to skip the step</h4>
                </div>

            </div>
            <div class="bottom">
                <button type="button" class="left-button" (click)="submitComment()">
                    <h3 i18n="@@hitest.yes">Yes</h3>
                </button>
                <button type="button" class="right-button" (click)="toggleDarkbox()">
                    <h3 i18n="@@hitest.no">No</h3>
                </button>
            </div>
        </div>
    </div>

    <hic-fullscreen-spinner [visible]="showLoadingBox" [text]="loadingBoxText">
        <p *ngIf="darkboxMessage" class="connecting">
            {{ darkboxMessage }} {{ darkboxPercent }}
        </p>
    </hic-fullscreen-spinner>


    <div *ngIf="showTestApproved" class="hic-modal-dialog-container">
        <div class="context">
            {{ approveMessage }}
        </div>
        <button id="confirm-test-approved" type="button" class="modal-dialog-bottom" (click)="showTestApproved = false">
            OK
        </button>
    </div>

    <div *ngIf="moduleSelectionViewModel" class="hic-darkbox module-selection">
        <div class="content">
            <div class="header">
                <div>
                    <h3 i18n="@@hitest.module.selection.header">Select modules to run</h3>
                </div>
                <div><hic-toggle2 [label]="enableAllText" [(ngModel)]="enableAll" (toggled)="toggleAll()"></hic-toggle2>
                </div>
            </div>
            <div class="list">
                <div *ngFor="let module of moduleSelectionViewModel">
                    <div class="module-selection-subitem">
                        <div class="module-selected">
                            <hic-toggle2 [disabled]="!module.canModify" [(ngModel)]="module.selected"></hic-toggle2>
                        </div>
                        <div class="module-lock">
                            <mat-icon *ngIf="!module.canModify" class="lock-icon" (dblclick)="runAgain(module)"
                                [matTooltip]="unlockModuleTooltip">
                                lock
                            </mat-icon>
                        </div>
                        <button type="button" [ngStyle]="{'background': getModuleColor(module.moduleId)}"
                            class="module-identifier" (click)="toggleModuleSelected(module)">
                            M{{module.moduleId}} {{module.moduleName}}
                        </button>
                        <div class="module-status" *ngIf="!module.canModify">
                            ({{module.status}})
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <button type="button" class="module-selection-button module-selection-ok" id="done-button"
                    (click)="sendModuleSelection()" i18n="@@hitest.module.selection.Ok">Ok</button>
                <button type="button" class="module-selection-button" (click)="stopTest()"
                    i18n="@@hitest.stop">ABORT</button>
            </div>
        </div>
    </div>

    <hic-message-viewer />