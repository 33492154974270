import { IAdvancedExpression } from "src/app/hitest/models/advanced-expression.model";
import { HiTestInputDTO } from "src/app/hitest/models/hitestinputs.model";
import { DynamicExpression } from "./dynamic-expression.model";

export class DynamicInputValueData {
    public expressions: DynamicExpression[] = [];
    public allowExpressions = true;
    public allowBenchData = true;
    public allowManualEntry = true;

    public static fromJson(jsonString: string, availableInputs: HiTestInputDTO[]): DynamicInputValueData {
        let jsonObject = jsonString ? JSON.parse(jsonString) : undefined;
        if (jsonObject) {
            if (Array.isArray(jsonObject)) { // This can happen if input type has been converted from AdvancedExpression
                const tempArray: IAdvancedExpression[] = [...jsonObject];
                jsonObject = new DynamicInputValueData();
                jsonObject.expressions = tempArray;
            }
            const data: DynamicInputValueData = Object.assign(new DynamicInputValueData(), jsonObject);
            if (data.expressions && Array.isArray(data.expressions)) {
                data.expressions = data.expressions.map(x => DynamicExpression.fromAdvancedExpression(x, availableInputs));
            } else {
                data.expressions = [];
            }
            return data;
        }
        return new DynamicInputValueData();
    }

    public toJson(): string {
        const valueData = Object.assign(new DynamicInputValueData(), this);

        // Force feed the Dynamic epxression converteded into an advanced expression into the valueData.expressions property.
        const advancedExpressions = this.expressions.map(x => x.toAdvancedExpression());
        valueData.expressions = advancedExpressions.map(x => x as unknown as DynamicExpression);
        const json = JSON.stringify(valueData);
        return json;
    }
}
