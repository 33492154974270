<div class="prev-test-container">
    <span *ngIf="showPrevTestAsPaused" i18n="@@hitest.prev.test">This crane has a previously stored test.</span>
    <span *ngIf="showPrevTestAsPaused === false" i18n="@@hitest.performed.test">This crane has a previously performed test.</span>

    <div class="grey-box">
        <div class="upper-row">
            <div class="upper-column">
                <span i18n="@@hitest.prev.factory">Factory</span>
                <div class="data">{{previousTest.factory}}</div>
            </div>
            <div class="upper-column">
                <span i18n="@@hitest.prev.bench">Test bench</span>
                <div class="data">{{previousTest.testBench}}</div>
            </div>
            <div class="upper-column">
                <span i18n="@@hitest.prev.started">Started</span>
                <div class="data">{{startedDate}}</div>
            </div>
            <div class="upper-column">
                <span *ngIf="showPrevTestAsPaused" i18n="@@hitest.prev.paused">Paused</span>
                <span *ngIf="showPrevTestAsPaused === false" i18n="@@hitest.prev.stopped">Stopped</span>
                <div class="data" form>{{pausedDate}}</div>
            </div>
        </div>

        <div class="progress-bar">
            <hic-progress-bar [completedProgress]="previousTest.performedModules"
                [totalProgress]="previousTest.totalModules" [operatorView]="false">
            </hic-progress-bar>
        </div>
    </div>

    <div class="footer-buttons">
        <button type="button" class="startover-button hitest-startover-button" (click)="startOver()" [matTooltip]="startOverTooltipText" i18n="@@hitest.prev.startover">
            START OVER
        </button>
        <button type="button" class="resume-button hitest-resume-button" (click)="resume()" [matTooltip]="resumeTooltipText" i18n="@@hitest.prev.resume">
            RESUME
        </button>
    </div>
</div>
