<div class="hic-hitest-row-order-editor">
    <div class="hic-hitest-list">
        <div appSortableList
            (sort)="sortRow($event)"
            (dragStart)="onDragStart($event)"
            (dragEnd)="onDragEnd($event)"
            [sourceUpdated$]="listUpdated$"
            class="hic-rows-container" id="hic-rows-container">
            <div appSortable *ngFor="let cluster of clusters">
                <ng-template #rowitem let-isDragIcon>
                    <div class="row-container">
                        <div class="hic-row-counter-number">
                            {{ cluster.startNumber }}{{ cluster.endNumber > cluster.startNumber ? ("-" + cluster.endNumber) : ""}}
                        </div>
                        <ng-container *ngIf="cluster.rows[0]; let row">
                            <!-- Single row -->
                            <div *ngIf="cluster.rows.length === 1" class="hic-test-row" [ngStyle]="{'background': isMoveAllowed || !isDragIcon ? '' : '#b12020'}">
                                <div *ngIf="cluster.representsUnbreakableModule" class="hic-row-index" [ngStyle]="{'background': cluster.moduleColor}">
                                    <div class="hic-module-number"> M{{row.ModuleId}} </div>
                                </div>

                                <div *ngIf="!cluster.representsUnbreakableModule" class="hic-row-index" [ngStyle]="{'background': cluster.moduleColor}">
                                    <div class="hic-module-number"> M{{row.ModuleId}} </div>
                                    <div class="hic-row-number">{{ row.InternalStepNr }}/{{ cluster.moduleRowCount }}</div>
                                </div>

                                <div *ngIf="cluster.representsUnbreakableModule" class="hic-modulebackground">{{ cluster.representsModuleName }}</div>
                                <div *ngIf="!cluster.representsUnbreakableModule" class="hic-indented">{{ row.TestRowName }}</div>
                            </div>
    
                            <!-- Multiple rows -->
                            <div class="cluster-container" *ngIf="cluster.rows.length > 1" >
                                <div class="hic-test-row" [ngStyle]="{'background': isMoveAllowed || !isDragIcon ? '' : '#b12020'}">
                                    <div class="hic-row-index" [ngStyle]="{'background': cluster.moduleColor}">
                                        <div class="hic-module-number"> M{{row.ModuleId}} </div>
                                        <div class="hic-row-number">{{ row.InternalStepNr }}-{{ cluster.rows[cluster.rows.length - 1].InternalStepNr }}/{{ cluster.moduleRowCount }}</div>
                                    </div>
                                    <div class="hic-indented">{{ row.TestRowName }} [...]</div>
                                </div>
                                <div class="hic-test-row behind-one" [ngStyle]="{'background': isMoveAllowed || !isDragIcon ? '' : '#b12020'}"></div>
                                <div class="hic-test-row behind-two" [ngStyle]="{'background': isMoveAllowed || !isDragIcon ? '' : '#b12020'}"></div>
                            </div>
                        </ng-container>
                    </div>
                </ng-template>

                <ng-container *ngTemplateOutlet="rowitem; context: {$implicit: false}"></ng-container>
                <div *appDraggableHelper id="draggable">
                    <ng-container *ngTemplateOutlet="rowitem; context: {$implicit: true}"></ng-container>
                </div>
            </div>
        </div>
    </div>
    <hic-light-footer-buttons (undoButtonPressed)="onUndo()" (saveButtonPressed)="onSave()" [undoVisible]="hasChanges$ | async"
        [saveVisible]="hasChanges$ | async" [allSavedVisible]="!(hasChanges$ | async)">
    </hic-light-footer-buttons>
    
    <!-- Information Dialog -->
    <div class="hic-darkbox" *ngIf="afterSaveInformationVisible">
        <div class="confirm-box">
            <div class="header">Warning</div>
            <div class="content">
                <div>
                    <img class="validation-symbol" src="/assets/symbols/warning.svg"/>
                </div>
                <div>
                    {{afterSaveInformationText}}
                </div>
            </div>
            <div class="single-button">
                <button (click)="onAfterSaveInformationConfirm()">Confirm</button>
            </div>
        </div>
    </div>
</div>
