import { IHiTestConditionEntity } from "src/app/hitest/models/hitest.model";
import { IHiTestModuleRestriction } from "src/app/hitest/models/hitest.model";
import { IHiTestModuleRowModel } from "src/app/hitest/models/hitest.model";
import { IHiTestModuleModel } from "src/app/hitest/models/hitest.model";

export class HiTestModuleModel implements IHiTestModuleModel {
    public Id = 0;
    public TestModuleName = "New Module";
    public Description = "";
    public Instruction = "";
    public Ps2000Rules: string;
    public ValidFrom: string; // Date;
    public ValidTo: string; // Date;
    public Enabled: number;
    public ConfirmedToExpire: number;
    public confirmedToExpireBy: string;
    public LocationId: number;
    public HiTestModuleRows: IHiTestModuleRowModel[] = [];
    public RequirementUrl = "";
    public HiTestModuleRestrictionModules: IHiTestModuleRestriction[] = [];
    public HiTestModuleRestrictionReferencingModules: IHiTestModuleRestriction[] = [];
    public HiTestConditionModuleApproval: IHiTestConditionEntity[] = [];
    public HiTestConditionModuleCondition: IHiTestConditionEntity[] = [];
    public UnbreakableModule = -1;
    public LastUpdatedTimestamp: string;
    public LastUpdatedUserName: string;
    public RelatedFunction: string;
    public FunctionalRequirementJSON: string;
    public WorkFlowState: string;
    public MaxRunTimeSeconds = 300;
}
