export enum DynamicInputValidationStatus {
    valid,
    nameMissing,
    unitMissing,
    emptyExpressionsArray,
    invalidExpressionsField,
    noInputMethodSelected,
    noFallbackMethodSelected,
    unreachableFallbacks,
    notReady,
    noValidInputProvided,
    expressionSourceInputMissing,
    expressionInvalidCriteria,
    expressionIllegalCriteriaOrder,
    modifierTypeMissing,
    modifierInputMissing,
    modifierInputObjectMissing,
    modifierValueMissing,
    modifierMethodMissing,
    modifierIllegalMethodName,
    modifierDivisionByZeroError
}
