import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { firstValueFrom, Subject, timer as observableTimer } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";
import { ControlSystemGeneration } from "src/app/core/connections/enums/control-system-generation.enum";
import { HiTestMessageService } from "src/app/hitest/services/message/hitest-message.service";
import { ConditionsService } from "src/app/core/space/services/conditions/conditions.service";
import { PollingHandler } from "src/app/core/space/services/polling/polling-handler";
import { PollingService } from "src/app/core/space/services/polling/polling.service";
import { SacBuildService } from "src/app/overview/file/services/sac-build.service";
import { TruckTopViewSetup } from "src/app/shared/graphics/top-view/models/truck-top-view-setup.model";
import {
    CalculatorValue, HiTestConstants, HiTestMockedComment, IHiTestModuleModel, IHiTestModuleRowModel,
    InitParametersResponseDto, InitParametersViewModel, ITestRowViewModel, moduleSelection, ModuleSelectionViewModel,
    modulesSelectionResponse, modulesSelectionResponseDto, ParameterInitializationResponse
} from "../models/hitest.model";
import { IHiTestLocation } from "../models/hitestinputs.model";
import { HiTestLocationsService } from "../services/hitest-locations.service";
import { HiTestModuleService } from "../services/hitest-modules.service";
import { HiTestStatusHubService } from "../services/hitest-status-hub.service";
import { HiTestService } from "../services/hitest.service";
import { ModuleColoringService } from "../services/module-coloring.service";
import { OperatorstepsComponent } from "./components/operatorsteps/operatorsteps.component";
import {
    HiTestTimerValue, IHiTestAskClientForDataUpdateMessage,
    IHiTestAskGuiToClearRequestMessage, IHiTestAskOperatorMessage,
    IHiTestGuidToRestartMessage, IHiTestInputsStatusMessage,
    IHiTestMessage, IHiTestProgressMessage, IHiTestRowUpdateMessage,
    IHiTestShowProgressBarMessage,
    IHiTestShowToleranceMetersMessage,
    IHiTestRowsToSkipMessage, IHiTestTimerStatusMessage,
    IHiTestUpdateToleranceMetersMessage,
    InputValue,
    operatorResponseDataType as OperatorResponseDataType,
    ToleranceMeterDto
} from "./models/hitest-status-message.interface";
import { IDialogOptions } from "src/app/core/dialogs/models/dialog-options.interface";
import { ButtonType } from "src/app/core/dialogs/models/button-type.enum";
import { DialogService } from "src/app/core/dialogs/services/dialog.service";
import { DialogResult } from "src/app/core/dialogs/models/dialog-result.enum";

@Component({
    selector: "hic-hitestoperator",
    templateUrl: "./hitestoperator.component.html",
    styleUrls: ["./hitestoperator.component.scss"],
})
export class HitestOperatorComponent implements OnInit, OnDestroy {
    @ViewChild("operatorsteps") private operatorStepsComponent: OperatorstepsComponent;
    public orderNumber = "";
    public startFromStep = null;
    public force = false;
    public modulesLoaded: boolean;
    public showDoneWindow: boolean;
    public shownDescriptions: number[] = [];
    public shownCommentSections: number[] = [];
    public showDarkbox = false;
    public showLoadingBox = false;
    public showTestApproved = false;
    public approveMessage = "";
    public loadingBoxText = "";
    public commentValue = "";
    public noComment = false;
    public currentRowId: number;
    public displayCommentDropdown = false;
    public filteredComments: HiTestMockedComment[] = [];
    public testRows: ITestRowViewModel[] = [];
    public testRowsToShow: ITestRowViewModel[] = [];
    public modules: IHiTestModuleModel[] = [];
    public operatorComment = "";
    public askFail = false;
    public askStop = false;
    public darkboxMessage = "";
    public darkboxPercent = "";
    public parameterInitDataRequest: InitParametersResponseDto;
    public parameterInitViewModel: InitParametersViewModel;
    public moduleSelectionViewModel: ModuleSelectionViewModel[];
    public showRowSpinner = false;
    public showRowProgress = false;
    public showRowProgressIndeterminate = false;
    public progressValue = 0;
    public progressType = "determinate";
    public readonly testRowIdPrefix = "testrow-";

    public currentTestStatus: IHiTestProgressMessage;
    public activeTimers: HiTestTimerValue[] = [];
    public inputValues: InputValue[] = [];
    public toleranceMeters: ToleranceMeterDto[];
    public askOperatorRequests: IHiTestAskOperatorMessage[] = [];
    public currentRequests: IHiTestAskOperatorMessage[];
    public topViewSetup = new TruckTopViewSetup(true, false, false);
    public showSideView = true;
    public isEvoSystem: boolean;
    public popupTitle = $localize `:@@hitest.hitestoperator.leaving.step:Are you sure you want to leave this step?`;
    public popupInfo = $localize `:@@hitest.hitestoperator.add.comment:Please add a comment on why the step was not left unapproved.`;
    public unlockModuleTooltip = $localize `Double click to unlock module (i.e. to run it again)`;
    public revealHiddenStepsTooltip = $localize `Steps are hidden to increase performance. Click to temporary show all hidden steps.`;

    private readonly operatorResponseRetry = "retry";
    private readonly operatorResponseFail = "fail";
    private testGuidToRestart: string;
    private location: IHiTestLocation;
    private lastActiveStep: number = null;
    private lastModuleId: number = null;
    private moduleSelectionRequest: modulesSelectionResponseDto;
    private componentDestroyed$ = new Subject<boolean>();
    private readonly instructionRowTypeName = "Instruction";
    public rowsAreHidden = false;
    public hiddenRows: number;
    public enableAll = true;
    public enableAllText = $localize`Select/Deselect all`;

    public constructor(
        public hitestService: HiTestService,
        public modulesService: HiTestModuleService,
        private locationsService: HiTestLocationsService,
        private statusHubService: HiTestStatusHubService,
        private messageService: HiTestMessageService,
        private conditionsService: ConditionsService,
        private pollingService: PollingService,
        private sacBuildService: SacBuildService,
        public moduleColoringService: ModuleColoringService,
        private dialogService: DialogService
    ) { }

    public async ngOnInit(): Promise<void> {
        // Remove any alerts left behind in queue from any previous session
        this.messageService.clearAlerts();

        // Show spinner while loading test sequence
        this.showLoadingBoxSpinner();
        this.isEvoSystem = this.conditionsService.controlSystemGeneration === ControlSystemGeneration.Evo;

        // Load modules
        await this.modulesService.updateModuleDataAsync();

        this.setupMessageHandling();

        await this.statusHubService.init();

        this.hitestService.resendMessages();

        this.hitestService.setNextRowPerformed$.pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
            if (result){
                this.hideModuleSelection();
                this.hideParameterInitModel();
            }
        });

        // Hide the spinner
        this.showLoadingBox = false;
    }

    public ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    public toggleAll(): void {
        this.moduleSelectionViewModel.forEach(x => {
            if (x.canModify) {
                x.selected = this.enableAll;
            }
        });
    }

    private updateRowsToShow():void {
        this.rowsAreHidden = false;

        if (!this.testRows) {
            // No rows to show exists
            this.testRowsToShow = [];
            return;
        }

        if (this.testRows.length < this.modulesService.guiRowLimit) {
            // We have not reached the row limit, show all
            // Use spread operator to ensure change detection is triggered
            this.testRowsToShow = [...this.testRows];
            return;
        }

        if (!this.currentTestStatus){
            // The test has no status, so nothing to show
            this.testRowsToShow = [];
            return;
        }

        if (!this.currentTestStatus.testIsRunning) {
            // The test has finished running. Show all rows.
            // Use spread operator to ensure change detection is triggered
            this.testRowsToShow = [...this.testRows];
            return;
        }

        this.limitRowsToShow();
    }

    private limitRowsToShow(): void {
        const lookAroundRows = this.modulesService.visibleRowsRadius; // Number of rows to always see forward and backward
        const rowsToShow: ITestRowViewModel[] = [];

        // Find which row is active (i.e. the row that is being executed by the test right now)
        const currentExecutingRowIndex = this.testRows.findIndex(x => x.Id == this.currentTestStatus.currentActiveRowId);

        let previousModule = -1;
        for (let i = 0; i < this.testRows.length; i++) {
            const rowToCheck = this.testRows[i];

            if (this.rowShouldBeVisible(i, rowToCheck, lookAroundRows, currentExecutingRowIndex)) {
                // Within window, or same module as current, show complete row content
                rowToCheck.placeholderForWholeModule = false;
                rowsToShow.push(rowToCheck);
            } else if (this.testRows[i].ModuleId !== previousModule) {
                // First row in a module, show only as module header (row content is hidden)
                rowToCheck.placeholderForWholeModule = true;
                rowsToShow.push(rowToCheck);
            }

            previousModule = this.testRows[i].ModuleId;
        }

        this.testRowsToShow = rowsToShow;

        this.hiddenRows = this.testRows.length - this.testRowsToShow.length;
        this.rowsAreHidden = (this.hiddenRows !== 0);
    }

    public revealHiddenRows(): void {
        setTimeout(() => this.testRowsToShow = this.testRows, 0);
        this.rowsAreHidden = false;
    }

    private rowShouldBeVisible(
        indexInTotalListToCheck: number,
        rowToCheck: ITestRowViewModel,
        lookAroundSteps: number,
        currentExecutingRowIndex: number): boolean {

        const startIndex = Math.max(currentExecutingRowIndex - lookAroundSteps, 0);
        const stopIndex = Math.min(currentExecutingRowIndex + lookAroundSteps, this.testRows.length);

        if (indexInTotalListToCheck >= startIndex && indexInTotalListToCheck <= stopIndex) {
            // step is within lookAround
            return true;
        }

        if (rowToCheck.ModuleId === this.testRows[currentExecutingRowIndex]?.ModuleId) {
            // Step belongs to current module
            return true;
        }

        return false;
    }

    public async sendModuleSelection(): Promise<void> {
        const identifier = this.moduleSelectionRequest.identifier;
        let dataToSend = this.moduleSelectionRequest;

        dataToSend = {
            identifier: this.moduleSelectionRequest.identifier,
            data: {
                hiTestPerformedTestModules: this.moduleSelectionViewModel.map(x => {
                    let status: string = x.status;
                    if (x.canModify) {
                        status = x.selected ?
                            HiTestConstants.hiTestPerformedTestModuleStatus.selected
                            : HiTestConstants.hiTestPerformedTestModuleStatus.deselected;
                    }

                    return {
                        id: x.id,
                        moduleId: x.moduleId,
                        moduleName: x.moduleName,
                        status: status,
                    };
                })
            }
        };

        await this.hitestService.sendModulesSelectionResponse(dataToSend);
        this.askOperatorRequests = this.askOperatorRequests.filter(x => x.identifier !== identifier);
        this.hideModuleSelection();
    }

    public toggleModuleSelected(module: ModuleSelectionViewModel): void {
        if (module.canModify) {
            module.selected = !module.selected;
        }
    }

    public getModuleColor(moduleId: number): string {
        return this.moduleColoringService.getModuleColor(moduleId);
    }

    public async runAgain(module: ModuleSelectionViewModel): Promise<void> {
        const dialogOptions: IDialogOptions = {
            title: $localize`:@@hitest.confirm.start-over.header:Confirm \"Start over\"`,
            content: $localize`:@@hitest.confirm.start-over:Confirm that module ${module.moduleId} should be restarted.`,
            confirmativeButton: ButtonType.yes,
            dismissiveButton: ButtonType.no
        };

        if (await this.dialogService.showModalDialogAsync(dialogOptions) === DialogResult.confirm) {
            module.status = "Selected";
            module.selected = true;
            module.canModify = true;
        }
    }

    /**
     * Checks if the specified test row is shown, if so it is hidden otherwise it is shown
     */
    public toggleShownDescription(internalStepNumber: number): void {
        if (this.shownDescriptions.includes(internalStepNumber)) {
            this.shownDescriptions = this.shownDescriptions.filter(
                (i) => i !== internalStepNumber
            );
        } else {
            this.shownDescriptions.push(internalStepNumber);
        }
    }

    /**
     * Checks if the specified test row comment section is shown, if so it is hidden otherwise it is shown
     */
    public toggleShownCommentSection(rowId: number): void {
        if (this.shownCommentSections.includes(rowId)) {
            this.shownCommentSections = this.shownCommentSections.filter(
                (i) => i !== rowId
            );
        } else {
            this.shownCommentSections.push(rowId);
        }
    }

    /**
     * TODO: Get description which displays under drop down for the corresponding test step from backend
     *
     * @param rowId
     */
    public getRowDescription(rowId: number): string {
        const row = this.testRows.find(i => i.Id === rowId);
        return row.Description;
    }

    /**
     * If there is no comment, display error message.
     * If there is a comment, submit it and move to next step
     */
    public submitComment(): void {
        if (this.hasComment()) {
            this.noComment = false;
            this.toggleDarkbox();
            // TODO: add comment submission pipe
            this.commentValue = "";
            this.moveToNextStep(this.currentRowId);
        } else {
            this.noComment = true;
        }
    }

    public toggleDarkbox(): void {
        this.commentValue = "";
        this.noComment = false;
        this.showDarkbox = !this.showDarkbox;
    }

    public isRestartable(): boolean {
        return !this.isTestStarted() && !!this.testGuidToRestart;
    }

    public toggleTestRun(): void {
        this.showTestApproved = false;
        if (this.isTestStarted()) {
            this.hitestService.stopTest(HiTestConstants.performedTest.paused);
            this.hideParameterInitModel();
        } else if (this.isRestartable()) {
            this.modulesLoaded = false;
            this.hitestService.restartTest(this.testGuidToRestart);
            this.testGuidToRestart = null;
        }
    }

    public async stopTest(): Promise<void> {
        await this.hitestService.stopTest(HiTestConstants.performedTest.aborted);
        this.hideParameterInitModel();
        this.hideModuleSelection();
    }

    public getNumberOfStepsPerformed(): number {
        let response = 0;
        if (this.currentTestStatus) {
            const currentStepId = this.getCurrentStepId();
            response = this.testRows.findIndex(x => x.Id === currentStepId) + 1;
        }
        return response;
    }

    public isTestStarted(): boolean {
        if (this.currentTestStatus) {
            return this.currentTestStatus.testIsRunning && this.currentTestStatus.testStatus === "Started";
        }
        return false;
    }

    public getCurrentStepId(): number {
        if (this.currentTestStatus) {
            return this.currentTestStatus.currentActiveRowId;
        }
        return null;
    }

    public totalNumberOfStepsToExecute(): number {
        return this.testRows.length;
    }

    // Returns true if provided row is actual current row
    public isThisCurrentStep(row): boolean {
        return (row.Id === (this.currentTestStatus?.currentActiveRowId));
    }

    public showDoneButton(row): boolean {
        const response =
            (this.currentTestStatus?.testIsRunning)
            && (row.Id === (this.currentTestStatus?.currentActiveRowId))
            && (row.RowType.RowType === this.instructionRowTypeName);
        return response;
    }

    public activateSkipLegs(): void {
        this.hitestService.activateSkipLegs();
    }

    public reinitCanSensors(): void {
        this.hitestService.reinitCanSensors();
    }
    public async updateCurrentRequests(clearAll: boolean): Promise<void> {
        const currentStepId = clearAll ? null : this.getCurrentStepId();

        const identifiersToCancel = this.askOperatorRequests.filter(x => x.stepId !== currentStepId).map(x => x.identifier);

        this.askOperatorRequests = this.askOperatorRequests.filter(x => x.stepId === currentStepId);

        // Make a local copy of the values (not reference)
        const localCopy = this.askOperatorRequests.map(x => Object.assign({}, x));

        // Only one Override-button should be present
        this.ensureOnlyOneOverride(localCopy);

        // Sort the entries with entrie(s) with askValue to the top
        localCopy.sort((a, _b) => a.askValue ? -1 : 1);

        // To avoid trigger OnChange in frontend every time, only update this.currentRequests if anything has changed
        if (!this.isEqual(this.currentRequests, localCopy)) {
            this.currentRequests = localCopy;
        }

        if (identifiersToCancel.length > 0) {
            await this.hitestService.cancelOperatorRequests(identifiersToCancel);
        }
        this.setOperatorFocus();
    }

    public showOmsProBuilderName(): boolean {
        return !!this.parameterInitViewModel.omsProBuilderName;
    }

    public showAliasProBuilderName(): boolean {
        return !!this.parameterInitViewModel.aliasProBuilderName;
    }

    public toggleAllWriteParameterToggles(isOn: boolean): void {
        for (const parameter of this.parameterInitViewModel.parameters) {
            parameter.selected = isOn;
        }
    }

    private hideModuleSelection(): void {
        this.moduleSelectionViewModel = undefined;
    }

    private async handleSortingDataAsync(stepIdsToSkip: number[]): Promise<void> {
        await firstValueFrom(this.modulesService.modulesUpdated$
            .pipe(takeUntil(this.componentDestroyed$), filter(x => x === true), first()));

        this.lastModuleId = null;
        this.modules = this.modulesService.allModules;

        const locations = await this.locationsService.getLocationsAsync();
        const locationId = this.modulesService.sortorderData.reduce<number>((prev, curr) => curr.LocationId, -1);
        this.location = locations.find(x => x.id === locationId);

        const testrows = this.modulesService.getSortedExpandedRowsByLocation(this.location).filter(x => !stepIdsToSkip.includes(x.Id));

        this.testRows = testrows.map(testrow => {
            const module = this.modules.find(mod => mod.Id === testrow.ModuleId);
            const rowViewModel: ITestRowViewModel = Object.assign({}, testrow, {
                moduleName: this.moduleIdTracker(testrow) ? module?.TestModuleName ?? "" : "",
                numberOfRowsInModule: module?.HiTestModuleRows?.length ?? 0,
                moduleDeselected: false,
                moduleFailed: false,
                placeholderForWholeModule: false,
            });
            return rowViewModel;
        });

        this.updateRowsToShow();

        this.updateModuleStatusForSteps();

        this.modulesLoaded = true;
    }

    private setupMessageHandling(): void {
        this.statusHubService.genericMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleGenericMessage(response);
        });
        this.statusHubService.askClientForDataUpdateMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleDataUpdateMessage(response);
        });
        this.statusHubService.shutdownMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleShutdownMessage(response);
        });
        this.statusHubService.guidToRestartMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleAskGuidToRestart(response);
        });
        this.statusHubService.askGuiForReadyMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleAskGuiForReady(response);
        });
        this.statusHubService.timerStatusMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleTimerStatus(response);
        });
        this.statusHubService.testProgressMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleTestProgressMessage(response);
        });
        this.statusHubService.rowsToSkipMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleStepsToSkip(response);
        });
        this.statusHubService.inputsStatusMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleInputsStatus(response);
        });
        this.statusHubService.showToleranceMetersMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleShowToleranceMeters(response);
        });
        this.statusHubService.updateToleranceMetersMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleUpdateToleranceMeters(response);
        });
        this.statusHubService.rowUpdateMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleRowUpdate(response);
        });
        this.statusHubService.askOperatorMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleAskOperator(response);
        });
        this.statusHubService.requestPriorityMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleRequestPriority(response);
        });
        this.statusHubService.releasePriorityMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleReleasePriority(response);
        });
        this.statusHubService.askGuiToClearRequestMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            await this.handleAskGuiToClearMessage(response);
        });
        this.statusHubService.showSpinnerMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            this.handleShowSpinner(response.spinnerVisible);
        });
        this.statusHubService.showProgressBarMessage$.pipe(takeUntil(this.componentDestroyed$)).subscribe(async (response) => {
            this.handleShowProgressBar(response);
        });
    }
    private handleShowSpinner(showSpinner: boolean): void {
        this.showRowSpinner = showSpinner;
    }

    private handleShowProgressBar(hiTestShowProgressBarMessage: IHiTestShowProgressBarMessage): void {
        this.handleAdditionalMessage(hiTestShowProgressBarMessage.additionalMessage);
        if (hiTestShowProgressBarMessage.progressBarVisible) {
            // The progress bar should be active..
            if (hiTestShowProgressBarMessage.determinate) {
                //.. in determinata mode
                this.showRowProgress = true;
                this.showRowProgressIndeterminate = false;
                this.progressValue = hiTestShowProgressBarMessage.progressPercent;
            } else {
                //.. in indeterminate mode
                this.showRowProgress = false;
                this.showRowProgressIndeterminate = true;
            }

        } else {
            // The progress bar should not be active
            if (this.showRowProgress || this.showRowProgressIndeterminate) {
                // If the progress bar has been activated, never hide it (just remove value).
                // Otherwise the content of the step will "jump" up and down when the progress comes and goes.
                this.showRowProgressIndeterminate = false;
                this.showRowProgress = true;
                this.progressValue = 0;
            }
        }
    }

    private showLoadingBoxSpinner(): void {
        this.loadingBoxText = $localize `:@@hitest.loading.test.sequence:Loading Test Sequence`;
        this.showLoadingBox = true;
    }

    private handleAdditionalMessage(message: string): void {
        if (!message) {
            return;
        }
        // TODO Improve this part of the code
        //  Now we look at additionalMessage to decide which kind the message is, this should be done in another way
        //  Needs more detailed information from backend
        //  HIAA-2817
        if (this.isErrorResponse(message)) {
            this.messageService.sendAlert(message);
        } else {
            this.messageService.sendMessage(message);
        }

        if (this.isApprovedResponse(message)) {
            this.approveMessage = message;
            this.showTestApproved = true;
        }
    }

    private async handleGenericMessage(message: IHiTestMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
    }

    private async handleDataUpdateMessage(message: IHiTestAskClientForDataUpdateMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);

        // Add an operator request to show the "Override"-button
        this.showLoadingBox = false;
        this.loadingBoxText = "";
        const operatorMessage = {
            identifier: message.identifier,
            additionalMessage: message.additionalMessage,
            askConfirmation: false,
            askRetry: false,
            askOverride: true,
            askFail: false,
            askStop: false,
            askValue: false,
            askPriority: false,
            askReleasePriority: false,
            expectedResponseType: OperatorResponseDataType.none,
            promptOperator: "",
            description: "",
            additionalInfo: "",
            stepId: this.getCurrentStepId(),
            responseValue: "",
            showAdditionalInfo: false,
            defaultValue: "",
        };

        this.askOperatorRequests.push(operatorMessage);
        await this.updateCurrentRequests(false);
        this.scrollStepsRowIntoView(this.getCurrentStepId());

        if (message.dataType === "ModulesSelectionResponse") {
            this.moduleSelectionRequest = {
                identifier: message.identifier,
                data: {
                    hiTestPerformedTestModules: message.data as moduleSelection[]
                }
            };

            const moduleData = message.data as modulesSelectionResponse;
            this.moduleSelectionViewModel = moduleData.hiTestPerformedTestModules.map(x => {
                const record: ModuleSelectionViewModel = {
                    id: x.id, moduleId: x.moduleId, moduleName: x.moduleName, status: x.status,
                    selected:
                        x.status === HiTestConstants.hiTestPerformedTestModuleStatus.selected
                        || x.status === HiTestConstants.hiTestPerformedTestModuleStatus.started,
                    canModify:
                        x.status === HiTestConstants.hiTestPerformedTestModuleStatus.selected
                        || x.status === HiTestConstants.hiTestPerformedTestModuleStatus.deselected
                        || x.status === HiTestConstants.hiTestPerformedTestModuleStatus.started,
                };

                if (!record.canModify) {
                    // Those that cannot be modified should be treated as selected (it applies to "Approved" steps)
                    record.selected = true;
                }

                return record;
            }
            );

            // Toggle should always start in "All enabled" position when list is presented
            this.enableAll = true;

            setTimeout(() => {
                // Target Ok button (to make life easier for operator)
                const element = (document.getElementsByClassName("module-selection-ok")[0]) as HTMLElement;
                if (element) {
                    element.focus();
                }
            }, 0);
        }

        if (message.dataType === "ParameterInitializationResponse") {
            const paramData = message.data as ParameterInitializationResponse;
            const canParameters: CalculatorValue[][] = [];
            for (const key in paramData.canParameters) {
                if (key) {
                    canParameters[key] = paramData.canParameters[key];
                }
            }

            this.parameterInitDataRequest = {
                identifier: message.identifier,
                data: {
                    omsProBuilderName: paramData.omsProBuilderName ?? "",
                    aliasProBuilderName: paramData.aliasProBuilderName ?? "",
                    includesDefaultParameters: paramData.includesDefaultParameters ?? false,
                    parameters: paramData.parameters,
                    canParameters: paramData.canParameters
                }
            };

            this.parameterInitViewModel = {
                omsProBuilderName: this.parameterInitDataRequest.data.omsProBuilderName,
                aliasProBuilderName: this.parameterInitDataRequest.data.aliasProBuilderName,
                includesDefaultParameters: this.parameterInitDataRequest.data.includesDefaultParameters,
                parameters: this.parameterInitDataRequest.data.parameters.map(x => ({ item: x, selected: true })),
                canParameters: canParameters.map(x => ({ items: x, selected: true }))
            };

            const canModuleParameters = this.parameterInitViewModel.canParameters.flatMap(x => x.items);
            for (const canModuleParameter of canModuleParameters) {
                canModuleParameter.selected = true;
            }
        }
    }

    private async handleShutdownMessage(message: IHiTestMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
    }

    private async handleAskGuiForReady(message: IHiTestMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        await this.hitestService.confirmStep(message.identifier);
    }

    private async handleTimerStatus(message: IHiTestTimerStatusMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        this.activeTimers = message.timerValues;
    }

    private async handleAskGuidToRestart(message: IHiTestGuidToRestartMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        this.testGuidToRestart = message.testGuidToRestart;
    }

    private async handleTestProgressMessage(message: IHiTestProgressMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        if (message.testIsRunning) {
            this.currentTestStatus = message;

            this.updateModuleStatusForSteps();
            await this.updateCurrentRequests(false);

            const activeStep = this.getCurrentStepId();
            if (this.lastActiveStep !== activeStep) {
                this.inputValues = [];
                this.scrollStepsRowIntoView(activeStep);
                this.showRowProgress = false;
                this.showRowProgressIndeterminate = false;
            }
            this.lastActiveStep = activeStep;
        } else {
            this.currentTestStatus.testIsRunning = false;
        }
        this.updateRowsToShow();
    }

    private updateModuleStatusForSteps(): void {
        this.testRows.forEach(x => {
            const moduleStatus = this.currentTestStatus?.
            performedTestModules?.
            find(y => y.moduleId === x.ModuleId)?.status;

            x.moduleDeselected =
                moduleStatus === HiTestConstants.hiTestPerformedTestModuleStatus.deselected;
            x.moduleFailed =
                moduleStatus === HiTestConstants.hiTestPerformedTestModuleStatus.failed;
        });
    }

    private async handleStepsToSkip(message: IHiTestRowsToSkipMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        await this.handleSortingDataAsync(message.rowIdsToSkip);
    }

    private async handleInputsStatus(message: IHiTestInputsStatusMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        this.inputValues = message.inputValues.map(x => {
            const inputValue: InputValue = Object.assign({}, x);
            let name = inputValue.name;
            name = name.startsWith("{") ? name.substring(1) : name;
            name = name.endsWith("}") ? name.substring(0, name.length - 1) : name;
            inputValue.name = name;
            return inputValue;
        });
    }

    private async handleUpdateToleranceMeters(message: IHiTestUpdateToleranceMetersMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        message.toleranceMeters.forEach(toleranceMeter => {
            const index = this.toleranceMeters?.findIndex(x => x.id === toleranceMeter.id);
            if (index >= 0) {
                // Meter is found
                if (JSON.stringify(this.toleranceMeters[index]) !== JSON.stringify(toleranceMeter)) {
                    // Meter values has changed, update!
                    this.toleranceMeters[index] = toleranceMeter;
                }
            }
        });
    }

    private async handleShowToleranceMeters(message: IHiTestShowToleranceMetersMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        this.toleranceMeters = message.toleranceMeters;
    }

    private async handleRowUpdate(message: IHiTestRowUpdateMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        console.log(message);
        this.replaceRowInstruction(message);
    }

    private async handleAskOperator(message: IHiTestAskOperatorMessage): Promise<void> {
        if (this.currentTestStatus){
            this.handleAdditionalMessage(message.additionalMessage);
            this.showLoadingBox = false;
            this.loadingBoxText = "";
            const askOperator = message;
            askOperator.stepId = this.getCurrentStepId();
            askOperator.responseValue = message.defaultValue;
            this.askFail = askOperator.askFail;
            this.askStop = askOperator.askStop;
            this.askOperatorRequests.push(askOperator);
            await this.updateCurrentRequests(false);
            this.scrollStepsRowIntoView(this.getCurrentStepId());
        } else {
             const dialogResult = await this.dialogService.showModalDialogAsync({
                content: message.description + "\n" + message.additionalInfo,
                confirmativeButton: ButtonType.retry,
                dismissiveButton: ButtonType.cancel,
            });

            if (dialogResult === DialogResult.confirm) {
                await this.hitestService.sendOperatorResponse(message.identifier, this.operatorResponseRetry, "");
            } else {
                await this.hitestService.sendOperatorResponse(message.identifier, this.operatorResponseFail, "");
            }

        }
    }

    private async handleRequestPriority(message: IHiTestMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        this.showLoadingBox = true;
        this.loadingBoxText = $localize `:@@hitest.create-backup-file:Creating backup file`;
        await this.pollingService.disablePollingAsync();
        await this.hitestService.confirmStep(message.identifier);
        this.pollProgress();
    }

    private async handleReleasePriority(message: IHiTestMessage): Promise<void> {
        this.handleAdditionalMessage(message.additionalMessage);
        this.loadingBoxText = "";
        this.showLoadingBox = false;
        await this.pollingService.enablePollingAsync();
        await this.hitestService.confirmStep(message.identifier);
    }

    private async handleAskGuiToClearMessage(message: IHiTestAskGuiToClearRequestMessage): Promise<void> {
        const foundRequest = this.askOperatorRequests.find(x => x.identifier === message.requestToClear);
        if (foundRequest) {
            this.askOperatorRequests = this.askOperatorRequests.filter(x => x.identifier !== message.requestToClear);
            await this.updateCurrentRequests(false);
            await this.hitestService.cancelOperatorRequests([message.requestToClear]);
        }
    }

    private replaceRowInstruction(rowUpdate: IHiTestRowUpdateMessage): void {
        const row = this.testRows.find(i => i.Id === rowUpdate.rowId);
        if (row !== undefined) {
            row.Instruction = rowUpdate.parsedInstruction;
            row.InstructionEs = rowUpdate.parsedInstruction;
            row.InstructionPl = rowUpdate.parsedInstruction;
            this.updateRowsToShow();
        }
    }

    /**
     * Hides the current step and shows the next
     * TODO: select next rowId in a generalised way instead of adding 1
     *
     * @param currentRowId Current step
     */
    private moveToNextStep(currentRowId: number): void {
        this.toggleShownDescription(currentRowId);
        this.toggleShownDescription(currentRowId + 1);
    }

    private hasComment(): boolean {
        return this.commentValue !== "";
    }

    public hideParameterInitModel(): void {
        this.parameterInitViewModel = undefined;
        this.parameterInitDataRequest = undefined;
    }


    private isErrorResponse(response: string): boolean {
        if (response.includes(HiTestConstants.performedTest.aborted)) {
            return true;
        }
        if (response.includes(HiTestConstants.performedTest.error)) {
            return true;
        }
        if (response.includes(HiTestConstants.performedTest.failed)) {
            return true;
        }
        return false;
    }

    private isApprovedResponse(response: string): boolean {
        if (response.includes(HiTestConstants.performedTest.approved)) {
            return true;
        }
        if (response.includes(HiTestConstants.performedTest.paused)) {
            return true;
        }
        return false;
    }

    // Set the operator focus to any object that should be preselected
    private setOperatorFocus(): void {
        // Priority 1: Manual input
        if (this.currentRequests.some(x => x.askValue)) {
            // Wait for DOM, and set focus
            setTimeout(() => {
                const element = (document.getElementsByClassName("manual-input")[0]?.firstChild?.firstChild) as HTMLElement;
                if (element) {
                    element.focus();
                }
            }, 0);
            return;
        }

        // Priority 2: Confirm button
        if (this.currentRequests.some(x => x.askConfirmation)) {
            // Wait for DOM, and set focus
            setTimeout(() => {
                const element = (document.getElementById("done-button")) as HTMLElement;
                if (element) {
                    element.focus();
                }
            }, 0);
            return;
        }
    }

    // Makes sure only one record (the last one) has the askOverride-flag set
    private ensureOnlyOneOverride(localCopy: IHiTestAskOperatorMessage[]): void {
        // Get all Override requests
        const overrideRequests = localCopy.filter(x => x.askOverride);

        // Remove/ignore the last Override request from the array
        overrideRequests.pop();

        // Set askOverride to false on all remaining items.
        overrideRequests.forEach(x => x.askOverride = false);
    }

    // Verifies that content of both arrays are exactly equal
    private isEqual(firstMessage: IHiTestAskOperatorMessage[], secondMessage: IHiTestAskOperatorMessage[]): boolean {
        return JSON.stringify(firstMessage) === JSON.stringify(secondMessage);
    }

    private moduleIdTracker(row: IHiTestModuleRowModel): boolean {
        const hasIdChanged = row.ModuleId !== this.lastModuleId;
        this.lastModuleId = row.ModuleId;
        return hasIdChanged;
    }

    private scrollStepsRowIntoView(rowId: number): void {
        if (this.operatorStepsComponent) {
            this.operatorStepsComponent.scrollRowIntoView(rowId);
        }
    }

    private pollProgress(): void {
        const timer = observableTimer(3000, 1000);
        const obj = timer.subscribe(async _ => {
            // When polling is allowed again, we should stop asking for progress messages
            if (PollingHandler.isPollingAllowed) {
                obj.unsubscribe();
            } else {
                const progress = await this.sacBuildService.getProgressAsync();
                if (progress !== undefined) {
                    this.darkboxMessage = progress.message;
                    this.darkboxPercent = "" + progress.progress + "%";
                } else {
                    this.darkboxMessage = $localize `:@@file.label.error-reading:Error reading progress`;
                }
            }
        });
    }
}
