import { Component, OnInit, Input } from "@angular/core";
import { ToleranceMeterDisplayModel, ToleranceMeterDto, } from "../../models/hitest-status-message.interface";

@Component({
    selector: "hic-hitest-tolerance-meter",
    templateUrl: "./hitest-tolerance-meter.component.html",
    styleUrls: ["./hitest-tolerance-meter.component.scss"]
})

export class HitestToleranceMeterComponent implements OnInit {
    // Graphical parameters
    public numberOfLeds = 20;
    public ledLeftMargin = 250;
    public ledTopMargin = 25;
    public ledSpacing = 10;
    public targetPosition = 345; // X position on screen for the target value
    private zoomFactor = 150;    // Number of procent extra shown above and under minOkValue and maxOkValue

    // Value related parameters
    @Input() public toleranceMeter: ToleranceMeterDto;
    public displayModel: ToleranceMeterDisplayModel;
    public leds: Led[] = [];
    public minValue = 0;    // Start value for scale
    public scaleSpan = 100; // Width of whole scale

    public constructor() { }

    public ngOnInit(): void {
        this.calculateScaling();
        this.skewLimits();
        this.initiateLeds();
        this.createDisplayModel();
    }

    public getLedClasses(led: Led): string {
        let classes = "hic-tolerance-led";

        if (led.leftValue <= this.toleranceMeter.value) {
            classes += " hic-led-active";
        }

        if (this.toleranceMeter.value) {
            if (led.leftValue >= this.toleranceMeter.valueMaxOk) {
                classes += " hic-led-red";
            } else
                if (led.leftValue >= this.toleranceMeter.valueMinOk) {
                    classes += " hic-led-green";
                } else {
                    classes += " hic-led-yellow";
                }
        } else {
            classes += " hic-led-grey";
        }
        if (this.showLedAsBig(led)) {
            classes += " hic-led-big";
        }

        return classes;
    }

    public getLedX(led: Led): number {
        if (this.showLedAsBig(led)) {
            return led.x-1;
        }
        return led.x;
    }

    public getLedY(led: Led): number {
        if (this.showLedAsBig(led)) {
            return led.y-2;
        }
        return led.y;
    }

    private showAsNiceText(number: number, desiredMaxLength: number = 5): string {
        let result = number?.toString()?.replace(",",".");
        if (!result) {
            return "";
        }

        if (result.includes(".")){
            while ((result.length > desiredMaxLength) && (!result.endsWith("."))) {
                result = result.slice(0,-1);
            }

            while (result.endsWith("0")) {
                result = result.slice(0,-1);
            }

            if (result.endsWith(".")) {
                result = result.slice(0,-1);
            }
        }
        return result;
    }

    private createDisplayModel(): void {
        this.displayModel = this.toleranceMeter as ToleranceMeterDisplayModel;
        this.displayModel.valueNice=this.showAsNiceText(this.displayModel.value);
        this.displayModel.valueMinOkNice = this.showAsNiceText(this.displayModel.valueMinOk);
        this.displayModel.valueMaxOkNice = this.showAsNiceText(this.displayModel.valueMaxOk);
        this.displayModel.valueTargetNice = this.showAsNiceText(this.displayModel.valueTarget);
    }

    private calculateScaling(): void {
        const minUsedPositiveNumber = 1;

        const biggestTolerance = Math.max(
            (this.toleranceMeter.valueTarget - this.toleranceMeter.valueMinOk),
            (this.toleranceMeter.valueMaxOk - this.toleranceMeter.valueTarget),
            minUsedPositiveNumber
        );

        let headroom = biggestTolerance * (this.zoomFactor / 100);

        // Headroom should always be a positive number
        headroom = Math.max(headroom, minUsedPositiveNumber);

        this.minValue =
            this.toleranceMeter.valueMinOk - headroom;

        const maxValue = this.toleranceMeter.valueMaxOk + headroom;

        this.scaleSpan = maxValue - this.minValue;
    }

    private showLedAsBig(led: Led): boolean {
        const currentValue = this.toleranceMeter.value >= led.leftValue && this.toleranceMeter.value <= led.nextLedLeftValue(this.leds);
        return led.targetLed || currentValue;
    }

    private initiateLeds(): void {
        for (let i = 0; i < this.numberOfLeds; i++) {
            const correspondingLeftValue = this.minValue + (i / this.numberOfLeds) * this.scaleSpan;
            const led = new Led();
            led.id = i;
            led.leftValue = correspondingLeftValue;
            led.x= this.ledLeftMargin + i * this.ledSpacing;
            led.y= this.ledTopMargin;

            this.leds.push(led);
        }
        this.skewLimits();
        this.markTargetLed();
    }

    private skewLimits(): void{
        const valueMinOk = this.toleranceMeter.valueMinOk;
        const minOkLedId = this.leds.findIndex(x => x.leftValue <= valueMinOk && x.nextLedLeftValue(this.leds) > valueMinOk);
        if (minOkLedId !== -1) {
            const skewLeft = valueMinOk - this.leds[minOkLedId].leftValue;
            const skewNextLeft = this.leds[minOkLedId].nextLedLeftValue(this.leds) - valueMinOk;
            if (skewLeft<=skewNextLeft){
                this.leds[minOkLedId].leftValue = valueMinOk;
            } else {
                this.leds[minOkLedId + 1].leftValue = valueMinOk;
            }
        }

        const valueMaxOk = this.toleranceMeter.valueMaxOk;
        const maxOkLedId = this.leds.findIndex(x => x.leftValue <= valueMaxOk && x.nextLedLeftValue(this.leds) > valueMaxOk);
        if (maxOkLedId !== -1) {
            const skewLeft = valueMaxOk - this.leds[maxOkLedId].leftValue;
            const skewNextLeft = this.leds[maxOkLedId].nextLedLeftValue(this.leds) - valueMaxOk;
            if (skewLeft<=skewNextLeft){
                this.leds[maxOkLedId].leftValue = valueMaxOk;
            } else {
                this.leds[maxOkLedId + 1].leftValue = valueMaxOk;
            }
        }
    }

    private markTargetLed(): void {
        this.leds.forEach(x => {
            x.targetLed =
                (x.leftValue <= this.toleranceMeter.valueTarget && x.nextLedLeftValue(this.leds) > this.toleranceMeter.valueTarget);
        });
    }
}

export class Led {
    public id: number;
    public x: number;
    public y: number;
    public targetLed = false;
    public leftValue: number;

    public nextLedLeftValue(leds: Led[]): number {
        return leds.find(x => x.id === this.id+1)?.leftValue;
    }
}
