import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})

export class ModuleColoringService {
// TODO HIAA-2454 Consolidate HiTest constants in frontend https://jira.shared.tds.cargotec.com/browse/HIAA-2454
    public readonly moduleColors: string[] = [
        "#375d5a",
        "#c2ad70",
        "#b96251",
        "#c0a9a4",
        "#4c695a",
        "#64b695",
        "#bc8b3f",
        "#1199c4",
        "#97abac"
    ];
    constructor() { }

    public getModuleColor(moduleId: number): string {
        return this.moduleColors[moduleId % this.moduleColors.length];
    }
}
