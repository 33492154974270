import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ToolboxModule } from "src/app/shared/toolbox/toolbox.module";
import { SharedInputModule } from "../shared/shared-input.module";
import { TestBenchRowComponent } from "./test-bench-configuration-row/test-bench-row.component";
import { TestBenchConfigurationComponent } from "./test-bench-configuration.component";
import { TestbenchEditorComponent } from "./test-bench-editor/testbench-editor.component";
import { TestbenchInterfaceComponent } from "./test-bench-editor/testbench-interface/testbench-interface.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ToolboxModule,
        SharedInputModule,
    ],
    declarations: [TestbenchInterfaceComponent,
        TestbenchEditorComponent,
        TestBenchConfigurationComponent,
        TestBenchRowComponent

    ],
    exports: [
        TestbenchInterfaceComponent,
        TestbenchEditorComponent,
        TestBenchConfigurationComponent,
        TestBenchRowComponent
    ]
})
export class TestBenchConfigurationModule { }
