import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChildren } from "@angular/core";
import { LocaleService } from "src/app/core/localisation/services/locale/locale.service";
import { HiTestService } from "src/app/hitest/services/hitest.service";
import { ModuleColoringService } from "src/app/hitest/services/module-coloring.service";
import { CalculatorValue, HiTestConstants, IHiTestModuleRowModel, InitParametersResponseDto, InitParametersViewModel, ITestRowViewModel } from "../../../models/hitest.model";
import { IHiTestAskOperatorMessage, IHiTestProgressMessage, operatorResponseDataType as OperatorResponseDataType } from "../../models/hitest-status-message.interface";
import { ContextMenuService } from "src/app/hitest/services/context-menu/context-menu.service";
import { Subject, takeUntil } from "rxjs";

@Component({
    selector: "hic-operatorsteps",
    templateUrl: "./operatorsteps.component.html",
    styleUrls: ["./operatorsteps.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperatorstepsComponent implements OnDestroy{

    @Input() public testRows: ITestRowViewModel[] = [];
    @Input() public currentTestStatus: IHiTestProgressMessage;
    @Input() public currentRequests: IHiTestAskOperatorMessage[];
    @Input() public shownDescriptions: number[] = [];
    @Input() public showRowProgress = false;
    @Input() public showRowProgressIndeterminate = false;
    @Input() public showRowSpinner = false;
    @Input() public progressValue = 0;
    @Input() public askOperatorRequests: IHiTestAskOperatorMessage[] = [];
    @Input() public operatorComment = "";
    @Input() public parameterInitViewModel: InitParametersViewModel;
    @Input() public parameterInitDataRequest: InitParametersResponseDto;
    @Input() public askFail = false;
    @Input() public askStop = false;
    @Input() private interactiveAllowed = false;
    @Output() public toggleShownDescriptionEvent = new EventEmitter<number>();
    @Output() public updateCurrentRequestsEvent = new EventEmitter<boolean>();
    @Output() public hideParameterInitModelEvent = new EventEmitter<void>();

    @ViewChildren("testrow", { read: ElementRef }) public testRowElements: QueryList<ElementRef>;

    private destroyed$ = new Subject<void>();
    private readonly operatorResponseUseValue = "usevalue";
    private readonly operatorResponseRetry = "retry";
    private readonly operatorResponseOverride = "override";
    private readonly operatorResponseFail = "fail";
    private readonly operatorResponseStop = "stop";

    public readonly testRowIdPrefix = "testrow-";
    public operatorResponseDataType = OperatorResponseDataType;

    public enterOverrideMessagePlaceholder: string = $localize `:@@hitest.test.enter-override-message:Enter override/fail message...`;


    constructor(
        public moduleColoringService: ModuleColoringService,
        public hitestService: HiTestService,
        private localeService: LocaleService,
        private contextMenuService: ContextMenuService,
    ) {
        this.localeService.localeChanged
            .pipe(takeUntil(this.destroyed$))
            .subscribe(async locale => await this.hitestService.localeChanged(locale));
     }

    public ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    public getModuleColor(moduleId: number): string {
        return this.moduleColoringService.getModuleColor(moduleId);
    }

    public getOverflowOffset(moduleName: string): number {
        // This gives a good enough approximation of pixels in the GUI.
        // We earlier used offset = Math.min(el.offsetWidth - el.scrollWidth, 0) which gives the correct value
        // but this is too expensive to calculate with many objects in DOM.
        const expectedMaxLength = 55;
        const expectedCharWidth = 5;
        let offset = 0;
        if (moduleName.length > expectedMaxLength) {
            offset = ((expectedMaxLength - moduleName.length) * expectedCharWidth);
        }
        return offset;
    }

    public toggleShownDescription(rowId: number): void {
        this.toggleShownDescriptionEvent.emit(rowId);
    }

    public isInShownDescriptions(internalStepNumber: number): boolean {
        let result = false;

        // Always show description field if this is current step (in running test) and any operator input is needed
        // or if operator has selected to show the description.
        result = (this.currentTestStatus?.testIsRunning
            && this.currentTestStatus?.currentActiveRowId === internalStepNumber
            && ((this.currentRequests.length > 0) || (this.showRowProgress) || (this.showRowProgressIndeterminate)))
            || this.shownDescriptions.includes(internalStepNumber);
        return result;
    }

    public getTranslatedTestRowName(row: IHiTestModuleRowModel): string {
        let translatedRowName = "";

        switch (this.localeService.selectedLocale) {
            case HiTestConstants.languageCodes.polish:
                translatedRowName = row.TestRowNamePl;
                break;

            case HiTestConstants.languageCodes.spanish:
                translatedRowName = row.TestRowNameEs;
                break;
        }

        // Always default to english
        return translatedRowName ? translatedRowName : row.TestRowName;
    }

    public getCurrentStepId(): number {
        if (this.currentTestStatus) {
            return this.currentTestStatus.currentActiveRowId;
        }
        return null;
    }

    public onRightClick(event: MouseEvent, row: ITestRowViewModel): void {
        if (this.interactiveAllowed === false) {
            return;
        }

        if (this.isTestStarted() === false) {
            return;
        }
        event.preventDefault();  // Prevent browser's default context menu from showing
        this.contextMenuService.x = event.clientX;
        this.contextMenuService.y = event.clientY;
        this.contextMenuService.row = row;
        const activeStepId = this.currentTestStatus?.currentActiveRowId;
        const activeModuleId = this.testRows.find(x => x.Id === activeStepId).ModuleId;

        if (row.ModuleId === activeModuleId) {
            this.contextMenuService.showContextMenuRow = true;
        } else {
            this.contextMenuService.showContextMenuModule = true;
        }
      }

      private checkIfApproved(index: number): boolean {
        if (this.currentTestStatus) {
            if (Array.isArray(this.currentTestStatus.rowStatuses)) {
                const currentStepStatus = this.currentTestStatus.rowStatuses.find(s => s.rowNo === index);
                if (currentStepStatus) {
                    return currentStepStatus.status === "Ok";
                }
            }
        }
        return false;
    }

    private checkIfCurrent(index: number): boolean {
        if (this.currentTestStatus) {
            if (Array.isArray(this.currentTestStatus.rowStatuses)) {
                const currentStepStatus = this.currentTestStatus.rowStatuses.find(s => s.rowNo === index);
                if (currentStepStatus) {
                    return currentStepStatus.status === "Active";
                }
            }
        }
        return false;
    }

    private checkIfOverridden(index: number): boolean {
        if (this.currentTestStatus) {
            if (Array.isArray(this.currentTestStatus.rowStatuses)) {
                const currentStepStatus = this.currentTestStatus.rowStatuses.find(s => s.rowNo === index);
                if (currentStepStatus) {
                    return currentStepStatus.status === "Overridden";
                }
            }
        }
        return false;
    }

    private checkIfDeselected(row: ITestRowViewModel): boolean {
        if (this.currentTestStatus) {
            return row.moduleDeselected;
        }
        return false;
    }

    private checkIfFailed(row: ITestRowViewModel): boolean {
        if (this.currentTestStatus) {
            return row.moduleFailed;
        }
        return false;
    }

    public getIconStyleClass(row: ITestRowViewModel): string {
        if (this.checkIfApproved(row.Id)) {
            return "row-status-checked";
        }
        if (this.checkIfCurrent(row.Id)) {
            return "row-status-running";
        }
        if (this.checkIfOverridden(row.Id)) {
            return "row-status-overridden";
        }
        if (this.checkIfDeselected(row)) {
            return "row-status-deselected";
        }
        if (this.checkIfFailed(row)) {
            return "row-status-failed";
        }
        return "";
    }

    public getRowInstruction(rowId: number): string {
        if (this.testRows === undefined) {
            return "";
        }
        const row = this.testRows.find(i => i.Id === rowId);
        if (row === undefined) {
            return "";
        }

        // We have a valid row, return the translated instruction
        return this.getTranslatedInstruction(row);
    }

    public getTranslatedInstruction(row: IHiTestModuleRowModel): string {
        let translatedInstruction = "";

        switch (this.localeService.selectedLocale) {
            case HiTestConstants.languageCodes.polish:
                translatedInstruction = row.InstructionPl;
                break;

            case HiTestConstants.languageCodes.spanish:
                translatedInstruction = row.InstructionEs;
                break;
        }

        // Always default to english
        return translatedInstruction ? translatedInstruction : row.Instruction;
    }

    public shouldAdditionalInfoBeVisible(request: IHiTestAskOperatorMessage): boolean {
        const result =
            (request.promptOperator || request.description)
            && request.additionalInfo
            && request.showAdditionalInfo;
        return result;
    }

    public getEnterCallback(request: IHiTestAskOperatorMessage): () => void {
        return () => this.sendValueResponse(request);
    }

    public async sendValueResponse(request: IHiTestAskOperatorMessage): Promise<void> {
        if (request.responseValue || request.responseValue === 0) {
            await this.hitestService.sendOperatorResponse(
                request.identifier, this.operatorResponseUseValue, request.responseValue.toString()
            );
            this.askOperatorRequests = this.askOperatorRequests.filter(x => x.identifier !== request.identifier);
            this.updateCurrentRequestsEvent.emit(false);
        }
    }

    public async retryStep(identifier: string): Promise<void> {
        await this.hitestService.sendOperatorResponse(identifier, this.operatorResponseRetry, this.operatorComment);
        this.askOperatorRequests = this.askOperatorRequests.filter(x => x.identifier !== identifier);
        this.operatorComment = "";
        this.updateCurrentRequestsEvent.emit(false);
    }

    public async sendUpdatedParameterData(identifier: string): Promise<void> {
        if (this.parameterInitDataRequest) {
            let dataToSend = this.parameterInitDataRequest;

            const hasUnselectedParameters = this.parameterInitViewModel.parameters.some(x => !x.selected);
            const hasUnselectedCanParameters = this.parameterInitViewModel.canParameters
                .flatMap(x => x.items)
                .some(canParameter => !canParameter.selected);

            if (hasUnselectedParameters || hasUnselectedCanParameters) {
                const canParameters: { [key: number]: CalculatorValue[] } = {};
                for (let index = 0; index < this.parameterInitViewModel.canParameters.length; index++) {
                    if (this.parameterInitViewModel.canParameters[index] &&
                        this.parameterInitViewModel.canParameters[index].selected &&
                        this.parameterInitViewModel.canParameters[index].items.some(x => x.selected)) {
                        canParameters[index] = this.parameterInitViewModel.canParameters[index].items.filter(x => x.selected);
                    }
                }

                dataToSend = {
                    identifier: this.parameterInitDataRequest.identifier,
                    data: {
                        omsProBuilderName: this.parameterInitDataRequest.data.omsProBuilderName,
                        aliasProBuilderName: this.parameterInitDataRequest.data.aliasProBuilderName,
                        includesDefaultParameters: this.parameterInitDataRequest.data.includesDefaultParameters,
                        parameters: this.parameterInitViewModel.parameters.filter(x => x.selected).map(x => x.item),
                        canParameters: canParameters
                    }
                };
            }

            await this.hitestService.sendInitParametersResponse(dataToSend);
            this.askOperatorRequests = this.askOperatorRequests.filter(x => x.identifier !== identifier);
            this.hideParameterInitModelEvent.emit();
        } else {
            throw new Error("There is no data present to be updated");
        }
    }

    public async overrideStep(identifier: string): Promise<void> {
        await this.sendOperatorResponse(identifier, this.operatorResponseOverride);
    }

    private async sendOperatorResponse(identifier: string, response: string): Promise<void> {
        if (!this.operatorComment) {
            return;
        }
        await this.hitestService.sendOperatorResponse(identifier, response, this.operatorComment);
        this.askOperatorRequests = this.askOperatorRequests.filter(x => x.identifier !== identifier);
        this.operatorComment = "";
        this.hideParameterInitModelEvent.emit();
        this.updateCurrentRequestsEvent.emit(true);
    }

    public async failStep(identifier: string): Promise<void> {
        await this.sendOperatorResponse(identifier, this.operatorResponseFail);
    }

    public async stopStep(identifier: string): Promise<void> {
        this.operatorComment = "Stop pressed";
        await this.sendOperatorResponse(identifier, this.operatorResponseStop);
    }

    public async confirmStep(identifier: string): Promise<void> {
        await this.hitestService.confirmStep(identifier);
    }

    public scrollRowIntoView(rowId: number): void {
        const soughtId = this.testRowIdPrefix + rowId;
        const el = this.testRowElements.find(x => x.nativeElement.id === soughtId)?.nativeElement as HTMLElement;
        setTimeout(() => el?.scrollIntoView({ behavior: "smooth", block: "start" }), 0);
    }

    private isTestStarted(): boolean {
        if (this.currentTestStatus) {
            return this.currentTestStatus.testIsRunning && this.currentTestStatus.testStatus === "Started";
        }
        return false;
    }

}
