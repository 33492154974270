import { HiTestConstants, IHiTestModuleModel, IHiTestModuleRowModel } from "../../models/hitest.model";
import { Restrictions } from "./restrictions.model";
import { RowCluster } from "./row-cluster.model";

abstract class FailureType {}
export class RestrictionLockFailureType extends FailureType {}

export class SortingError extends Error {
    public static readonly restrictionLockFailure = RestrictionLockFailureType;
    private __proto__;
    constructor(message: string, public failureType: FailureType) {
        super(message);

        // restore prototype chain
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) {
 Object.setPrototypeOf(this, actualProto);
        } else {
            this.__proto__ = actualProto;
        }
    }
}
/**
 * Contains tools to help with sorting of rows and clusters of rows.
 */
export class SortingTool {
    private readonly placeholderModuleId = -999999;

    /**
     * Finds the last index in the provided array matching the provided predicate.
     *
     * @param array The array in which to find the index.
     * @param predicate findLastIndex calls the predicate once for each element of the array,
     * in descending order, until it finds one where predicate returns true.
     * If such an element is found, findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
     */
    public findLastIndex<T>(array: T[], predicate: (value: T, index: number, arr: T[]) => boolean): number {
        for (let i = array.length - 1; i > 0; i--) {
            if (predicate(array[i], i, array) === true) {
                return i;
            }
        }
        return -1;
    }

    /**
     * Replaces rows with same ID in the original rows array with rows from the provided module.
     * Assumes that originalRows contain all row-IDs in the provided module. If not an error will be thrown.
     *
     * @param module an IHitestModuleModel instance containing the new rows.
     * @param originalRows an array of IHitestModuleRowModel containing rows that should be substituted.
     * @returns A possibly updated array of IHitestModuleRowModel rows.
     */
    public substituteDirectMatch(module: IHiTestModuleModel, originalRows: IHiTestModuleRowModel[]): IHiTestModuleRowModel[] {
        if (!originalRows.some(x => x.ModuleId === module.Id)) {
            return originalRows;
        }

        const rowsCopy = originalRows.slice();
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < module.HiTestModuleRows.length; i++) {
            const newRow = module.HiTestModuleRows[i];
            const index = rowsCopy.findIndex(row => row.Id === newRow.Id);
            if (index >= 0) {
                rowsCopy[index] = newRow;
            }
        }
        return rowsCopy;
    }

    /**
     * Substitutes rows in a global list of test rows with rows from the provided module.
     * Takes the sorting order of the original array of rows, from originalModule, into consideration
     * and tries to match that order as closely as possible.
     *
     * @param module A module containing updated rows
     * @param originalModule Original module to be compared with
     * @param testRows A set of all available rows, regardless of module belonging.
     */
    public substituteWithSortingChanges(
        module: IHiTestModuleModel,
        originalModule: IHiTestModuleModel,
        testRows: IHiTestModuleRowModel[]
    ): IHiTestModuleRowModel[] {
        if (!testRows.some(x => x.ModuleId === module.Id)) {
            return testRows;
        }

        let testRowsCopy: IHiTestModuleRowModel[] = testRows.slice();
        const oldClusters = RowCluster.extractClustersFromModule(originalModule, true);
        const newClusters = RowCluster.extractClustersFromModule(module, true);

        // Replace first row per cluster with a placeholder.
        for (const oldCluster of oldClusters) {
            const index = testRowsCopy.findIndex(row => row.Id === oldCluster.rows[0].Id);
            if (index >= 0) {
                testRowsCopy[index] = {ModuleId: this.placeholderModuleId} as unknown as IHiTestModuleRowModel;
            }
        }

        // Delete all remaining rows belong to the module.
        testRowsCopy = testRowsCopy.filter(row => row.ModuleId !== module.Id);

        // Case - Fewer clusters in the updated module.
        // Action - Delete placeholders in descending order until counts are equal.
        if (newClusters.length < oldClusters.length) {
            this.removePlaceholdersToMatchLength(newClusters, testRowsCopy);
        }

        // Case - More clusters in the updated module.
        // Action - Add placeholders directly after last placeholder until counts are equal.
        if (newClusters.length > oldClusters.length) {
            this.addPlaceholdersToMatchLength(newClusters, testRowsCopy);
        }

        // Now we should have the correct amount of placeholders to insert the new clusters.
        const tempTestRows: IHiTestModuleRowModel[] = this.rebuildTestRows(testRowsCopy, module, newClusters);
        return tempTestRows;
    }

    /**
     * Tries to find out if sorting changes has been done in provided module's rows vs oldRows.
     *
     * @param module An updated module.
     * @param originalRows An original array of rows to compare with.
     */
    public detectSortingChanges(
        module: IHiTestModuleModel,
        originalModule: IHiTestModuleModel
    ): boolean {
        // Find out if the number of rows has been altered
        let isAltered = originalModule.HiTestModuleRows.length !== module.HiTestModuleRows.length;

        // Find out if the amount of clusters has been altered.
        if (!isAltered) {
            const oldClusters = RowCluster.extractClustersFromModule(originalModule, true);
            const newClusters = RowCluster.extractClustersFromModule(module, true);
            isAltered = JSON.stringify(oldClusters) !== JSON.stringify(newClusters);
        }

        // Find out if the order of rows has been altered.
        if (!isAltered) {
            for (let i = 0; i < module.HiTestModuleRows.length; i++) {
                if (module.HiTestModuleRows[i].Id !== originalModule.HiTestModuleRows[i].Id) {
                    isAltered = true;
                    break;
                }
            }
        }

        return isAltered;
    }

    /**
     * Returns an updated version of the provided testRows array. Does not affect original array.
     * Insertion will be either last in the array or (if that is not possible due to module restrictions)
     * at the lastmost position possible.
     *
     * @param module The module containing changes.
     * @param modules All modules possibly affecting restrictions of provided module.
     * @param testRows An array consisting the original set of all test rows, regardless of module belonging.
     * @returns An updated array of rows.
     */
     public addModule(
        module: IHiTestModuleModel,
        modules: IHiTestModuleModel[],
        testRows: IHiTestModuleRowModel[]
    ): IHiTestModuleRowModel[] {
        const wantedStartIndex = testRows.length;
        const newRows = testRows.filter(row => row.ModuleId !== module.Id);
        const index = this.findSuitableIndex(module, wantedStartIndex, modules, newRows);
        if (module.UnbreakableModule === HiTestConstants.db.true && module.HiTestModuleRows.length >= 1) {
                newRows.splice(index, 0, ...module.HiTestModuleRows.slice(0,1));
        } else {
            newRows.splice(index, 0, ...module.HiTestModuleRows);
        }
        return newRows;
    }

    /**
     * Returns an updated version of the provided testRows array. Does not affect original array.
     * Removes all occurences of rows having the same module ID as the provided module from the
     * provided testRows array and inserts all rows from the module in non broken sequence.
     * Insertion will be either at same place as the first removed row or (if that is not
     * possible due to module restrictions) at the lastmost position possible.
     *
     * @param module The module containing changes.
     * @param modules All modules possibly affecting restrictions of provided module.
     * @param testRows An array consisting the original set of all test rows, regardless of module belonging.
     * @returns An updated array of rows.
     */
    public sortModuleRowsBasedOnRestrictions(
        module: IHiTestModuleModel,
        modules: IHiTestModuleModel[],
        testRows: IHiTestModuleRowModel[]
    ): IHiTestModuleRowModel[] {
        if (!testRows.some(x => x.ModuleId === module.Id)) {
            return testRows;
        }
        const wantedStartIndex = testRows.findIndex(row => row.ModuleId === module.Id);
        const newRows = testRows.filter(row => row.ModuleId !== module.Id);
        const index = this.findSuitableIndex(module, wantedStartIndex, modules, newRows);
        newRows.splice(index, 0, ...module.HiTestModuleRows);
        return newRows;
    }

    /**
     * Checks if any changes to restrictions has been made between newModule and oldModule.
     *
     * @param newModule The new module
     * @param originalModule The original module
     * @returns true if restriction changes was found, else false.
     */
    public detectRestrictionChanges(newModule: IHiTestModuleModel, originalModule: IHiTestModuleModel): boolean {
        let restrictionsAltered =
            newModule.HiTestModuleRestrictionModules.length !== originalModule.HiTestModuleRestrictionModules.length;
        if (!restrictionsAltered) {
            for (const newRestriction of newModule.HiTestModuleRestrictionModules) {
                const oldRestriction = originalModule.HiTestModuleRestrictionModules
                    .find(x => x.ReferencingModuleId === newRestriction.ReferencingModuleId);
                if (!oldRestriction) {
                    restrictionsAltered = true;
                    break;
                }
                if (oldRestriction.Restriction !== newRestriction.Restriction) {
                    restrictionsAltered = true;
                    break;
                }
            }
        }
        return restrictionsAltered;
    }

    private findSuitableIndex(
        module: IHiTestModuleModel,
        wantedIndex: number,
        modules: IHiTestModuleModel[],
        testRows: IHiTestModuleRowModel[]
    ): number {
        const restrictions = Restrictions.create(module, modules);
        let firstPossible = this.findLastIndex(testRows, row => !restrictions.allowBefore(row.ModuleId)) + 1;
        firstPossible = firstPossible >= 0 ? firstPossible : 0;

        let lastPossible = testRows.findIndex(row => !restrictions.allowAfter(row.ModuleId));
        lastPossible = lastPossible >= 0 ? lastPossible : testRows.length;

        if (firstPossible > lastPossible) {
            throw new SortingError(
                "Test rows are not correctly sorted or has two-way locking restrictions!",
                new RestrictionLockFailureType());
        }
        const suitableIndex = wantedIndex >= firstPossible && wantedIndex <= lastPossible ? wantedIndex : lastPossible;
        return suitableIndex;
    }

    private rebuildTestRows(
        testRowsCopy: IHiTestModuleRowModel[],
        module: IHiTestModuleModel,
        newClusters: RowCluster[]
    ): IHiTestModuleRowModel[] {
        let counter = 0;
        let clusterCounter = 0;
        const tempTestRows: IHiTestModuleRowModel[] = [];
        for (const testRow of testRowsCopy) {
            if (testRow.ModuleId === this.placeholderModuleId) {
                tempTestRows.push(...newClusters[clusterCounter].rows);
                clusterCounter++;
            } else {
                tempTestRows.push(testRow);
            }
            counter++;
        }
        return tempTestRows;
    }

    private removePlaceholdersToMatchLength(
        newClusters: RowCluster[],
        testRowsCopy: IHiTestModuleRowModel[]
    ): void {
        let count = testRowsCopy.filter(x => x.ModuleId === this.placeholderModuleId).length;
        while (count > newClusters.length) {
            const idx = this.findLastIndex(testRowsCopy, row => row.ModuleId === this.placeholderModuleId);
            if (idx >= 0) {
                testRowsCopy.splice(idx, 1);
                count--;
            } else {
                break;
            }
        }
    }

    private addPlaceholdersToMatchLength(
        newClusters: RowCluster[],
        testRowsCopy: IHiTestModuleRowModel[]
    ): void {
        const placeholderRow = { ModuleId: this.placeholderModuleId } as unknown as IHiTestModuleRowModel;
        let count = testRowsCopy.filter(x => x.ModuleId === this.placeholderModuleId).length;
        let lastIndex = this.findLastIndex(testRowsCopy, (row) => row.ModuleId === this.placeholderModuleId);
        while (count < newClusters.length) {
            testRowsCopy.splice(lastIndex + 1, 0, placeholderRow);
            lastIndex++;
            count++;
        }
    }
}
