import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SecurityModule } from "src/app/core/security/security.module";
import { RoutingModule } from "../routing/routing.module";
import { RouteAllowedGuard } from "src/app/core/security/shared/guards/route-allowed.guard";
import { HiTestEquipmentSelectionComponent } from "./operator/components/equipment-selection/equipment-selection.component";
import { HitestOperatorComponent } from "./operator/hitestoperator.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";

const routes: Routes = [
    { path: "equipment-selection", component: HiTestEquipmentSelectionComponent, canActivate: [ RouteAllowedGuard ] },
    { path: "operator", component: HitestOperatorComponent, canActivate: [ RouteAllowedGuard ] },
];

@NgModule({
    imports: [
        SecurityModule,
        RoutingModule,
        MatSnackBarModule,
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class HiTestRoutingModule { }
