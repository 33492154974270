import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { ICraneEquipmentDTO, IPreviousCompletedTest } from "src/app/hitest/models/hitest.model";
import { Subject } from "rxjs";
import { HiTestService } from "src/app/hitest/services/hitest.service";
import { IDialogOptions } from "src/app/core/dialogs/models/dialog-options.interface";
import { ButtonType } from "src/app/core/dialogs/models/button-type.enum";
import { DialogService } from "src/app/core/dialogs/services/dialog.service";
import { DialogResult } from "src/app/core/dialogs/models/dialog-result.enum";

@Component({
    selector: "hic-load-order",
    templateUrl: "./load-order.component.html",
    styleUrls: ["./load-order.component.scss"]
})

export class HitestLoadOrderComponent implements OnInit, OnDestroy {
    @Output() public loadButtonPressed = new EventEmitter<string>();
    @Output() public previousTestsVisible = new EventEmitter<boolean>();
    @Input() public showRobotModeOffline = false;
    @Input() public completedTests: IPreviousCompletedTest[] = undefined;

    public orderToLoad = "";
    public craneModel = "";
    public craneType = "";
    public craneSerial = "";
    public orderType = "";
    public itemId = "";
    public isOrderLoaded = false;
    public craneEquipment: ICraneEquipmentDTO;
    public orderNumber: string;
    public previouslyPerformedTestsTooltip = $localize `There are previously performed tests for this order`;

    private componentDestroyed$: Subject<boolean> = new Subject<boolean>();


    constructor(
        public hiTestService: HiTestService,
        public dialogService: DialogService
    ) { }


    ngOnInit(): void {
        this.hiTestService.craneEquipmentReady$.pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
            if (result) {
                this.craneEquipment = this.hiTestService.craneEquipment;
                this.craneModel = this.hiTestService.craneEquipment.craneModel;
                this.craneSerial = this.hiTestService.craneEquipment.craneSerialNumber;
                this.craneType = this.hiTestService.craneEquipment.craneType;
                this.orderType = this.hiTestService.craneEquipment.orderType;
                this.itemId = this.hiTestService.craneEquipment.itemId;
                if (this.hiTestService.craneEquipment.orderNumber !== "") {
                    this.orderNumber = this.hiTestService.craneEquipment.orderNumber;
                    this.orderToLoad = "";
                    this.isOrderLoaded = true;
                }
            }
        });

        ((document.getElementById("input-component")?.firstChild?.firstChild) as HTMLElement)?.focus();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    public showPreviousTests(): void {
        this.previousTestsVisible.emit(true);
    }

    public loadOrder(): void {
        this.isOrderLoaded = false;
        this.hiTestService.robotMode = false;
        this.hiTestService.robotModeButtonEnabled = true;
        this.loadButtonPressed.emit(this.orderToLoad);
    }

    public async toggleRobotMode(): Promise<void> {
        if (this.hiTestService.robotModeButtonEnabled) {
            this.hiTestService.robotMode = !this.hiTestService.robotMode;
            await this.robotModeChange();
        }
    }

    public async robotModeChange(): Promise<void> {
        if (this.hiTestService.robotMode) {
            const dialogOptions: IDialogOptions = {
                title: "Confirm \"Robot mode\"",
                content: "Confirm that the test should be run in robot mode.\ni.e. No interaction with test operator.",
                confirmativeButton: ButtonType.yes,
                dismissiveButton: ButtonType.no
            };

            const dialogResult = await this.dialogService.showModalDialogAsync(dialogOptions);
            if (dialogResult !== DialogResult.confirm) {
                this.hiTestService.robotMode = false;
            }
        }

        const robotModeCode = "#RobotMode";
        const existingEntry = this.hiTestService.craneEquipment.pS2000Codes.indexOf(robotModeCode);

        if (existingEntry !== -1) {
            this.hiTestService.craneEquipment.pS2000Codes.splice(existingEntry, 1);
        }

        if (this.hiTestService.robotMode) {
            this.hiTestService.craneEquipment.pS2000Codes = [robotModeCode].concat(this.hiTestService.craneEquipment.pS2000Codes);
        }
    }
}
