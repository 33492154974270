import { takeUntil } from "rxjs/operators";
import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { HiTestConstants } from "src/app/hitest/models/hitest.model";
import { HiTestBenchDTO, IHiTestLocation } from "../../models/hitestinputs.model";
import { TestBenchConnections } from "./../../models/hitestinputs.model";
import { Subject } from "rxjs";
import { ArraySortPipe } from "src/app/shared/toolbox/sorting/array-sort.pipe";
import { HiTestInputsService } from "../services/hitestinputs.service";

@Component({
    selector: "hic-test-bench-configuration",
    templateUrl: "./test-bench-configuration.component.html",
    styleUrls: ["../../admin/hitest-admin.scss", "./test-bench-configuration.component.scss"],
    providers: [ArraySortPipe]
})
export class TestBenchConfigurationComponent implements OnInit, OnDestroy, OnChanges {


    @Input() public selectedLocationId: number;
    @Input() public userLocation: IHiTestLocation;

    public showEditor = false;

    public message = "";
    public showAlert = false;

    public testBenches: HiTestBenchDTO[] = [];
    public selectedTestBench: HiTestBenchDTO;

    public maxCan = 0;
    private maxPlc = 0;

    public sources = HiTestConstants.sensorInterfaces;

    public headers = {
        can: "CAN Name",
        plc: "PLC Name"
    };

    public canHeaders: string[];
    public plcHeaders: string[];
    public columns = new TestBenchConnections();

    private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

    constructor(private hitestInputsService: HiTestInputsService) {
    }

    public async ngOnChanges(): Promise<void> {
        await this.loadTable();
    }

    async ngOnInit(): Promise<void> {
        this.hitestInputsService.testBenches$.pipe(takeUntil(this.componentDestroyed$)).subscribe(benches =>
            this.testBenches = benches);
        await this.loadTable();
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next(true);
    }

    public openConfig(item: HiTestBenchDTO): void {
        this.showEditor = !this.showEditor;
        this.selectedTestBench = item;
        if (this.selectedTestBench.locationId === 0) {
            this.selectedTestBench.locationId = this.selectedLocationId;
        }
    }

    public async loadTable(): Promise<void> {
        if (this.selectedLocationId) {
            await this.hitestInputsService.getTestBenchesAsync(this.selectedLocationId);
        } else {
            console.error("No location Id provided");
        }
        this.createTable(this.testBenches);
        this.showEditor = false;
    }

    public deleteTestBenchConfig(item: HiTestBenchDTO): void {
        if (item !== undefined) {
            this.message = `Do you really want to remove entire row '${item.name}'?`;
            this.showAlert = true;
            this.selectedTestBench = item;
            return;
        }
    }

    public removeAnswer(answer: boolean): void {
        if (answer === true) {
            this.deleteTestBench(this.selectedTestBench);
        }
    }

    public closeAlert(): void {
        this.showAlert = false;
    }

    public changedConfigRow(changedTestBench: HiTestBenchDTO): void {
        this.updateTestBench(changedTestBench);
        return;
    }

    public close(): void {
        this.showEditor = false;
        this.loadTable();
    }

    public addNew(): void {
        this.showEditor = !this.showEditor;

        const item: HiTestBenchDTO = {
            id: 0,
            locationId: this.selectedLocationId,
            name: "",
            interfaces:
                [{
                    id: "",
                    source: "",
                    name: "",
                    speed: "",
                    address: "",
                    port: 0,
                }]
        };

        this.selectedTestBench = item;
    }

    public async deleteTestBench(item: HiTestBenchDTO): Promise<void> {
        if (item !== undefined) {
            this.selectedTestBench = item;
            await this.hitestInputsService.deleteTestBenchAsync(item.id, this.selectedLocationId);
            this.loadTable();
        }
    }

    private async updateTestBench(testBench: HiTestBenchDTO): Promise<void> {
        await this.hitestInputsService.updateTestBenchAsync(testBench, this.selectedLocationId);
        this.loadTable();
    }

    private createTable(data: HiTestBenchDTO[]): void {
        this.countInterfaces(this.sources.can, data);
        this.countInterfaces(this.sources.plc, data);
        this.createColumns();
    }

    private countInterfaces(source: string, data: HiTestBenchDTO[]): void {
        let max = 1;
        data?.forEach(testBench => {
            const interfaceType = testBench.interfaces.filter(x => x.source === source);
            if (interfaceType.length > max) {
                max = interfaceType.length;
            }
        });

        if (source === this.sources.can) {
            this.maxCan = max;
        }

        if (source === this.sources.plc) {
            this.maxPlc = max;
        }
    }

    private createColumns(): void {
        this.canHeaders = new Array(this.maxCan).fill(this.headers.can);
        this.plcHeaders = new Array(this.maxPlc).fill(this.headers.plc);

        this.columns.can = new Array(this.maxCan).fill(" - ");
        this.columns.plc = new Array(this.maxPlc).fill(" - ");
    }
}
