<!-- Live testing values -->
<div *ngIf="!displayAsMiniDisplay">
    <div class="editor-container">
        <div class="hic-criterias-area">
             <div class="criterias-title" header i18n="@@hitest-operator.testing.values">Testing Values</div>
            <div class="criterias-table">
                <ng-container *ngFor="let toleranceMeter of toleranceMeters;">
                    <div class="tolerance-meter-container">
                        <hic-hitest-tolerance-meter [toleranceMeter]="toleranceMeter"></hic-hitest-tolerance-meter>
                    </div>
                </ng-container>
                <ng-container *ngFor="let timer of activeTimers; trackBy: timerTrack">
                    <div class="single-condition-container">
                        <div class="condition-name">{{ timer.name }}</div>
                        <div class="condition-value"> {{ timer.value.toFixed(1) }}</div>
                        <div class="condition-gradient-tolerance">[Placeholder for tolerance-meter]</div>
                        <div class="condition-unit"> s</div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let condition of testConditions; trackBy: conditionTrack">
                    <div class="single-condition-container">

                        <div class="condition-name">{{ condition.name }}</div>
                        <div class="condition-value"> {{ condition.currentValue }}</div>
                        <div class="condition-gradient-tolerance">[Placeholder for tolerance-meter]</div>
                        <div class="condition-unit"> {{ condition.unit }}</div>
                    </div>
                </ng-container>

                <div *ngIf="inputValues.length" class="single-status-container">
                    <div class="status-name header" i18n="@@hitest-operator.testing.name-header">Name</div>
                    <div class="status-value header" i18n="@@hitest-operator.testing.value-header">Value</div>
                    <div class="status-unit header" i18n="@@hitest-operator.testing.unit-header">Unit</div>
                </div>

                <ng-container *ngFor="let status of inputValues; trackBy: inputTrack">
                    <div class="single-status-container">
                        <div class="status-name">{{status.name}}</div>
                        <div class="status-value">{{status.value}}</div>
                        <div class="status-unit">{{status.unit}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<!-- When displayed as a mini display -->
<div *ngIf="displayAsMiniDisplay">
    <div class="hic-mini-criterias-table">

        <ng-container *ngFor="let c of testConditions">

                <div class="single-condition-container" [ngClass]="{
                    'hitest-criteria-state-pass': c.approveState === 'pass',
                    'hitest-criteria-state-awaiting': c.approveState === 'awaiting',
                    'hitest-criteria-state-fail': c.approveState === 'fail'}">
                    <div class="condition-name">{{ c.name }}</div>
                    <mat-icon *ngIf="current >= min && current <= max">arrow_upward</mat-icon>
                    <mat-icon *ngIf="current < min">arrow_downward</mat-icon>
                    <div class="condition-value"> {{ c.currentValue }}</div>
                    {{ c.unit }}
                </div>

        </ng-container>
    </div>
</div>

