import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { ButtonType } from "src/app/core/dialogs/models/button-type.enum";
import { IDialogOptions } from "src/app/core/dialogs/models/dialog-options.interface";
import { DialogResult } from "src/app/core/dialogs/models/dialog-result.enum";
import { DialogService } from "src/app/core/dialogs/services/dialog.service";
import { HiTestMessageService } from "src/app/hitest/services/message/hitest-message.service";
import { ITestRowViewModel } from "src/app/hitest/models/hitest.model";
import { ContextMenuService } from "src/app/hitest/services/context-menu/context-menu.service";
import { HiTestService } from "src/app/hitest/services/hitest.service";

@Component({
    selector: "hic-context-menu",
    templateUrl: "./context-menu.component.html",
    styleUrls: ["./context-menu.component.scss"],
})

export class ContextMenuComponent implements OnInit, OnDestroy {
    private clickOutsideListener: () => void;

    constructor(
        public contextMenuService: ContextMenuService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private dialogService: DialogService,
        private hitestService: HiTestService,
        private messageService: HiTestMessageService) { }

    ngOnInit(): void {
        this.clickOutsideListener = this.renderer.listen(document, "click", this.onClickOutside.bind(this));
    }

    ngOnDestroy(): void {
        this.clickOutsideListener();  // Remove the event listener
    }

    public async selectEntry(row: ITestRowViewModel): Promise<void> {
        const dialogOptions: IDialogOptions = {
            title: $localize`Confirm jump to step.`,
            content: this.contextMenuService.showContextMenuRow ?
            $localize`Confirm that You intend continue execution with step \"${row.TestRowName}\".`
                : $localize`Confirm that You intend continue execution with first step in module ${row.ModuleId}.`,
            confirmativeButton: ButtonType.yes,
            dismissiveButton: ButtonType.no
        };

        const dialogResult = await this.dialogService.showModalDialogAsync(dialogOptions);
        if (dialogResult === DialogResult.confirm) {
            const result = await this.hitestService.setNextRow(row.Id);
            if (result === false) {
                this.messageService.sendAlert($localize `Could not jump to step!`);
            }
        }

        this.closeContextMenu();
    }

    public closeContextMenu(): void {
        this.contextMenuService.showContextMenuRow = false;
        this.contextMenuService.showContextMenuModule = false;
    }

    private onClickOutside(event: Event): void {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            // Clicked outside, close the context menu
            this.closeContextMenu();
        }
    }
}
