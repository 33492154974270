import { HiTestConstants } from "src/app/hitest/models/hitest.model";
import { HiTestInputDTO } from "src/app/hitest/models/hitestinputs.model";
import { DynamicInputValidationStatus } from "./dynamic-input-validation-status.enum";

export class DynamicExpressionModifier {
    public static valueTypeConstants = {
        none: "none",
        numeric: "numeric",
        input: "input",
        source: "source",
    };

    public static valueTypes = [
        DynamicExpressionModifier.valueTypeConstants.numeric,
        DynamicExpressionModifier.valueTypeConstants.input,
        DynamicExpressionModifier.valueTypeConstants.source
    ];

    private methodNames = Object.keys(HiTestConstants.modifierNames).map<string>(x => HiTestConstants.modifierNames[x].toString());

    public method: string;
    public valueType: string = DynamicExpressionModifier.valueTypeConstants.none;
    public valueNumeric: number;
    public valueInput: string;

    private _inputObject: HiTestInputDTO;
    public get inputObject(): HiTestInputDTO {
        return this._inputObject;
    }
    public set inputObject(value: HiTestInputDTO) {
        this._inputObject = value;
        if (value) {
            this.valueInput = value.inputName;
        }
    }

    public static fromExpression(expression: string, availableInputs: HiTestInputDTO[]): DynamicExpressionModifier[] {
        if (expression === `${HiTestConstants.modifierNames.toNumber}($0)`) {
            return [];
        }

        const result: DynamicExpressionModifier[] = [];
        const methodRegex = /^(?<methodname>.*?)\((?<parameters>.*)\)$/;
        const inputRegex = /^\{(?<input>.*)\}$/;
        const valueRegex = /^(?<value>\d+)$/;
        const startValueRegex = /^(?<startValue>\$0)$/;
        let workingCopy = expression;
        let isMatch = true;
        let iterationCounter = 0;
        const failsafe = 1000;
        while (++iterationCounter) {
            if (iterationCounter > failsafe) {
                throw new Error("Infinite loop detected");
            }

            workingCopy = workingCopy.replace(/\s/g, "");
            const match = workingCopy.match(methodRegex);
            if (!match) {
                isMatch = false;
                break;
            }

            const dynExpressionModifier = new DynamicExpressionModifier();
            dynExpressionModifier.method = match.groups["methodname"];
            dynExpressionModifier.valueType = DynamicExpressionModifier.valueTypeConstants.none;

            const inner = match.groups["parameters"];
            const parameters = DynamicExpressionModifier.splitParameters(inner).map(x => x.trim());
            let nextMethod = "";
            for (const parameter of parameters) {
                if (methodRegex.test(parameter)) {
                    nextMethod = parameter;
                } else if (inputRegex.test(parameter)) {
                    dynExpressionModifier.valueInput = parameter.replace("{", "").replace("}", "");
                    dynExpressionModifier.valueType = DynamicExpressionModifier.valueTypeConstants.input;
                    dynExpressionModifier._inputObject = availableInputs.find(x => x.inputName === dynExpressionModifier.valueInput);
                } else if (valueRegex.test(parameter)) {
                    dynExpressionModifier.valueNumeric = parseFloat(parameter);
                    dynExpressionModifier.valueType = DynamicExpressionModifier.valueTypeConstants.numeric;
                } else if (parameter === "$0") {
                    dynExpressionModifier.valueType = DynamicExpressionModifier.valueTypeConstants.source;
                }
            }
            if (dynExpressionModifier.method !== HiTestConstants.modifierNames.toNumber) {
                result.push(dynExpressionModifier);
            }
            if (nextMethod === "") {
                break;
            }
            workingCopy = nextMethod;
        }
        result.reverse();
        return result;
    }

    /**
     * Builds an expression from the modifiers.
     *
     * @returns a string consisting of the build expression.
     */
    public static buildModifierChain(modifiers: DynamicExpressionModifier[]): string {
        let result = modifiers && modifiers.length > 0 ? "$0" : `${HiTestConstants.modifierNames.toNumber}($0)`;
        for (const modifier of modifiers) {
            let modifierValue: string | number = "";
            switch (modifier.valueType) {
                case DynamicExpressionModifier.valueTypeConstants.input:
                    modifierValue = "{" + modifier.valueInput + "}";
                    break;
                case DynamicExpressionModifier.valueTypeConstants.numeric:
                    modifierValue = modifier.valueNumeric;
                    break;
                default:
                    modifierValue = "$0";
            }

            switch (modifier.method) {
                case HiTestConstants.modifierNames.abs:
                    result = `${HiTestConstants.modifierNames.abs}(${result})`;
                    break;
                case HiTestConstants.modifierNames.add:
                    result = `${HiTestConstants.modifierNames.add}(${result}, ${modifierValue})`;
                    break;
                case HiTestConstants.modifierNames.subtract:
                    result = `${HiTestConstants.modifierNames.subtract}(${result}, ${modifierValue})`;
                    break;
                case HiTestConstants.modifierNames.divide:
                    result = `${HiTestConstants.modifierNames.divide}(${result}, ${modifierValue})`;
                    break;
                case HiTestConstants.modifierNames.multiply:
                    result = `${HiTestConstants.modifierNames.multiply}(${result}, ${modifierValue})`;
                    break;
                default:
                    result = `${HiTestConstants.modifierNames.toNumber}(${result})`;
                    break;
            }
        }
        return result;
    }

    private static splitParameters(source: string): string[] {
        let activeSubstring = "";
        const substrings: string[] = [];
        let openCount = 0;
        for (let char of source) {
            openCount += char === "(" ? 1 : char === ")" ? -1 : 0;
            if (char === "," && openCount <= 0) {
                char = "";
                substrings.push(activeSubstring);
                activeSubstring = "";
            }
            activeSubstring += char;
        }
        substrings.push(activeSubstring);
        return substrings;
    }

    public validate(): DynamicInputValidationStatus {
        if (this.valueType === undefined) {
            return DynamicInputValidationStatus.modifierTypeMissing;
        }
        if (!this.method) {
            return DynamicInputValidationStatus.modifierMethodMissing;
        }
        if (!this.methodNames.includes(this.method)) {
            return DynamicInputValidationStatus.modifierIllegalMethodName;
        }
        if (this.valueType === DynamicExpressionModifier.valueTypeConstants.input && !this.valueInput) {
            return DynamicInputValidationStatus.modifierInputMissing;
        }
        if (this.valueType === DynamicExpressionModifier.valueTypeConstants.input && !this._inputObject) {
            return DynamicInputValidationStatus.modifierInputObjectMissing;
        }
        if (this.valueType === DynamicExpressionModifier.valueTypeConstants.numeric) {
            if (this.valueNumeric === undefined || isNaN(this.valueNumeric)) {
                return DynamicInputValidationStatus.modifierValueMissing;
            }
            if (this.method === HiTestConstants.modifierNames.divide && this.valueNumeric === 0) {
                return DynamicInputValidationStatus.modifierDivisionByZeroError;
            }
        }

        return DynamicInputValidationStatus.valid;
    }

}
